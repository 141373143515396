import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';

@Injectable()  
export class LoginService {
    constructor(
        private constants: CONSTANTS,
        private httpServ: HttpService,
        private sessionServ: SessionService
    ) {
        this.httpServ = httpServ;
        this.constants = constants;
        this.sessionServ = sessionServ
    }

    /**
     * Prepares request object for login and send it to http service
     * @param data 
     */
    checkLogin(data) {
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.LOGIN,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object for register and send it to http service
     * @param data 
     */
    register(data) {
        let requestObject = {
            REQUEST_DATA: {data: data},
            API_URL: this.constants.API_URLS.REGISTER,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Call http service for logout
     * @param data 
     */
    logout(user) {
        let requestObject = {
            API_URL: this.constants.API_URLS.LOGOUT + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object for sending password link email and send it to http service
     * @param email
     */
    sendResetEmail(email) {
        let lang = this.sessionServ.getLang();
        let requestObject = {
            REQUEST_DATA: {email: email, language: lang},
            API_URL: this.constants.API_URLS.SEND_RESET_LINK,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object to reset password send it to http service
     * @param data 
     */
    resetPassword(data) {
        let requestObject = {
            REQUEST_DATA: {data: data},
            API_URL: this.constants.API_URLS.RESET_PASSWORD,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
}
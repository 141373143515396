import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { DentalRecordService } from '../../../services/dental-record.service';
import { DentalRecord } from '../../../models/dental-record';
import { DataService } from '../../../services/data.service';
import { SessionService } from '../../../services/session.service';
import { LegendsComponent } from '../legends/legends.component';
import { ToothMapComponent } from '../tooth-map/tooth-map.component';
import { TreatmentService } from 'src/app/services/treatment.service';

@Component({
	selector: 'app-edit-dental-record',
	templateUrl: './edit-dental-record.component.html',
	styleUrls: ['./edit-dental-record.component.css']
})
export class EditDentalRecordComponent implements OnInit {

    dentalRecord: DentalRecord;
    user: any;
    id: number;
    dentalRecordAssets: any;
    assetLength: number;
    reportTitle: string;
    lang: string = 'en';
    selectedLegends: any = [];
    selectedTeeth: any = [];
    treatmentIds: number[] = [];
    teethLegends: any = [];
    treatmentDetails: any = [];
    files: File[] = [];
    hideReport: boolean[] = [];

    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public dentalRecordServ: DentalRecordService,
        public dataServ: DataService,
        public router: Router,
        public sessionServ: SessionService,
        public treatmentServ: TreatmentService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {        
        this.lang = this.sessionServ.getLang();

        for(let i=11; i<=85; i++) {                            
            if(!this.selectedLegends[i])
                 this.selectedLegends[i] = [];
            this.selectedLegends[i]['color'] = '#ffffff';
            this.selectedLegends[i]['code'] = '';                        
        }

        this.dentalRecord = new DentalRecord();

        this.teethLegends['fixed'] = [];

        this.dataServ.id.subscribe(id => {
            this.loaderServ.start();
            this.id = id; 
            if(!this.id)
                this.router.navigateByUrl('/user/dental-record');
            else {                
                this.dentalRecordServ.find(this.id)
                    .then(res => {
                      
                        this.dentalRecord = new DentalRecord(res.response[0]);
						for(let i=0; i<this.constants.PROCEDURES.length; i++) {
							if(res.response[0][this.constants.PROCEDURES[i][this.lang].title])
                                this.selectedTeeth[this.constants.PROCEDURES[i][this.lang].title] = JSON.parse(res.response[0][this.constants.PROCEDURES[i][this.lang].title]);
                        }                        
                        
                        for(let i=0; i<res.response[0].treatmentDetails.length; i++) {
                            this.treatmentIds.push(res.response[0].treatmentDetails[i].id);
                        }

                        this.treatmentServ.records()
                            .then(res => {
                                if(typeof res !== 'undefined') {
                                    if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
                                    {   
                                        this.treatmentDetails = res.response;
                                    }
                                }
                            });            

						if(res.response[0].teeth_status) {
                            res.response[0].teeth_status = JSON.parse(res.response[0].teeth_status);
                            if(res.response[0].teeth_status['fixed'])
                                for(let i=0; i<res.response[0].teeth_status['fixed'].length; i++) {
                                    this.selectedLegends[res.response[0].teeth_status['fixed'][i].tooth] = res.response[0].teeth_status['fixed'][i];
                                    this.teethLegends['fixed'].push(this.selectedLegends[res.response[0].teeth_status['fixed'][i].tooth])
                                }
                            
                            if(res.response[0].teeth_status['supernum']) {
                                this.selectedLegends[0] = res.response[0].teeth_status['supernum'];
                                this.teethLegends['supernum'] = this.selectedLegends[0];
                            } else {
                                this.selectedLegends[0] = [];
                                // this.selectedLegends[0]['color'] = '#c6ee9e';
                                // this.selectedLegends[0]['code'] = 'PS';
                                this.selectedLegends[0]['color'] = null;
                                this.selectedLegends[0]['code'] = null;                         
                            }
                        }
                        this.loaderServ.stop();
                        this.dentalRecordAssets = res.response[0].assetDetails;
                        for(let i=0; i<this.dentalRecordAssets.length; i++)
                            this.hideReport[this.dentalRecordAssets[i].id] = false;
                    });
            }
        });        
    }

    handleReportFileInput(files: FileList) {        
        let flag = true;
        for(let i=0; i<files.length; i++) {
            if(this.constants.FILE_FORMATS.DOC.indexOf(files[i].type.toLowerCase()) < 0 && this.constants.FILE_FORMATS.IMAGE.indexOf(files[i].type.toLowerCase()) < 0) {
                flag = false;  
                break;
            }
        }

        if(!flag)
            this.showMessage({'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT});
        else {            
            this.files = [];

            if(!flag)
                this.showMessage({'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT});
            else {
                this.reportTitle = '';
                for(let i=0; i<files.length; i++) {
                    this.files[i] = files.item(i);
                    this.reportTitle += files[i].name + ', ';
                }

                this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
            }
        }
    }

    update(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            
            if(this.dentalRecord.start_date)
                this.dentalRecord.start_date = this.common.formatDate(new Date(this.dentalRecord.start_date));
            else 
                this.dentalRecord.start_date = null;
            if(this.dentalRecord.end_date)
                this.dentalRecord.end_date = this.common.formatDate(new Date(this.dentalRecord.end_date));
            else 
                this.dentalRecord.end_date = null;

            this.dentalRecord.title = this.dentalRecord.start_date;
            
            for(let i=0; i<this.constants.PROCEDURES.length; i++) {                
                let title = this.constants.PROCEDURES[i][this.lang].title.toLowerCase();
                let type = this.constants.PROCEDURES[i][this.lang].type.toLowerCase(); 
                
                if(this.selectedTeeth[title]) {   
                    console.log(this.selectedTeeth[title]); 
                    let fixedData = this.selectedTeeth[title].fixed;  //modified on 16.07.2020
                    let supernumData = this.selectedTeeth[title].supernum;

                    if(fixedData.length > 0 || supernumData) {
                        this.selectedTeeth[title]['type'] = type;        
                        this.dentalRecord[title] = JSON.stringify(Object.assign({}, this.selectedTeeth[title]));
                    } else {
                        this.selectedTeeth[title]['type'] = null;        
                        this.dentalRecord[title] = null;
                    } 
                }
            }

            

            this.dentalRecord.teeth_status = JSON.stringify(Object.assign({}, this.teethLegends));
            
            this.dentalRecordServ.update(this.dentalRecord, this.treatmentIds, this.files)
                .then(response => {   
                    this.loaderServ.stop();               
                    this.showMessage(response);
                    this.router.navigateByUrl('/user/dental-record');
                });
        }
    }

    delete(id: number) {
        this.dentalRecordServ.remove(id)
            .then(res => {
                this.router.navigateByUrl('/user/dental-record');
            });
    }

    deleteAsset(id: number) {
        this.snackBar.open(this.constants.REMOVE_ASSET_ALERT[this.lang], this.constants.YES[this.lang], {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['white-snackbar']
        }).onAction().subscribe(() => {
            this.loaderServ.start();
            this.dentalRecordServ.deleteAsset(id)
                .then(res => {
                    if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
                        this.hideReport[id] = true;
                    
                    this.loaderServ.stop();
                });
        });
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    openToothMapDialog(procedure: string): void { 
        // console.log('edit procedure:'+procedure)
        this.dataServ.currectProcedureString(procedure);         
        let dialogRef = this.dialog.open(ToothMapComponent, {
            data: this.selectedTeeth[procedure],
            width: '65%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.selectedTeeth[procedure] = [];
                this.selectedTeeth[procedure]['fixed'] = result['fixed'];

                if(this.selectedTeeth[procedure]['fixed'].indexOf('All') > 0)
                    this.selectedTeeth[procedure]['fixed'].splice( this.selectedTeeth[procedure]['fixed'].indexOf('All'), 1 );

                this.selectedTeeth[procedure]['supernum'] = result['supernum'];
                this.selectedTeeth[procedure]['supernum'] = Object.assign({}, this.selectedTeeth[procedure]['supernum']);
            }
        });
    }

    openLegendDialog(n: number): void { 
        let dialogRef = this.dialog.open(LegendsComponent, {
            width: '40%',
        });

        dialogRef.afterClosed().subscribe(result => {           
            if(result) {                
                if(0 == n) {
                    this.selectedLegends[0] = result;
                    this.teethLegends['supernum'] = this.selectedLegends[0];
                } else {
                    this.selectedLegends[n] = result;                    
                    result.tooth = n;
                    this.teethLegends['fixed'].forEach(element => {
                        if(element.tooth == n) {
                            this.teethLegends['fixed'].splice(this.teethLegends['fixed'].indexOf(element), 1);
                        }
                    });
                    this.teethLegends['fixed'].push(result);
                }
            }    
        });
    }
}
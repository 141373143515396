import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SessionService {

    public dashBoardUser = new BehaviorSubject(null);
  constructor() { }

    /**
     * get user details stored in session
     */
    getUser() {
        return JSON.parse(sessionStorage.getItem('user'));
    }

    /**
     * get logged user details stored in session
     */
    getLoggedInUser() {
        return JSON.parse(sessionStorage.getItem('loggedInUser'));
    }

    /**
     * get selected language stored in session
     */
    getLang() {
        return sessionStorage.getItem('lang');
    }

    /**
     * Store user details to session
     */
    setUser(user: any) {
        sessionStorage.setItem('user', JSON.stringify(user));
        this.dashBoardUser.next(user.id)
    }

    /**
     * Store logged user details to session
     */
    setLoggedInUser(user: any) {
        sessionStorage.setItem('loggedInUser', JSON.stringify(user));
    }

    /**
     * Store selected language to session
     */
    setLang(lang: string) {
        sessionStorage.setItem('lang', lang);
    }

    /**
     * Remove user details to session
     */
    removeUser() {
        sessionStorage.removeItem('user');
    }

    /**
     * Remove logged user details to session
     */
    removeLoggedInUser() {
        sessionStorage.removeItem('loggedInUser');
    }

    /**
     * Clear session
     */
    clearSession() {
        sessionStorage.clear();
    }

    /*
     *   Get current logged in status
     */
    isLoggedIn() {
        if(null != sessionStorage.getItem('user'))
          return true;

        return false;
    }
}

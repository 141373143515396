import { Injectable } from '@angular/core';
import { CanActivate } from "@angular/router";

import { CONSTANTS } from "../util/constants";
import { SessionService } from "./session.service";

@Injectable()
export class AuthUserService implements CanActivate {
    constructor(
        private sessionServ: SessionService,
        private constants: CONSTANTS
    ) {};

    canActivate() {
        if (this.sessionServ.isLoggedIn()) {
            let user = this.sessionServ.getUser();            
            if(this.constants.USER_TYPES.USER == user.type)
                return true;
            else
                window.location.href = '';
        }
        else
            window.location.href = '';
                
        return false;
    }
}

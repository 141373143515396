import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';
import { UserProfile } from '../../models/user-profiles';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-dashboard',
    templateUrl: './user-dashboard.component.html',
    styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {

    userProfile: UserProfile;
    message: string;
    profileImage: string = '/assets/images/dummy-user.png';
    categoryCurrentDates: any;

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        private loaderServ: NgxUiLoaderService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public router: Router
    ) { }

    ngOnInit() {
        this.sessionServ.dashBoardUser.subscribe(id=>{
            this.userServ.getDashboardValues(this.constants.STATUS.ACTIVE)
            .then(res => {
                this.categoryCurrentDates = res.response.category_current_dates;
            });
        })      
      
        this.loaderServ.start();
        this.userServ.getLoggedInUserProfile(this.sessionServ.getUser().token)
            .then(res => {
                this.loaderServ.stop();
                this.userProfile = res.response;

                if(this.userProfile.image)
                    this.profileImage = this.constants.SERVER.BASE + '/' + this.userProfile.image;
            });
    }

    medicalHistory() {
        this.router.navigateByUrl('/user/medical-history');
    }

    treatment() {
        this.router.navigateByUrl('/user/treatment');
    }

    dentalRecord() {
        this.router.navigateByUrl('/user/dental-record');
    }
}

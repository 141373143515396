import { Component, OnInit, ɵConsole} from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { DentalRecordService } from '../../../services/dental-record.service';
import { DentalRecord } from '../../../models/dental-record';
import { UserProfile } from '../../../models/user-profiles';
import { TreatmentService } from 'src/app/services/treatment.service';
import { ToothMapComponent } from '../tooth-map/tooth-map.component';
import { LegendsComponent } from '../legends/legends.component';
import { SessionService } from 'src/app/services/session.service';
import { DataService } from '../../../services/data.service';

@Component({
    selector: 'app-add-dental-record',
    templateUrl: './add-dental-record.component.html',
    styleUrls: ['./add-dental-record.component.css']
})
export class AddDentalRecordComponent implements OnInit {

    dentalRecord: DentalRecord;
    userProfile: UserProfile;
    message: string;
    reportTitle: string;
    maxDate: Date;
    quantity: number;
    position: string;
    lang: string = 'en';
    files: File [] = [];
    treatmentIds: number[] = [];
    treatmentDetails: any = [];
    selectedTeeth: any = [];
    selectedLegends: any = [];
    teethLegends: any = [];

    constructor(
        public dialog: MatDialog,
        public constants: CONSTANTS,
        private dataServ: DataService,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public dentalRecordServ: DentalRecordService,
        public treatmentServ: TreatmentService,
        private snackBar: MatSnackBar,
        public router: Router,
        private sessionServ: SessionService
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();

        this.maxDate = new Date();
        this.dentalRecord = new DentalRecord();

        this.treatmentServ.records()
            .then(res => {
                this.treatmentDetails = res.response;
            });

        this.selectedLegends[0] = [];
        // this.selectedLegends[0]['color'] = '#c6ee9e';
        // this.selectedLegends[0]['code'] = 'PS';
        this.selectedLegends[0]['color'] = null;
        this.selectedLegends[0]['code'] = null;
        
        for(let i=11; i<=85; i++) {
            if(!this.selectedLegends[i])
                this.selectedLegends[i] = [];
            else {
                this.selectedLegends[i]['color'] = '#ffffff';
                this.selectedLegends[i]['code'] = '';
            }
        }
    }

    dateChanged() {
        if(this.dentalRecord.start_date > this.dentalRecord.end_date) {
            this.snackBar.open(this.constants.MESSAGES.INVALID_DATE, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });

            this.dentalRecord.end_date = null;
        }
    }

    handleReportFileInput(files: FileList) {
        let flag = true;
        for(let i=0; i<files.length; i++) {
            if(this.constants.FILE_FORMATS.DOC.indexOf(files[i].type.toLowerCase()) < 0 && this.constants.FILE_FORMATS.IMAGE.indexOf(files[i].type.toLowerCase()) < 0) {
                flag = false;  
                break;
            }
        }

        this.files = [];

        if(!flag)
            this.showMessage({'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT});
        else {
            this.reportTitle = '';
            for(let i=0; i<files.length; i++) {
                this.files[i] = files.item(i);
                this.reportTitle += files[i].name + ', ';
            }

            this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
        }
    }

    save(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();

            // start date and end date validation start
            if(this.dentalRecord.start_date == null && this.dentalRecord.end_date == null) {
                this.loaderServ.stop();     
                this.snackBar.open(this.constants.START_DATE_END_DATE_ERROR[this.lang].STARTDATEREQUIRED, '', {
                    duration: 3000,
                    panelClass: ['red-snackbar']
                  }); 
                  return false;
            }
            if(this.dentalRecord.end_date != null && this.dentalRecord.start_date == null) {
                this.loaderServ.stop();     
                this.snackBar.open(this.constants.START_DATE_END_DATE_ERROR[this.lang].STARTDATEREQUIREDBEFOREENDDATE, '', {
                    duration: 3000,
                    panelClass: ['red-snackbar']
                  }); 
                  return false; 
            }
            // start date and end date validation end

            if(this.dentalRecord.start_date)
                this.dentalRecord.start_date = this.common.formatDate(new Date(this.dentalRecord.start_date));
            else 
                this.dentalRecord.start_date = null;
            if(this.dentalRecord.end_date)
                this.dentalRecord.end_date = this.common.formatDate(new Date(this.dentalRecord.end_date));
            else 
                this.dentalRecord.end_date = null;

            this.dentalRecord.title = this.dentalRecord.start_date;
            for(let i=0; i<this.constants.PROCEDURES.length; i++) {                
                let title = this.constants.PROCEDURES[i][this.lang].title.toLowerCase();  
                let type = this.constants.PROCEDURES[i][this.lang].type.toLowerCase(); 

                

                // if(this.selectedTeeth[title]) {   
                if(this.selectedTeeth[title] && (this.selectedTeeth[title]['fixed'] ||  this.selectedTeeth[title]['supernum'])) { 
                    this.selectedTeeth[title]['type'] = type;               
                    this.dentalRecord[title] = JSON.stringify(Object.assign({}, this.selectedTeeth[title]));
                }
            }

            this.dentalRecord.teeth_status = JSON.stringify(Object.assign({}, this.teethLegends));
            
            this.dentalRecordServ.add(this.dentalRecord, this.treatmentIds, this.files)
                .then(response => {  
                    // console.log(response);
                    if(response.error == true){
                        this.loaderServ.stop();     
                        this.showMessage(response);
                        return false; 
                    } else {
                        this.loaderServ.stop();     
                        this.showMessage(response);
                        this.router.navigateByUrl('/user/dental-record');
                    } 
                });
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    openToothMapDialog(procedure: string): void { 
        this.dataServ.currectProcedureString(procedure);      
        let dialogRef = this.dialog.open(ToothMapComponent, {
            data: this.selectedTeeth[procedure],
            width: '65%',
        });

        dialogRef.afterClosed().subscribe(result => {    
            this.selectedTeeth[procedure] = [];
            if(result) {
                if(result['fixed'])
                    this.selectedTeeth[procedure]['fixed'] = result['fixed'];

                if(this.selectedTeeth[procedure]['fixed'].indexOf('All') > 0)
                    this.selectedTeeth[procedure]['fixed'].splice( this.selectedTeeth[procedure]['fixed'].indexOf('All'), 1 );

                if(result['supernum']) {
                    this.selectedTeeth[procedure]['supernum'] = result['supernum'];
                    this.selectedTeeth[procedure]['supernum'] = Object.assign({}, this.selectedTeeth[procedure]['supernum']);
                }
            }
        });
    }

    selectSupernum() {        
        this.selectedLegends[0].quantity = this.quantity;
        this.selectedLegends[0].position = this.position;
        this.selectedLegends[0] = Object.assign({}, this.selectedLegends[0]);
        this.teethLegends['supernum'] = this.selectedLegends[0];
    }

    openLegendDialog(n: number): void { 
        let dialogRef = this.dialog.open(LegendsComponent, {
            width: '70%',
        });

        if(!this.teethLegends['fixed'])
            this.teethLegends['fixed'] = [];

        dialogRef.afterClosed().subscribe(result => {
            if(result) {                
                if(0 == n) {
                    this.selectedLegends[0] = result;
                    this.selectedLegends[0].quantity = this.quantity;
                    this.selectedLegends[0].position = this.position;
                    this.teethLegends['supernum'] = this.selectedLegends[0];
                } else {
                    this.selectedLegends[n] = result;                    
                    result.tooth = n;
                    this.teethLegends['fixed'].forEach(element => {
                        if(element.tooth == n) {
                            this.teethLegends['fixed'].splice(this.teethLegends['fixed'].indexOf(element), 1);
                        }
                    });
                    this.teethLegends['fixed'].push(result);
                }
            }    
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { SessionService } from '../../services/session.service';
import { UserProfile } from '../../models/user-profiles';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css', '../../../assets/styles/responsive.css']
})
export class RegisterComponent implements OnInit {

    termsAndCondition: boolean;
    passwordConfirmation: string;
    monthOfBirth: string = '';
    dayOfBirth: string = '';
    yearOfBirth: string = '';
    isMaleClicked: boolean = true;
    isFemaleClicked: boolean = false;
    hideError: boolean = true;
    hideSuccess: boolean = true;
    userProfile: UserProfile;
    hideMessage: boolean = true;
    message: string;
    years: number[] = [];
     
    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public loaderServ: NgxUiLoaderService,
        public userServ: UserService,
        public router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {        
        // for(let i=new Date().getFullYear(); i>=1901; i--)
        for(let i=new Date().getFullYear(); i>=1600; i--)
            this.years.push(i);

        this.userProfile = new UserProfile();
        this.userProfile.gender = this.constants.GENDERS.MALE;
    }

    genderSelected(gender) {
        this.userProfile.gender = gender;

        if(this.constants.GENDERS.MALE == gender) {
            this.isMaleClicked = true;
            this.isFemaleClicked = false;
        } else {
            this.isMaleClicked = false;
            this.isFemaleClicked = true;
        }
    }

    register(form: NgForm) {
        let password = form.value.password;
        let confirmPassword = form.value.confirm_password;
        if(form.value.password != "" && form.value.password.length < 6) {
            this.snackBar.open(this.constants.MESSAGES.PASSWORD_LENGTH, "Dismiss", {
                duration: 4000,
                panelClass: ['red-snackbar']
            });
         
            this.loaderServ.stop();
            return false;
        }
        
        if(password != confirmPassword) {
            this.snackBar.open(this.constants.MESSAGES.PASSWORD_MISMATCH, "Dismiss", {
                duration: 4000,
                panelClass: ['red-snackbar']
            });
        
            this.loaderServ.stop();
            return false;
        }
        
        if(form.valid) {
            this.loaderServ.start();
            if(this.yearOfBirth != '' && this.monthOfBirth != '' && this.dayOfBirth != '') {
                this.userProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;
                let date = new Date();
                let currentDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
                if(currentDate < this.userProfile.date_of_birth) {
                    this.loaderServ.stop();
                    this.snackBar.open(this.constants.MESSAGES.INVALID_BIRTHDATE, '', {
                        duration: 3000,
                        panelClass: ['red-snackbar']
                    });
                    return false;
                }
            }
           
            this.loginServ.register(this.userProfile)
                .then(response => {                    
                    if(response && this.constants.RESPONSE_CODES.HTTP_STATUS_OK == response.status) {
                        this.userServ.getLoggedInUserProfile(response.access_token)
                            .then(userResponse => {
                                let user = userResponse.response;                                
                                user.token = response.access_token;
                                user.email = user.user.email;
                                user.type = user.user.type;
                                
                                this.sessionServ.setUser(user);
                                this.sessionServ.setLoggedInUser(user);
                                this.loaderServ.stop();

                                if(this.constants.USER_TYPES.USER == user.type) 
                                    this.router.navigateByUrl('/add-basic-info');
                                else
                                    this.router.navigateByUrl('/admin');
                            });
                    } else {
                        this.snackBar.open(response.message, "Dismiss", {
                            duration: 4000,
                            panelClass: ['red-snackbar']
                        });
                    
                        this.loaderServ.stop();
                        this.hideSuccess = true;
                        this.hideError = false;
                    }
                });
        }
    }
}

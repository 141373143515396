import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { CONSTANTS } from 'src/app/util/constants';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

    lang: string;
    searchString: string;
    result: any = [];

    constructor(
        public constants: CONSTANTS,
        private dataServ: DataService,
        private userServ: UserService,
        private sessionServ: SessionService,
        public loaderServ: NgxUiLoaderService,
        private router: Router
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        
        this.dataServ.searchString.subscribe(searchString => {
            this.searchString = searchString;
            this.search();
        });
    }

    search() {
        this.loaderServ.start();
        this.userServ.search(this.searchString)
            .then(res => {
                this.result = res.response;
                
                let user = this.sessionServ.getLoggedInUser();
                this.loaderServ.stop();              
                if(this.result.length > 0) {
                    this.result.filter(element => {                        
                        if(this.constants.CATEGORIES.MEDICAL_HISTORY == element.type_of_record) {
                            if(1 == user.language_id) 
                                element.detail = element.meddra_english;
                            else if(2 == user.language_id)
                                element.detail = element.meddra_chinese;
                            else if(3 == user.language_id)
                                element.detail = element.meddra_french;
                            else if(4 == user.language_id)
                                element.detail = element.meddra_german;
                            else if(5 == user.language_id)
                                element.detail = element.meddra_italian;
                            else if(6 == user.language_id)
                                element.detail = element.meddra_spanish;
                            if(element.meddra_other)
                                element.detail = element.meddra_other;
                        } else if(this.constants.CATEGORIES.TREATMENT_IMMUNIZATION_OLD == element.type_of_record) {
                            element.detail = element.brand_name;
                            if(element.purpose)
                                element.detail = element.detail + ' | ' + element.purpose;
                        } else if(this.constants.CATEGORIES.DENTAL_RECORD == element.type_of_record) {
                            
                            element.detail = '';
                            if('en' == this.lang) {
                                this.constants.TEETH_PROCEDURES_EN.forEach(procedure => {
                                    if(element[procedure]) {
                                        let procedure_lang = JSON.parse(element[procedure]);
                                        // console.log(procedure_lang.type)
                                        if(element.detail)
                                            element.detail = element.detail + ' | ';
                                        // element.detail = element.detail + procedure;
                                        element.detail = element.detail + procedure_lang.type;
                                    }
                                });
                            }
                            if('zh' == this.lang) {
                                this.constants.TEETH_PROCEDURES_ZH.forEach(procedure => {
                                    if(element[procedure]) {
                                        let procedure_lang = JSON.parse(element[procedure]);
                                        // console.log(procedure_lang.type)
                                        if(element.detail)
                                            element.detail = element.detail + ' | ';
                                        // element.detail = element.detail + procedure;
                                        element.detail = element.detail + procedure_lang.type;
                                    }
                                });
                            }
                            if('fr' == this.lang) {
                                this.constants.TEETH_PROCEDURES_FR.forEach(procedure => {
                                    if(element[procedure]) {
                                        let procedure_lang = JSON.parse(element[procedure]);
                                        // console.log(procedure_lang.type)
                                        if(element.detail)
                                            element.detail = element.detail + ' | ';
                                        // element.detail = element.detail + procedure;
                                        element.detail = element.detail + procedure_lang.type;
                                    }
                                });
                            }
                            if('de' == this.lang) {
                                this.constants.TEETH_PROCEDURES_DE.forEach(procedure => {
                                    if(element[procedure]) {
                                        let procedure_lang = JSON.parse(element[procedure]);
                                        // console.log(procedure_lang.type)
                                        if(element.detail)
                                            element.detail = element.detail + ' | ';
                                        // element.detail = element.detail + procedure;
                                        element.detail = element.detail + procedure_lang.type;
                                    }
                                });
                            }
                            if('es' == this.lang) {
                                this.constants.TEETH_PROCEDURES_ES.forEach(procedure => {
                                    if(element[procedure]) {
                                        let procedure_lang = JSON.parse(element[procedure]);
                                        // console.log(procedure_lang.type)
                                        if(element.detail)
                                            element.detail = element.detail + ' | ';
                                        // element.detail = element.detail + procedure;
                                        element.detail = element.detail + procedure_lang.type;
                                    }
                                });
                            }
                            
                        }
                    });
                }
            });
    }

    showContent(record: any) {
        if(this.constants.CATEGORIES.DENTAL_RECORD == record.type_of_record) {
            this.dataServ.updateId(record.id);
            this.router.navigateByUrl('/user/show-dental-record');
        } else if(this.constants.CATEGORIES.TREATMENT_IMMUNIZATION_OLD == record.type_of_record) {
            this.dataServ.updateId(record.id);
            this.router.navigateByUrl('/user/show-treatment');
        } else if(this.constants.CATEGORIES.MEDICAL_HISTORY == record.type_of_record) {
            this.dataServ.updateId(record.id);
            this.router.navigateByUrl('/user/show-medical-history');
        } else {
            this.dataServ.updateId(record.id);
            this.router.navigateByUrl('/user/member-profile');
        }
    }
}

export class UserProfile {
    id: number;

    name: string;
    email: string;
    gender: string;
    password: string;
    date_of_birth: string;
    organ_donor: number;
    primary_emergency_contact_name: string;
    primary_emergency_contact_number: string;
    secondary_emergency_contact_name: string;
    secondary_emergency_contact_number: string;
    smoker: number;
    doctor_contact_info: string;
    // user_consent: boolean = false;
    user_consent: number = 0;
    blood_group: string;
    rh_factor: string;
    image: string;
    medical_id: string;
    allergy: string;
    created_by:number;
    user_id:number;
    
    constructor(userProfile: UserProfile = null) {
        if(userProfile) {
            this.id = userProfile.id;
            this.name = userProfile.name;
            this.gender = userProfile.gender;
            this.password = userProfile.password;
            this.date_of_birth = userProfile.date_of_birth;
            this.organ_donor = userProfile.organ_donor;
            this.primary_emergency_contact_name = userProfile.primary_emergency_contact_name;
            this.primary_emergency_contact_number = userProfile.primary_emergency_contact_number;
            this.secondary_emergency_contact_name = userProfile.secondary_emergency_contact_name;
            this.secondary_emergency_contact_number = userProfile.secondary_emergency_contact_number;
            this.smoker = userProfile.smoker;
            this.doctor_contact_info = userProfile.doctor_contact_info;
            this.user_consent = userProfile.user_consent;
            this.rh_factor = userProfile.rh_factor;
            this.image = userProfile.image;
            this.blood_group = userProfile.blood_group;
            this.medical_id = userProfile.medical_id;
            this.allergy = userProfile.allergy;
            this.created_by = userProfile.created_by;
            this.user_id = userProfile.user_id;
        }
    }
}

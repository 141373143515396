import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../util/constants';
import { UserService } from '../../services/user.service';
import { SessionService } from '../../services/session.service';
import { User } from '../../models/user';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css', '../../../assets/styles/responsive.css']
})
export class UserComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = ['email', 'blood_group', 'medical_id', 'organ_donor', 'date_of_birth', 'gender', 'smoker', 'allergy', 'actions'];
  
    dataSource: any = [];
    users: any = [];
    user_status: string;
    lang: string;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatPaginator, {static: true}) sort: MatSort;

    constructor(
        public constant: CONSTANTS,
        public userServ: UserService,
        public sessionServ: SessionService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        this.records();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    records() {
        this.userServ.records()
            .then(res => {                
                this.users = res.response;
                console.log(this.users);
                this.users.filter(element => {
                    element.name = '';
                    
                    if(element.user_profile)
                        element.name = element.user_profile.name;
                        
                });

                this.applyTableBehaviour();
            });
    }

    delete(id) {
        this.userServ.delete(id)
            .then(res => {                
                this.records();
            })
    }

    activedeactive(id, status){
        console.log(status);
        if(status == 'active')
        { 
            this.user_status = 'removed';
        } else {
            this.user_status = 'active';
        }
        
        this.userServ.activedeactive(id, this.user_status)
        .then(res => {     
            this.showMessage(res);           
            this.records();
        })
    }

    applyTableBehaviour() {
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

      showMessage(res: any) {    
		if(this.constant.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['green-snackbar']
			});
		else
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['red-snackbar']
			});
	}

}

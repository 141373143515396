import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CmsService } from 'src/app/services/cms.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

    content: any;

    constructor(
        private cmsServ: CmsService,
        public loaderServ: NgxUiLoaderService,
    ) { }

    ngOnInit() {
        this.loaderServ.start();
        this.cmsServ.getByName('About Us')
            .then(res => {
                this.loaderServ.stop();
                this.content = res.response[0].content;
            });
    }
}

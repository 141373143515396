import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { MatSnackBar } from '@angular/material';

import { CONSTANTS } from 'src/app/util/constants';
import { Cms } from 'src/app/models/cms';
import { MeddraService } from 'src/app/services/meddra.service';
import { CmsService } from 'src/app/services/cms.service';

@Component({
    selector: 'app-add-cms',
    templateUrl: './add-cms.component.html',
    styleUrls: ['./add-cms.component.css']
})
export class AddCmsComponent implements OnInit {

    public Editor = ClassicEditor;
    public cms: Cms;
    public languages: any = [];

    constructor(
        public constants: CONSTANTS,
        private meddraServ: MeddraService,
        private cmsServ: CmsService,
        private loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.cms = new Cms();
        this.cms.content = '';

        this.meddraServ.getLanguages()
            .then(res => {
                if(res)
                    this.languages = res.response;
            })
    }

    save(form: NgForm) {
        if(form.valid) {                        
            this.loaderServ.start();
                            
            this.cmsServ.save(this.cms)
                .then(response => {                        
                    this.loaderServ.stop();               
                    
                    this.showMessage(response);

                    form.reset();
                });                      
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';
import { UserProfile } from '../../models/user-profiles';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-recently-deleted',
	templateUrl: './recently-deleted.component.html',
	styleUrls: ['./recently-deleted.component.css']
})
export class RecentlyDeletedComponent implements OnInit {
	userProfile: UserProfile;
	message: string;
	profileImage: string = '/assets/images/dummy-user.png';
	categoryCurrentDates: any = [];

	constructor(
		public constants: CONSTANTS,
		public loginServ: LoginService,
		public sessionServ: SessionService,
		public userServ: UserService,
		public router: Router
	) { }

	ngOnInit() {        
		this.userServ.getDashboardValues(this.constants.STATUS.REMOVED)
            .then(res => {
                this.categoryCurrentDates = res.response.category_current_dates;
			});
			
		this.userServ.getLoggedInUserProfile(this.sessionServ.getUser().token)
			.then(res => {
				this.userProfile = res.response;

				if(this.userProfile.image)
					this.profileImage = this.constants.SERVER.BASE + '/' + this.userProfile.image;
			})
	}

	medicalHistory() {
		this.router.navigateByUrl('/user/deleted-medical-histories');
	}

	treatment() {
		this.router.navigateByUrl('/user/deleted-treatments');
	}

	dentalRecord() {
		this.router.navigateByUrl('/user/deleted-dental-records');
	}
}

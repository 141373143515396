import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css', '../../../assets/styles/responsive.css']
})
export class ResetPasswordComponent implements OnInit {

    token: string;
    password: string;
    confirmPassword: string;
    message: string;
    email: string;
    hideError: boolean = true;
    hideSuccess: boolean = true;
    isDisabled:boolean = false;
    lang: string = 'en';

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        public constants: CONSTANTS,
        public loginServ: LoginService,
        private route: ActivatedRoute,
        public loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
        public sessionServ: SessionService,
        private router: Router
    ) { }

    ngOnInit() {
        let lang = '';
        if(this.locale == 'en-US'){
            lang = 'en';
        } else {
            lang = this.locale ;
        }
        console.log(lang);
        this.sessionServ.setLang(lang);
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.lang = this.sessionServ.getLang();
    }

    resetPassword(form: NgForm) {
        if(form.value.password != "" && form.value.password.length < 6) {
            this.isDisabled = false;
            this.snackBar.open(this.constants.RESET_PASSWORD_ERROR[this.lang].PASSWORD_LENGTH, this.constants.RESET_PASSWORD_ERROR[this.lang].DISMISS, {
                duration: 4000,
                panelClass: ['red-snackbar']
            });
         
            this.loaderServ.stop();
            return false;
        }
        if(form.valid) {
            this.loaderServ.start();
            if(this.password != this.confirmPassword) {
                this.isDisabled = false;
                this.snackBar.open(this.constants.RESET_PASSWORD_ERROR[this.lang].PASSWORD_MISMATCH, this.constants.RESET_PASSWORD_ERROR[this.lang].DISMISS, {
                    duration: 4000,
                    panelClass: ['red-snackbar']
                });
            
                this.loaderServ.stop();
                return false;
            } else {
                this.isDisabled = true;
                    this.loginServ.resetPassword({email: this.email, code: this.token, password: this.password})
                    .then(response => {
                        this.showMessage(response);
                        this.loaderServ.stop();
                        
                    });
                
            }
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status) {
            this.isDisabled = true;
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
            this.router.navigateByUrl('/');
        }  
        else {
            this.isDisabled = false;
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
        }
            
    }
}

import { Component, OnInit } from '@angular/core';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';
import { UserProfile } from '../../models/user-profiles';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

    userProfile: UserProfile;
    message: string;
    profileImage: string = '/assets/images/dummy-user.png';
    age: number;
    latestCategories: any;
    lang: string;

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService
    ) { }

    ngOnInit() {  
        this.lang = this.sessionServ.getLang();      
        this.userProfile = this.sessionServ.getUser();
        if(this.userProfile.image)
            this.profileImage = this.constants.SERVER.BASE + '/' + this.userProfile.image;

        this.calculateAge();

        this.userServ.getDashboardValues(this.constants.STATUS.ACTIVE)
            .then(res => {
                this.latestCategories = res.response.latest_record;
            });
    } 

    calculateAge() {
         let date_of_birth = Date.parse(this.userProfile.date_of_birth);
         
         if(this.userProfile.date_of_birth) {
            var timeDiff = Math.abs(Date.now() - date_of_birth);
            this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        }
    }

}

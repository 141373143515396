import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { SessionService } from '../../services/session.service';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css', '../../../assets/styles/responsive.css']
})
export class LoginComponent implements OnInit {

    email: string;
    password: string;
    hideMessage: boolean = true;
    message: string = '';
    show: boolean = false;
    currentLang: string = '';

    constructor(
        private router: Router,
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public userServ: UserService,
        public sessionServ: SessionService,
        public dataServ: DataService,
        public loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
        private location: Location
    ) { }

    ngOnInit() {
        this.loaderServ.start();
        let path = this.location.prepareExternalUrl(this.location.path());
        let lang = path.substr(path.length - 3); 
        lang = lang.substring(0, lang.length - 1);

        if(!lang)
            lang = 'en';
        this.currentLang = lang;    
        this.sessionServ.setLang(lang);
    }

    ngAfterViewInit() {
        this.loaderServ.stop();
      }

    /**
     * 
     * @param form 
     */
    login(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            this.loginServ.checkLogin({email: this.email, password: this.password})
                .then(response => {
                    if(response.status) {
                        if(this.constants.RESPONSE_CODES.HTTP_STATUS_BAD_REQUEST == response.status) {
                            this.hideMessage = false;
                            this.message = response.message;
                            this.loaderServ.stop();
                            this.snackBar.open(response.message, '', {
                                duration: 3000,
                                panelClass: ['red-snackbar']
                            });
                        } else {
                            this.hideMessage = true;
                            this.userServ.getLoggedInUserProfile(response.access_token)
                                .then(userResponse => {
                                    let user = userResponse.response;    
                                    user.token = response.access_token;
                                    user.email = user.user.email;
                                    user.type = user.user.type;
                                    user.language_id = user.user.language_id;
                                    
                                    this.sessionServ.setUser(user);
                                    this.sessionServ.setLoggedInUser(user);
                                
                                    this.loaderServ.stop();
                                    
                                    if(this.constants.USER_TYPES.USER == user.type)
                                        this.router.navigateByUrl('/user');
                                    else
                                        this.router.navigateByUrl('/admin');
                                })
                        }
                    } else {
                        this.loaderServ.stop();
                        this.message = "Please try again";
                        this.snackBar.open(response.message, '', {
                            duration: 3000,
                            panelClass: ['red-snackbar']
                        });
                        window.location.reload();
                    }
                });
        }
    }
    
    toggle() {
        this.show = !this.show;
    }
}

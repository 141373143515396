export class Contact {
    language_id: number;
    name: string;
    email: string;
    message: string;
    contact_number: string;

    constructor(contact: Contact = null) {
        if(contact) {
            this.contact_number = contact.contact_number;
            this.language_id = contact.language_id;
            this.name = contact.name;
            this.message = contact.message;
        }
    }
}

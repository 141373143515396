import { Injectable } from "@angular/core";

@Injectable()
export class CONSTANTS {
    SERVER = {
            // ROOT: 'http://lyfe-trek.test/api',
            // BASE: 'http://lyfe-trek.test',
        
        //  ROOT: '/api',
        //  BASE: '', 

        ROOT: 'https://beta.aequo360.com/api',
        BASE: 'https://beta.aequo360.com',

        // ROOT: 'http://127.0.0.1:8000/api',
        // BASE: 'http://127.0.0.1:8000/',
	};

    API_URLS = {
        ROOT: '/',
        LOGIN: '/login',
        REGISTER: '/register',
        LOGOUT: '/logout',
        SEND_RESET_LINK: '/send-reset-link',
        RESET_PASSWORD: '/reset-password',

        USERS: '/users',
        USER_PROFILES: '/user-profiles',
        MEDICAL_HISTORY: '/medical-history',
        TREATMENTS: '/treatments',
        MEDICINES: '/medicines',
        DENTAL_RECORDS: '/dental-records',
        ASSETS: '/assets',
        MEDDRA_PTS_LLTS: '/meddra-pts-llts',
        CMS: '/cms',
        PAGE: '/page',
        SEARCH: '/search',

        GET_LOGGED_IN_USER_PROFILE: '/get-logged-in-user-profile',
        GET_DASHBOARD_VALUES: '/get-dashboard-values',
        MEDICAL_HISTORY_MEDDRA_LIST: '/llt-pt-list',       
        UPDATE: '/update',
        UPDATE_PROFILE_IMAGE: '/update-profile-image',
        GET_BY_PARENT_ID: '/get-by-parent-id',
        UPDATE_MEDICAL_HISTORY: '/update-medical-history',
        DELETE_MEDICAL_REPORT: '/delete-medical-report',
        DELETE_TREATMENT_REPORT: '/delete-treatment-report',
        DELETE_DENTAL_REPORT: '/delete-dental-report',
        REMOVED: '/removed',
        RESTORE: '/restore',
        GENERATE_PDF: '/generate-pdf',
        GET_LANGUAGES: '/get-languages',        
        CHANGE_STATUS: '/change-status',       
        CONTACT_US: '/contact-us',    
        REPORTS: '/demographic-reports',    
    };

    URL_VARIABLES = {
        TOKEN: '?token=',
        EMAIL: '?email=',
        ID: '&id=',
        PERSON_ID: '&person_id=',
        TYPE: '?type=',
        LLT_ID: '&llt_id=',
        NAME: '&name=',
        NAME_VAR: '?name=',
        USER_ID: '&userId=',
        CREATED_BY: '&created_by=',
        LANGUAGE_ID: '&language_id=',
        SEARCH_PARAMETER: '&searchparameter=',
        DEVICE_TYPE: '&device_type=web',
        PAGE: '&page=',
        AGE: '&age=',
        GENDER: '&gender=',
        START_DATE: '&start_date=',
        END_DATE: '&end_date=',
        PROCEDURE: '&procedure=',
        MEDICATION_NAME: '&medication_name=',
    };

    RESPONSE_CODES = {
        ERROR: 1,
        NOT_FOUND: 2,
        READ_ERROR: 3,
        CREATE_ERROR: 4,
        UPDATE_ERROR: 5,
        DELETE_ERROR: 6,
        READ_SUCCESS: 7,
        CREATE_SUCCESS: 8,
        UPDATE_SUCCESS: 9,
        DELETE_SUCCESS: 10,
        EMAIL_SENT: 11,
        EMAIL_SENDING_FAILED: 12,
        DUPLICATE_RECORD: 13,

        VALID_LOGIN: 14,
        INVALID_LOGIN: 15,
        FILE_UPLOADED: 16,
        LOGGED_OUT: 17,
        ELIGIBLE: 20,
        NOT_ELIGIBLE: 21,

        HTTP_STATUS_BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        HTTP_STATUS_OK: 200,
        HTTP_STATUS_ERROR: 500,
        HTTP_STATUS_FORBIDDEN: 403,
        HTTP_STATUS_NOT_FOUND: 404,
    };

    REQUEST_METHODS = {
        GET: 'get',
        PUT: 'put',
        POST: 'post',
        DELETE: 'delete'
    };
    
    STATUS = {
        ACTIVE: 'active',
        REMOVED: 'removed',
        ELIGIBLE: 'Eligible',
        NOT_ELIGIBLE: 'Not Eligible'
    };

    MESSAGES = {
        EMAIL_EMPTY: 'Email is empty',
        PASSWORD_EMPTY: 'Password is empty',
        PASSWORD_MISMATCH: 'Password and confirm password do not match',
        PASSWORD_LENGTH: 'Password must contain at least six characters!',
        INVALID_DATE: 'Invalid date',
        INVALID_TOKEN: 'Invalid Token',
        INVALID_BIRTHDATE: 'Invalid Date of Birth',
    };

    MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    MONTH_DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

    GENDERS = {
        MALE: 'Male',
        FEMALE: 'Female',
    };

    GENDER_ARRAY = ['Male', 'Female', 'Other'];

    USER_TYPES = {
        USER: 'user',
        ADMIN: 'admin'
    };

    BLOOD_TYPES = ['A', 'B', 'O', 'AB'];

    MEDDRA_TYPES = {
        LLT: 'llt',
        PT: 'pt'
    };

    TEETH = ['Incisor', 'Canine',  'Premolar', 'Molar'];

    CATEGORIES = {
        MEDICAL_HISTORY: 'Medical History',
        DENTAL_RECORD: 'Dental Record',
        TREATMENT_IMMUNIZATION: 'Treatment, Medication & Immunization',
        TREATMENT_IMMUNIZATION_OLD: 'Treatment and Immunization',
    };

    CATEGORIES_NOTES = {
        'en': {
            'MEDICAL HISTORY': 'Medical History',
            'DENTAL RECORD': 'Dental Record',
            // 'TREATMENT AND IMMUNIZATION': 'Treatment and Immunization'
        },
        'zh': {
            'MEDICAL HISTORY': '个人医疗记录',
            'DENTAL RECORD': '牙科记录',
            // 'TREATMENT AND IMMUNIZATION': '免疫接种和治疗方案'
        },
        'fr': {
            'MEDICAL HISTORY': 'Antécédents médicaux',
            'DENTAL RECORD': 'Dossiers dentaires',
            // 'TREATMENT AND IMMUNIZATION': 'Vaccinations et traitements'
        },
        'de': {
            'MEDICAL HISTORY': 'Krankengeschichte',
            'DENTAL RECORD': 'Zahnarztakte',
            // 'TREATMENT AND IMMUNIZATION': 'Impfungen & Behandlung'
        },
        'es': {
            'MEDICAL HISTORY': 'Historial Médico',
            'DENTAL RECORD': 'Registro dental',
            // 'TREATMENT AND IMMUNIZATION': 'Vacunas y Tratamiento'
        }
        // MEDICAL_HISTORY: 'Medical History',
        // DENTAL_RECORD: 'Dental Record',
    };

    CATEGORIES_NOTES_NEW = {
        'en': {
            'MEDICAL HISTORY': 'Medical History',
            'DENTAL RECORD': 'Dental Record (ISO-System)',
            //'TREATMENT AND IMMUNIZATION': 'Treatment, Medication & Immunization'
        },
        'zh': {
            'MEDICAL HISTORY': '个人医疗记录',
            'DENTAL RECORD': '牙科记录 (ISO体系)',
            //'TREATMENT AND IMMUNIZATION': '免疫接种和治疗方案'
        },
        'fr': {
            'MEDICAL HISTORY': 'Antécédents médicaux',
            'DENTAL RECORD': 'Dossiers dentaires (système ISO)',
            //'TREATMENT AND IMMUNIZATION': 'Vaccinations et traitements'
        },
        'de': {
            'MEDICAL HISTORY': 'Krankengeschichte',
            'DENTAL RECORD': 'Zahnmedizinische Unterlagen (ISO-System)',
            //'TREATMENT AND IMMUNIZATION': 'Impfungen & Behandlung'
        },
        'es': {
            'MEDICAL HISTORY': 'Historial Médico',
            'DENTAL RECORD': 'Registros dentales (sistema ISO)',
            //'TREATMENT AND IMMUNIZATION': 'Vacunas y Tratamiento'
        },
        // MEDICAL_HISTORY: 'Medical History',
        // DENTAL_RECORD: 'Dental Record',

        
    };


    DATE_BIRTH = {
        'en': {
            'MM': 'MM',
            'DD': 'DD',
            'YYYY': 'YYYY'
        },
        'zh': {
            'MM': '年',
            'DD': '月',
            'YYYY': '日'
        },
        'fr': {
            'MM': 'MM',
            'DD': 'JJ',
            'YYYY': 'AAAA'
        },
        'de': {
            'MM': 'MM',
            'DD': 'TT',
            'YYYY': 'JJJJ'
        },
        'es': {
            'MM': 'MM',
            'DD': 'DD',
            'YYYY': 'AAAA'
        }
       
    };

    UPDATE_PROFILE = {
        'en': 'User profile has been updated successfully.',
        'zh': '用户个人资料已成功更新。?',
        'fr': 'Le profil utilisateur a été mis à jour avec succès.',
        'de': 'Benutzerprofil wurde erfolgreich aktualisiert.',
        'es': 'El perfil del usuario ha sido actualizado correctamente.'
    };

    OTHERS = {
        TOKEN: 'token',
        ID: 'id',
        FILE: 'file'
    };

    DOSE_FREQUENCIES = {
        en: ['One Time Daily', 'Two Times Daily', 'Three Times Daily', 'Four Times Daily'],
        zh: ['每日一次', '每日两次', '每日三遍', '每日四次'],
        de: ['Einmal täglich', 'Zweimal täglich', 'Dreimal täglich', 'Viermal täglich'],
        es: ['Una vez al día', 'Dos veces al día', 'Tres veces al día', 'Cuatro veces al día'],
        fr: ['Une fois par jour', 'Deux fois par jour', 'Trois fois par jour', 'Quatre fois par jour']
    };

    DOSE_FREQUENCIES_WITHOUT_LANG = ['One Time Daily', 'Two Times Daily', 'Three Times Daily', 'Four Times Daily'];
    
    
    DOSE_MEASURES = ['ml', 'mg'];

    DOSE_MEASURES_LANG = {
        en: ['ml', 'mg'],
        zh: ['毫升', '毫克'],
        de: ['ml', 'mg'],
        es: ['ml', 'mg'],
        fr: ['ml', 'mg']
    };

    MEASURES = [
        {
            'en': {
                'ml': 'ml',
                'mg': 'mg',
            },
            'zh': {
                'ml': '毫升',
                'mg': '毫克',
            },
            'fr': {
                'ml': 'ml',
                'mg': 'mg',
            },
            'de': {
                'ml': 'ml',
                'mg': 'mg',
            },
            'es': {
                'ml': 'ml',
                'mg': 'mg',
            }
        }
        
    ];

    FREQUENCY = [
        {
            'en': {
                'One Time Daily': 'One Time Daily',
                'Two Times Daily': 'Two Times Daily',
                'Three Times Daily': 'Three Times Daily',
                'Four Times Daily': 'Four Times Daily',
            },
            'zh': {
                'One Time Daily': '每日一次',
                'Two Times Daily': '每日两次',
                'Three Times Daily': '每日三遍',
                'Four Times Daily': '每日四次',
            },
            'fr': {
                'One Time Daily': 'Une fois par jour',
                'Two Times Daily': 'Deux fois par jour',
                'Three Times Daily': 'Trois fois par jour',
                'Four Times Daily': 'Quatre fois par jour',
            },
            'de': {
                'One Time Daily': 'Einmal täglich',
                'Two Times Daily': 'Zweimal täglich',
                'Three Times Daily': 'Dreimal täglich',
                'Four Times Daily': 'Viermal täglich',
            },
            'es': {
                'One Time Daily': 'Una vez al día',
                'Two Times Daily': 'Dos veces al día',
                'Three Times Daily': 'Tres veces al día',
                'Four Times Daily': 'Cuatro veces al día',
            }
        }
        
    ];

    PERMANENT_TOP_LEFT = [21, 22, 23, 24, 25, 26, 27, 28];
    PERMANENT_TOP_RIGHT = [18, 17, 16, 15, 14, 13, 12, 11];
    PERMANENT_BOTTOM_LEFT = [31, 32, 33, 34, 35, 36, 37, 38];
    PERMANENT_BOTTOM_RIGHT = [48, 47, 46, 45, 44, 43, 42, 41];

    DECIDUOUS_TOP_LEFT = [61, 62, 63, 64, 65];
    DECIDUOUS_TOP_RIGHT = [55, 54, 53, 52, 51];
    DECIDUOUS_BOTTOM_LEFT = [71, 72, 73, 74, 75];
    DECIDUOUS_BOTTOM_RIGHT = [85, 84, 83, 82, 81];

    ALL_TEETH = [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 51, 52, 53, 54, 55, 61, 62, 63, 64, 65, 71, 72, 73, 74, 75, 81, 82, 83, 84, 85];
    
    FILE_FORMATS = {IMAGE: ['image/jpeg', 'image/png'], DOC: ['application/pdf']};
    PAGES = ['About Us', 'Privacy Policy', 'Terms', 'Help and Supports'];

    LANGUAGE_LIST = [
        'en',
        'zh',
        'fr',
        'de',
        'es'
    ];
    LANGUAGE_COLUMNS = {
        1: 'english',
        2: 'chinese',
        3: 'french',
        4: 'german',        
        5: 'italian',
        6: 'spanish'        
    };

    LANGUAGE_COLUMNS_VALUE = {
        'en': '1',
        'zh': '2',
        'fr': '3',
        'de': '4',
        'es': '6'
    };

    REMOVE_ALERT = {
        'en': 'Remove this member?',
        'zh': '删除该成员?',
        'fr': 'Supprimer ce membre?',
        'de': 'Entfernen Sie dieses Element?',
        'es': 'Eliminar este miembro?'
    };

    REMOVE_ASSET_ALERT = {
        'en': 'Are you sure you want to delete this report?',
        'zh': '您确定要删除此报告吗?',
        'fr': 'Voulez-vous vraiment supprimer ce rapport?',
        'de': 'Möchten Sie diesen Bericht wirklich löschen?',
        'es': '¿Seguro que quieres eliminar este informe?'
    };

    YES = {
        'en': 'Yes',
        'zh': '是',
        'fr': 'Oui',
        'de': 'Ja',
        'es': 'si',
    };

    TEETH_PROCEDURES = ['cleaning', 'fluoride', 'filling', 'gum_treatment', 'rct', 'surgical_process', 'extraction'];
    TEETH_PROCEDURES_EN = ['cleaning_en', 'fluoride_en', 'filling_en', 'gum_treatment_en', 'rct_en', 'surgical_process_en', 'extraction_en'];
    TEETH_PROCEDURES_FR = ['cleaning_fr', 'fluoride_fr', 'filling_fr', 'gum_treatment_fr', 'rct_fr', 'surgical_process_fr', 'extraction_fr'];
    TEETH_PROCEDURES_DE = ['cleaning_de', 'fluoride_de', 'filling_de', 'gum_treatment_de', 'rct_de', 'surgical_process_de', 'extraction_de'];
    TEETH_PROCEDURES_ES = ['cleaning_es', 'fluoride_es', 'filling_es', 'gum_treatment_es', 'rct_es', 'surgical_process_es', 'extraction_es'];
    TEETH_PROCEDURES_ZH = ['cleaning_zh', 'fluoride_zh', 'filling_zh', 'gum_treatment_zh', 'rct_zh', 'surgical_process_zh', 'extraction_zh'];

    CATEGORY_TITLES = {
        'en': {
            'MEDICAL HISTORY': 'Medical History',
            'DENTAL RECORD': 'Dental Record (ISO-System)',
            'TREATMENT AND IMMUNIZATION': 'Treatment, Medication & Immunization'
        },
        'zh': {
            'MEDICAL HISTORY': '个人医疗记录',
            'DENTAL RECORD': '牙科记录（通过ISO系统认证的机构）',
            'TREATMENT AND IMMUNIZATION': '治疗，药物和免疫'
        },
        'fr': {
            'MEDICAL HISTORY': 'Antécédents médicaux',
            'DENTAL RECORD': 'Dossiers dentaires (système ISO)',
            'TREATMENT AND IMMUNIZATION': 'Traitement, médicaments et vaccination'
        },
        'de': {
            'MEDICAL HISTORY': 'Krankengeschichte',
            'DENTAL RECORD': 'Zahnmedizinische Unterlagen (ISO-System)',
            'TREATMENT AND IMMUNIZATION': 'Behandlungen, Medikamente & Impfungen'
        },
        'es': {
            'MEDICAL HISTORY': 'Historial Médico',
            'DENTAL RECORD': 'Registros Dentales(sistema ISO)',
            'TREATMENT AND IMMUNIZATION': 'Tratamiento, medicación y vacunación'
        },

    };

    CATEGORY_TITLES_LABEL = {
        
        'MEDICAL_HISTORY' : {
            'en': {
                'MEDICAL HISTORY': 'Medical History',
            },
            'zh': {
                'MEDICAL HISTORY': '个人医疗记录',
            },
            'fr': {
                'MEDICAL HISTORY': 'Antécédents médicaux',
            },
            'de': {
                'MEDICAL HISTORY': 'Krankengeschichte',
            },
            'es': {
                'MEDICAL HISTORY': 'Historial Médico',
            },
        },

        'DENTAL_RECORD' : {
            'en': {
                'DENTAL RECORD': 'Dental Record (ISO-System)',
            },
            'zh': {
                'DENTAL RECORD': '牙科记录 (ISO体系)',
            },
            'fr': {
                'DENTAL RECORD': 'Dossiers dentaires (système ISO)',
            },
            'de': {
                'DENTAL RECORD': 'Zahnmedizinische Unterlagen (ISO-System)',
            },
            'es': {
                'DENTAL RECORD': 'Registros dentales (sistema ISO)',
            },
        },

        'DENTAL_RECORD_NEW' : {
            'en': {
                'DENTAL RECORD': 'Dental Record',
            },
            'zh': {
                'DENTAL RECORD': '牙科记录',
            },
            'fr': {
                'DENTAL RECORD': 'Dossiers dentaires',
            },
            'de': {
                'DENTAL RECORD': 'Zahnmedizinische Unterlagen',
            },
            'es': {
                'DENTAL RECORD': 'Registros dentales',
            },
        },

        'TREATMENT_IMMUNIZATION' : {
            'en': {
                'TREATMENT AND IMMUNIZATION': 'Treatment, Medication & Immunization'
            },
            'zh': {
                'TREATMENT AND IMMUNIZATION': '免疫接种和治疗方案'
            },
            'fr': {
                'TREATMENT AND IMMUNIZATION': 'Vaccinations et traitements'
            },
            'de': {
                'TREATMENT AND IMMUNIZATION': 'Impfungen & Behandlung'
            },
            'es': {
                'TREATMENT AND IMMUNIZATION': 'Vacunas y Tratamiento'
            },
        }
    };

    PROCEDURES = [
        {
            'en': {
                'title': 'cleaning',
                'type': 'cleaning',
                'label': 'Cleaning',
            },
            'zh': {
                'title': 'cleaning',
                'type': 'cleaning',
                'label': '洗牙',
            },
            'fr': {
                'title': 'cleaning',
                'type': 'cleaning',
                'label': 'Nettoyage',
            },
            'de': {
                'title': 'cleaning',
                'type': 'cleaning',
                'label': 'Reinigung',
            },
            5: {
                'title': 'cleaning',
                'type': 'cleaning',
                'label': 'Cleaning',
            },
            'es': {
                'title': 'cleaning',
                'type': 'cleaning',
                'label': 'Limpieza',
            }
        },
        {
            'en': {
                'title': 'fluoride',
                'type': 'fluoride',
                'label': 'Fluoride'
            },
            'zh': {
                'title': 'fluoride',
                'type': 'fluoride',
                'label': '氟化物治疗',
            },
            'fr': {
                'title': 'fluoride',
                'type': 'fluoride',
                'label': 'Fluorure',
            },
            'de': {
                // 'title': 'fluorid',
                'title': 'fluoride',
                'type': 'fluoride',
                'label': 'Fluorid',
            },
            5: {
                'title': 'fluoride',
                'type': 'fluoride',
                'label': 'Fluoride',
            },
            'es': {
                'title': 'fluoride',
                'type': 'fluoride',
                'label': 'Fluoruro',
            }
        },
        {
            'en': {
                'title': 'filling',
                'type': 'restoration/filling',
                'label': 'Restoration/Filling'
            },
            'zh': {
                'title': 'filling',
                'type': 'restoration/filling',
                'label': '牙齿修复/补牙',
            },
            'fr': {
                'title': 'filling',
                'type': 'restoration/filling',
                'label': 'Restauration/Remplissage',
            },
            'de': {
                'title': 'filling',
                'type': 'restoration/filling',
                'label': 'Restaurierung/Füllung',
            },
            5: {
                'title': 'filling',
                'type': 'restoration/filling',
                'label': 'Restoration/Filling',
            },
            'es': {
                'title': 'filling',
                'type': 'restoration/filling',
                'label': 'Restauración/Empaste',
            }
        },
        {
            'en': {
                'title': 'gum_treatment',
                'type': 'periodontal/gum treatment',
                'label': 'Periodontal/Gum treatment'
            },
            'zh': {
                'title': 'gum_treatment',
                'type': 'periodontal/gum treatment',
                'label': '牙周/牙龈治疗',
            },
            'fr': {
                'title': 'gum_treatment',
                'type': 'periodontal/gum treatment',
                'label': 'Traitement parodontal/des gencives',
            },
            'de': {
                'title': 'gum_treatment',
                'type': 'periodontal/gum treatment',
                'label': 'Parodontal- / Zahnfleischbehandlung',
            },
            5: {
                'title': 'gum_treatment',
                'type': 'periodontal/gum treatment',
                'label': 'Periodontal/Gum treatment',
            },
            'es': {
                'title': 'gum_treatment',
                'type': 'periodontal/gum treatment',
                'label': 'Tratamiento periodontal/Encías',
            }
        },
        {
            'en': {
                'title': 'rct',
                'type': 'root canal treatment',
                'label': 'Root canal treatment'
            },
            'zh': {
                'title': 'rct',
                'type': 'root canal treatment',
                'label': '根管治疗',
            },
            'fr': {
                'title': 'rct',
                'type': 'root canal treatment',
                'label': 'Traitement du canal de la racine',
            },
            'de': {
                'title': 'rct',
                'type': 'root canal treatment',
                'label': 'Wurzelkanalbehandlung',
            },
            5: {
                'title': 'rct',
                'type': 'root canal treatment',
                'label': 'rct',
            },
            'es': {
                'title': 'rct',
                'type': 'root canal treatment',
                'label': 'Tratamiento de conducto',
            }
        },
        {
            'en': {
                'title': 'extraction',
                'type': 'extraction',
                'label': 'Extraction'
            },
            'zh': {
                'title': 'extraction',
                'type': 'extraction',
                'label': '拔牙',
            },
            'fr': {
                'title': 'extraction',
                'type': 'extraction',
                'label': 'Extraction',
            },
            'de': {
                'title': 'extraction',
                'type': 'extraction',
                'label': 'Zahnziehen',
            },
            5: {
                'title': 'extraction',
                'type': 'extraction',
                'label': 'extraction',
            },
            'es': {
                'title': 'extraction',
                'type': 'extraction',
                'label': 'Extracción',
            }
        },
        {
            'en': {
                'title': 'surgical_process',
                'type': 'surgical procedure',
                'label': 'Surgical procedure'
            },
            'zh': {
                'title': 'surgical_process',
                'type': 'surgical procedure',
                'label': '手术治疗',
            },
            'fr': {
                'title': 'surgical_process',
                'type': 'surgical procedure',
                'label': 'Procédure chirurgicale',
            },
            'de': {
                'title': 'surgical_process',
                'type': 'surgical procedure',
                'label': 'Chirurgischer Eingriff',
            },
            5: {
                'title': 'surgical_process',
                'type': 'surgical procedure',
                'label': 'Surgical procedure'
            },
            'es': {
                'title': 'surgical_process',
                'type': 'surgical procedure',
                'label': 'Procedimiento quirúrgico',
            }
        },
    ];

    FILE_FORMAT_ERRORS = {
        'en': {
            INVALID_FILE_FORMAT: 'Invalid File Format',
            INVALID_IMAGE_FORMAT: 'Invalid Image Format',
            SELECT_IMAGE: 'Please select file(s)',
            DOWNLOAD_PDF: 'Download PDF',
            ENTER_MESSAGE: 'Please Enter Message',
        },
        'zh': {
            INVALID_FILE_FORMAT: '无效文件格式',
            INVALID_IMAGE_FORMAT: '无效图片格式',
            SELECT_IMAGE: '请选择文件',
            DOWNLOAD_PDF: 'Download PDF',
            ENTER_MESSAGE: '请输入留言',
        },
        'fr': {
            INVALID_FILE_FORMAT: 'Format de fichier invalide',
            INVALID_IMAGE_FORMAT: 'Format d\'image invalide',
            SELECT_IMAGE: 'Veuillez sélectionner le (s) fichier (s)',
            DOWNLOAD_PDF: 'Download PDF',
            ENTER_MESSAGE: 'Veuillez saisir un message',
        },
        'de': {
            INVALID_FILE_FORMAT: 'Ungültiges Dateiformat',
            INVALID_IMAGE_FORMAT: 'Ungültiges Bildformat',
            SELECT_IMAGE: 'Bitte wählen Sie Datei (en)',
            DOWNLOAD_PDF: 'Download PDF',
            ENTER_MESSAGE: 'Bitte Nachricht eingeben',
        },
        'es': {
            INVALID_FILE_FORMAT: 'Formato de archivo inválido',
            INVALID_IMAGE_FORMAT: 'Formato de imagen inválido',
            SELECT_IMAGE: 'Por favor seleccione archivo (s)',
            DOWNLOAD_PDF: 'Download PDF',
            ENTER_MESSAGE: 'Por favor, introduzca el mensaje',
        }
    };

    DATE_OF_BIRTH_ERROR = {
        'en': {
            'DATEOFBIRTH': 'Provide correct date of birth including Day, Month, Year',
        },
        'zh': {
            'DATEOFBIRTH': '提供正确的出生日期，包括日，月，年',
        },
        'fr': {
            'DATEOFBIRTH': "Fournissez la date de naissance correcte, y compris le jour, le mois et l'année",
        },
        'de': {
            'DATEOFBIRTH': 'Geben Sie das korrekte Geburtsdatum an, einschließlich Tag, Monat, Jahr',
        },
        'es': {
            'DATEOFBIRTH': 'Proporcione la fecha de nacimiento correcta, incluido el día, mes y año.',
        }
    };

    START_DATE_END_DATE_ERROR = {
        'en': {
            'STARTDATEREQUIREDBEFOREENDDATE': 'Provide end date after providing the start date',
            'STARTDATEREQUIRED': 'Start date is required',
        },
        'zh': {
            'STARTDATEREQUIREDBEFOREENDDATE': '提供开始日期后提供结束日期',
            'STARTDATEREQUIRED': '必须输入开始日期',
        },
        'fr': {
            'STARTDATEREQUIREDBEFOREENDDATE': "Indiquez la date de fin après avoir fourni la date de début",
            'STARTDATEREQUIRED': 'La date de début est obligatoire',
        },
        'de': {
            'STARTDATEREQUIREDBEFOREENDDATE': 'Geben Sie das Enddatum an, nachdem Sie das Startdatum angegeben haben',
            'STARTDATEREQUIRED': 'Startdatum ist erforderlich',
        },
        'es': {
            'STARTDATEREQUIREDBEFOREENDDATE': 'Proporcione la fecha de finalización después de proporcionar la fecha de inicio',
            'STARTDATEREQUIRED': 'Se requiere fecha de inicio',
        }
    };

    BLOOD_TYPE_ERROR = {
        'en': {
            'BLOODTYPE': 'Blood type is required',
        },
        'zh': {
            'BLOODTYPE': '需要血型',
        },
        'fr': {
            'BLOODTYPE': "Le groupe sanguin est requis",
        },
        'de': {
            'BLOODTYPE': 'Blutgruppe ist erforderlich',
        },
        'es': {
            'BLOODTYPE': 'Se requiere tipo de sangre',
        }
    };

    LEGEND = {
        'en': {
            'SELECTLEGEND': 'Select Legend',
        },
        'zh': {
            'SELECTLEGEND': '选择图例',
        },
        'fr': {
            'SELECTLEGEND': "Sélectionnez la légende",
        },
        'de': {
            'SELECTLEGEND': 'Wählen Sie Legende',
        },
        'es': {
            'SELECTLEGEND': 'Seleccionar leyenda',
        }
    };

    RESET_PASSWORD_ERROR = {
        'en': {
            EMAIL_EMPTY: 'Email is empty',
            PASSWORD_EMPTY: 'Password is empty',
            PASSWORD_MISMATCH: 'Password and confirm password do not match',
            PASSWORD_LENGTH: 'Password must contain at least six characters!',
        },
        'zh': {
            EMAIL_EMPTY: '电子邮件为空',
            PASSWORD_EMPTY: '密码为空',
            PASSWORD_MISMATCH: '密码和确认密码不匹配',
            PASSWORD_LENGTH: '密码必须至少包含六个字符',
        },
        'fr': {
            EMAIL_EMPTY: "L'email est vide",
            PASSWORD_EMPTY: 'Le mot de passe est vide',
            PASSWORD_MISMATCH: 'Le mot de passe et la confirmation du mot de passe ne correspondent pas',
            PASSWORD_LENGTH: 'Le mot de passe doit contenir au moins six caractères!',
        },
        'de': {
            EMAIL_EMPTY: 'E-Mail ist leer',
            PASSWORD_EMPTY: 'Passwort ist leer',
            PASSWORD_MISMATCH: 'Passwort und Passwort bestätigen stimmen nicht überein',
            PASSWORD_LENGTH: 'Das Passwort muss mindestens sechs Zeichen enthalten!',
        },
        'es': {
            EMAIL_EMPTY: 'El correo esta vacio',
            PASSWORD_EMPTY: 'La contraseña esta vacía',
            PASSWORD_MISMATCH: 'Contraseña y confirmar contraseña no coinciden',
            PASSWORD_LENGTH: '¡La contraseña debe contener al menos seis caracteres!',
        }
    };

    // ===============for cleaning and fluride only 23.12.2020=================//
    PROCEDURES_CLEANING = [
        {
            'en': {
                'title': 'cleaning',
            },
            'zh': {
                'title': '洗牙',
            },
            'fr': {
                'title': 'nettoyage',
            },
            'de': {
                'title': 'reinigung',
            },
            'es': {
                'title': 'limpieza',
            }
        },
    ];

    PROCEDURES_FLUORIDE = [
        {
            'en': {
                'title': 'fluoride',
            },
            'zh': {
                'title': '氟化物治疗',
            },
            'fr': {
                'title': 'fluorure',
            },
            'de': {
                'title': 'fluorid',
            },
            'es': {
                'title': 'fluoruro',
            }
        },
        
    ];

    GENDER = [
        {
            'en': {
                'Female': 'Female',
                'Male': 'Male',
            },
            'zh': {
                'Female': '女性',
                'Male': '男性',
            },
            'fr': {
                'Female': 'Femelle',
                'Male': 'Femelle',
            },
            'de': {
                'Female': 'Weiblich',
                'Male': 'Männlich',
            },
            'es': {
                'Female': 'Mujer',
                'Male': 'Masculina',
            }
        },
        
    ];
    
}
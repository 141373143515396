import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { UserService } from '../../../services/user.service';
import { SessionService } from '../../../services/session.service';
import { User } from '../../../models/user';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { NgForm, FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { TreatmentService } from '../../../services/treatment.service';
import { Treatment } from '../../../models/treatment';

@Component({
  selector: 'app-treatment-report',
  templateUrl: './treatment-report.component.html',
  styleUrls: ['./treatment-report.component.css']
})
export class TreatmentReportComponent implements OnInit {

  displayedColumns: string[] = ['sn', 'email', 'blood_group', 'organ_donor', 'date_of_birth', 'gender', 'smoker', 'allergy', 'created_at',  'medication_name', 'brand_name', 'start_date', 'end_date', 'administration','purpose'];
  
  dataSource: any = [];
  users: any = [];
  user_status: string;
  filteredMedicines: any;
  public excelData: any = [];
  public genderselection: string;
  public startDate: string;
  public endDate: string;
  public age: string;
  lang: string;
  medicineCtrl: FormControl; 
  medicines: any = [];   

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) sort: MatSort;
  medicineId: any;
  treatment: any;
  nameCtrl: FormControl;
  filteredNames: any;
  names: string[] = [];    
  medicineValue: string;
  
  constructor(
      public constant: CONSTANTS,
      public userServ: UserService,
      public sessionServ: SessionService,
      private snackBar: MatSnackBar,
      public loaderServ: NgxUiLoaderService,
      public treatmentServ: TreatmentService,
  ) { }

  ngOnInit() {
      this.lang = this.sessionServ.getLang();
      this.records();
      this.medicineCtrl = new FormControl();
      this.setMedicineCtrl();
      this.treatment = new Treatment();

      this.treatmentServ.getMedicines()
      .then(res => {
          this.medicines = res.response.data;                

          if(this.medicines) {
              var flags = [];
              for(let i=0; i<this.medicines.length; i++) {
                  if(flags[this.medicines[i].name]) 
                      continue;
                      
                  flags[this.medicines[i].name] = true;
                  this.names.push(this.medicines[i].name);                
              }
          }
          
          this.setMedicineCtrl();
          // this.setNameCtrl();
      });
      // this.setNameCtrl();
  }

  ngAfterViewInit() {
      this.dataSource.sort = this.sort;
  }

  setMedicineCtrl() {
    this.filteredMedicines = this.medicineCtrl.valueChanges
        .pipe(
            startWith(''),
            map(medicine => medicine ? this.filterMedicines(medicine) : this.medicines.slice())
        );
  }

filterMedicines(data: any) {                
  this.medicineValue = data;
  if(data.composition) {
      this.medicineValue = data.composition;
      this.medicineId = data.id;   
      // this.treatment.brand_name = data.name;
  } else 
      this.medicineId = null;
     
  this.treatment.composition = this.medicineValue;      

  return this.medicines.filter(medicine =>
      medicine.composition.toLowerCase().includes(this.medicineValue.toLowerCase()) === true || medicine.name.toLowerCase().includes(this.medicineValue.toLowerCase()) === true
  );
} 

displayMedicine(data: any): string | undefined {
  return data ? data.composition : undefined;
}

medicineSelected() {
  var flags = [];
  this.names = [];

  for(let i=0; i<this.medicines.length; i++) {
      if(this.medicines[i].composition == this.medicineValue) {
          if(flags[this.medicines[i].name]) 
              continue;
              
          flags[this.medicines[i].name] = true;
          this.names.push(this.medicines[i].name);   
      }
  }
  
  this.nameCtrl = new FormControl();
  this.setNameCtrl();         
  // this.treatment.title = this.medicineValue;    
}

setNameCtrl() {
  this.filteredNames = this.nameCtrl.valueChanges
      .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.names.slice())
      );
}

filterNames(data: any) {           
  this.treatment.brand_name = data;

  for(let i=0; i<this.medicines.length; i++) {
      if(data == this.medicines[i].name) {
          this.medicineId = this.medicines[i].id;
          break;
      }
  }

  return this.names.filter(name =>
      name.toLowerCase().includes(data.toLowerCase()) === true
  );
}

  records() {
    this.loaderServ.start();
      this.userServ.recordsReportsTreatment()
          .then(res => {   
            // this.loaderServ.stop();             
              this.users = res.response;
              console.log(this.users);
              this.applyTableBehaviour(this.users);
          });
  }

  
  applyTableBehaviour(data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.loaderServ.stop();        
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  } 


  applyFilter() {
    let data = [];
    let startDateInput = this.formatDate(this.startDate);
    let startDate = Date.parse(startDateInput);
    let age = this.age;
    
    let endDateInput = this.formatDate(this.endDate);
    let endDate = Date.parse(endDateInput);
    let gender = this.genderselection;
    let medicinesName = new FormControl();
    // console.log(medicinesName.value);
    if(medicinesName.value != "" && medicinesName.value != null && typeof(medicinesName.value)!="undefined") {
       medicinesName = this.medicineCtrl;
    } 

    
    if(age || gender || startDate || endDate || medicinesName) {
      if(this.users) {
        
        let conditionList = [];
        if(typeof(age)!="undefined" && age!="")
        {
          conditionList.push("age")
        }

        if(typeof(gender)!="undefined" && gender!="")
        {
          conditionList.push("gender")
        }
        if(typeof(medicinesName)!="undefined" && medicinesName.value!="" && medicinesName.value != null)
        {
          conditionList.push("medicinesName")
        }

        if(startDate && !endDate)
        {
          conditionList.push("startDate")
        }

        if(endDate && !startDate)
        {
          conditionList.push("endDate");
        }

        if(endDate && startDate)
        {
          conditionList.push("startEndDate");     
        }

        data=this.users.filter(user => {
          let userdetails = [];
            let condition = true;
            let user_joining = Date.parse(user.created_at);
            let start_data = Date.parse(user.start_date);
            let end_data = Date.parse(user.end_date);
            let gender_value = user.gender;
            let age_value = user.age;
            let usermMdicinesName = user.medication_name;
            
            conditionList.forEach(function(item){
              if(condition!=false && item=="age"){
                  condition = age== user.age
              }
              if(condition!=false && item=="gender"){
                  condition = gender== user.gender
              }
              if(condition!=false && item=="medicinesName"){
                console.log(medicinesName);
                  condition = medicinesName== user.medication_name
              }
              // startDate = 1579737600000;
              if(condition!=false && item=="startDate" ){
                if(user.start_date != "" && Date.parse(user.start_date) != null) {
                  let convert_date = Date.parse(user.start_date);
                  condition = startDate == convert_date
                }
              }

              if(condition!=false && item=="endDate" ){
                if(user.end_date != "" && Date.parse(user.end_date) != null) {
                  let convert_date = Date.parse(user.end_date);
                  condition = endDate == convert_date
                }
              }
              
              if(condition!=false && item=="startEndDate"){
                let convert_date_end = Date.parse(user.end_date);
                let convert_date_start = Date.parse(user.start_date);
                  //  if (startDate <= convert_date_start && convert_date_end <= endDate) {
                    condition = (startDate <= convert_date_start && convert_date_end <= endDate)
                  // }
              }

            })
           
            return condition;

            // if(usermMdicinesName == medicinesName && user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age)
            // if(usermMdicinesName == medicinesName)
            // data.push(user);
            // console.log(medicinesName+ " "+endDate+ " "+startDate+" "+age+ " "+gender)
            // if(usermMdicinesName == medicinesName && user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
            //   console.log("LLT, Start and End date, Age, Gender");
            //   data.push(user);
            // } else if(user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
            //   console.log("Start and End date, Age, Gender");
            //   data.push(user);
            // } else if(medicinesName && usermMdicinesName == medicinesName && endDate && startDate && user_joining <= endDate && user_joining >= startDate && gender && gender == gender_value) {
            //   console.log("LLT,  Start and End date, Gender");
            //   data.push(user);
            // } else if(medicinesName && usermMdicinesName == medicinesName && endDate && startDate && user_joining <= endDate && user_joining >= startDate && age && age_value == age) {
            //   console.log("LLT , Start and End date, Age");
            //   data.push(user);
            // } else if(medicinesName && usermMdicinesName == medicinesName && gender && gender == gender_value && age && age_value == age) {
            //   console.log("LLT , Gender, Age");
            //   data.push(user);
            // } else if(gender && gender == gender_value && endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
            //   console.log("Start and End date, Gender");
            //   data.push(user);
            // } else if(age && age_value == age && endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
            //   console.log("Start and End date, Age");
            //   data.push(user);
            // } else if(gender && age && gender == gender_value && age_value == age) {
            //   console.log("Gender, Age");
            //   data.push(user);
            // } else if(medicinesName && usermMdicinesName == medicinesName && endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
            //   console.log("LLT, Start and End date");
            //   data.push(user);
            // } else if(medicinesName && usermMdicinesName == medicinesName && gender && gender == gender_value) {
            //   console.log("LLT,Gender");
            //   data.push(user);
            // } else if(medicinesName && usermMdicinesName == medicinesName && age && age_value == age) {
            //   console.log("LLT,Age");
            //   data.push(user);
            // } else if(age && age_value == age) {
            //   console.log("Age");
            //    data.push(user);
            // } else if(gender && gender == gender_value) {
            //   console.log("Gender");
            //   data.push(user);
            // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
            //   console.log("Start and End date");
            //   data.push(user);
            // } if(medicinesName && usermMdicinesName == medicinesName){
            //   console.log("Presenting");
            //   data.push(user);
            // }
          
        });
        this.applyTableBehaviour(data);
      }
    }
  }

  showMessage(res: any) {    
  if(this.constant.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
    this.snackBar.open(res.message, '', {
      duration: 3000,
      panelClass: ['green-snackbar']
    });
  else
    this.snackBar.open(res.message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
}

downloadCSV() {
  this.excelData = [];
  this.excelData.push(this.displayedColumns);
  
  let that = this;
  
  this.dataSource.filteredData.filter(function(element, index, array) {
    let email: string;  
    let fname: string;  
    let medical_id: string;  
    let date_of_birth: string;  
    let smoker: string;  
    let organ_donor: string;
    let primary_emergency_contact_number: string;
    let secondary_emergency_contact_number: string;
    let doctor_contact_info: string;
    if(element.user_consent !='0') 
      {
         email = element.email;
         fname = element.name;
         medical_id = element.medical_id;
         primary_emergency_contact_number = element.primary_emergency_contact_number;
         secondary_emergency_contact_number = element.secondary_emergency_contact_number;
         doctor_contact_info = element.doctor_contact_info;
      } else {
         email = '*****';
         fname = '*****';
         medical_id = '*****';
         primary_emergency_contact_number = '*****';
         secondary_emergency_contact_number = '*****';
         doctor_contact_info = '*****';
      }

      if(element.smoker == 1){
        smoker = 'Yes';
      } else if(element.smoker == 0) {
        smoker = 'No';
      } else {
        smoker = 'No';
      }

      if(element.organ_donor == 1){
        organ_donor = 'Yes';
      } else if(element.organ_donor == 0) {
        organ_donor = 'No';
      } else {
        organ_donor = 'No';
      }

      if(element.date_of_birth) {
        date_of_birth = element.date_of_birth
      }
      
      that.excelData.push([ index + 1 , email, element.blood_group, organ_donor, date_of_birth, element.gender, smoker,  element.allergy, element.created_at, element.medication_name, element.brand_name, element.start_date, element.end_date, element.administration, element.purpose]);  
  });

  new AngularCsv(this.excelData, 'treatment');
}

resetFilter() {
  this.dataSource.filter = '';
  this.startDate = '';
  this.age = '';
  this.endDate = '';
  this.genderselection = '';
  this.medicineCtrl = new FormControl(false);
  this.records();
}

}

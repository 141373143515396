import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { MedicalHistoryService } from '../../../services/medical-history.service';
import { MedicalHistory } from '../../../models/medical-history';
import { TreatmentService } from 'src/app/services/treatment.service';
import { MeddraService } from 'src/app/services/meddra.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-add-medical-history',
  templateUrl: './add-medical-history.component.html',
  styleUrls: ['./add-medical-history.component.css']
})
export class AddMedicalHistoryComponent implements OnInit {

  medicalHistory: MedicalHistory;
  message: string;
  lltCtrl: FormControl;
  ptCtrl: FormControl;
  filteredLLTs: any;
  filteredPTs: any;
  lltId: number;
  ptId: number;
  meddraPTValue: string;
  reportTitle: string;
  maxDate: Date;
  lang: string;
  files: File[] = [];
  llts: any = [];
  pts: any = [];
  ptValue: any = [];
  treatmentIds: number[] = [];
  treatmentDetails: any = [];

  constructor(
    public constants: CONSTANTS,
    private common: COMMON,
    private loaderServ: NgxUiLoaderService,
    private medicalHistoryServ: MedicalHistoryService,
    private treatmentServ: TreatmentService,
    private meddraServ: MeddraService,
    private sessionServ: SessionService,
    private snackBar: MatSnackBar,
    public router: Router,
  ) { }

  ngOnInit() {
    this.lang = this.sessionServ.getLang();
    this.maxDate = new Date();

    this.lltCtrl = new FormControl();
    this.ptCtrl = new FormControl();
    this.medicalHistory = new MedicalHistory();

    this.treatmentServ.records()
      .then(res => {
        this.treatmentDetails = res.response;
      });

    this.setLLTCtrl();
    this.setPTCtrl();
  }

  dateChanged() {
    if (this.medicalHistory.start_date > this.medicalHistory.end_date) {
      this.snackBar.open(this.constants.MESSAGES.INVALID_DATE, '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });

      this.medicalHistory.end_date = null;
    }
  }

  setLLTCtrl() {
    this.lltCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
      )
      .subscribe(llt => {
        this.filterLLTs(llt);
      });
  }

  setPTCtrl() {
    if (this.pts)
      this.filteredPTs = this.ptCtrl.valueChanges
        .pipe(
          startWith(''),
          map(pt => pt ? this.filterPTs(pt) : this.pts.slice())
        );
  }

  displayLLT(data: any): string | undefined {
    return data ? data.name : undefined;
  }

  displayPT(data: any): string | undefined {
    return data ? data.name : undefined;
  }

  filterLLTs(data: any) {
    this.setPTCtrl();

    let value = data;

    if (data.name) {
      value = data.name;
      this.lltId = data.id;
      this.medicalHistory.other_llt = value;
      this.medicalHistory.other_pt = data.meddra_pt.name;
      this.ptCtrl = new FormControl(data.meddra_pt);
      this.filteredPTs = null;
    } else {
      this.medicalHistory.other_llt = data;
      this.lltId = null;
    }

    this.meddraServ.getMeddraData(this.constants.MEDDRA_TYPES.LLT, data)
      .then(res => {
        if (res.response.data !== undefined) {
          this.llts = res.response.data;
        } else {
          this.llts = res.response;
        }
        if (this.llts.length > 0) {
          // if (value === undefined) return this.llts;
          return this.filteredLLTs = this.llts.filter(llt => {
            if (llt.english !== null) {
              try {
                return llt.english.toString().toLowerCase().includes(value.toLowerCase()) === true
              }
              catch (err) {
                // debugger;
              }

            }
          }
          );
        }
      })


  }

  filterPTs(data: any) {
    let value = data;
    if (data.name) {
      value = data.name;
      this.ptId = data.id;
      this.medicalHistory.other_pt = value;
    } else
      this.medicalHistory.other_pt = data;

    this.meddraServ.getMeddraData(this.constants.MEDDRA_TYPES.PT, data)
      .then(res => {
        this.pts = res.response.data;
      });

    if (this.pts)
      return this.pts.filter(pt =>
        pt.name.toLowerCase().includes(value.toLowerCase()) === true
      );
  }

  handleReportFileInput(files: FileList) {
    let flag = true;
    for (let i = 0; i < files.length; i++) {
      if (this.constants.FILE_FORMATS.DOC.indexOf(files[i].type.toLowerCase()) < 0 && this.constants.FILE_FORMATS.IMAGE.indexOf(files[i].type.toLowerCase()) < 0) {
        flag = false;
        break;
      }
    }

    if (!flag)
      this.showMessage({ 'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT });
    else {
      this.reportTitle = '';
      for (let i = 0; i < files.length; i++) {
        this.files[i] = files.item(i);
        this.reportTitle += files[i].name + ', ';
      }

      this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
    }
  }

  save(form: NgForm) {
    if (form.valid && (this.lltId || (this.medicalHistory.other_llt && this.medicalHistory.other_pt))) {
      this.loaderServ.start();

      // start date and end date validation start
      
      if(this.medicalHistory.end_date != null && this.medicalHistory.start_date == null) {
          this.loaderServ.stop();     
          this.snackBar.open(this.constants.START_DATE_END_DATE_ERROR[this.lang].STARTDATEREQUIREDBEFOREENDDATE, '', {
              duration: 3000,
              panelClass: ['red-snackbar']
            }); 
            return false; 
      }
    // start date and end date validation end

      if (this.medicalHistory.start_date)
        this.medicalHistory.start_date = this.common.formatDate(new Date(this.medicalHistory.start_date));
      else
        this.medicalHistory.start_date = null;
      if (this.medicalHistory.end_date)
        this.medicalHistory.end_date = this.common.formatDate(new Date(this.medicalHistory.end_date));
      else
        this.medicalHistory.end_date = null;
      this.medicalHistory.title = this.ptValue.name;
      this.medicalHistory.meddra_llt_id = this.lltId;
      this.medicalHistory.title = this.medicalHistory.other_pt;

      this.medicalHistoryServ.add(this.medicalHistory, this.treatmentIds, this.files)
        .then(response => {
          this.lltId = null;
          this.medicalHistory.other_llt = null;
          this.medicalHistory.other_pt = null;

          this.lltCtrl = new FormControl();

          this.llts = [];
          this.setLLTCtrl();

          this.loaderServ.stop();
          this.showMessage(response);
          this.router.navigateByUrl('/user/medical-history');
          form.reset();
        });
    }
  }

  getPTRecords() {
    try {
      let PTValueRecord = this.llts.filter(item => item.id == this.medicalHistory.meddra_llt_id);
      let nameOfPt = PTValueRecord[0];
      this.ptValue = nameOfPt.meddra_pt;
    } catch (error) {

    }
  }

  showMessage(res: any) {
    if (this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
      this.snackBar.open(res.message, '', {
        duration: 3000,
        panelClass: ['green-snackbar']
      });
    else
      this.snackBar.open(res.message, '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
  }
}

import { Component, OnInit, Input } from '@angular/core';

import { CONSTANTS } from 'src/app/util/constants';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-dental-record-detail',
    templateUrl: './dental-record-detail.component.html',
    styleUrls: ['./dental-record-detail.component.css']
})
export class DentalRecordDetailComponent implements OnInit {

    lang: string = 'en';
    
    @Input() selectedRecord: any;

    constructor(
        public constants: CONSTANTS,
        private sessionServ: SessionService
    ) { }

    ngOnInit() { 
        this.lang = this.sessionServ.getLang();
    }
}

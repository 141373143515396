import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../../services/session.service';
import { MemberProfile } from '../../../models/member-profile';
import { UserService } from '../../../services/user.service';
import { DataService } from '../../../services/data.service';
import { ProfileDataService } from '../../..//services/profile-data.service';

@Component({
    selector: 'app-edit-member-profile',
    templateUrl: './edit-member-profile.component.html',
    styleUrls: ['./edit-member-profile.component.css']
})
export class EditMemberProfileComponent implements OnInit {

    id: number;
    memberProfile: MemberProfile;
    user: any;
    message: string;
    monthOfBirth: any;
    dayOfBirth: any;
    yearOfBirth: any;
    // isMaleClicked: boolean = true;
    isMaleClicked: boolean = false;
    isFemaleClicked: boolean = false;
    profileImage: string = "url('/assets/images/user-img.png')";
    file: File;
    backgroundStyle: any;
    borderRadius: string = '0';
    backgroundSize: string = 'contain';
    years: number[] = [];
    lang: string = 'en';

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public dataServ: DataService,
        public loaderServ: NgxUiLoaderService,
        public router: Router,
        private snackBar: MatSnackBar,
        private profileDataServ: ProfileDataService
    ) { }

    ngOnInit() {    
        this.loaderServ.start();
        this.lang = this.sessionServ.getLang();    
        // for(let i=new Date().getFullYear(); i>=1901; i--)
        for(let i=new Date().getFullYear(); i>=1600; i--)
            this.years.push(i);

        this.memberProfile = new MemberProfile();

        this.dataServ.id.subscribe(id => { 
            this.id = id; 

            if(!this.id)
                this.router.navigateByUrl('/user/my-family');
            else {
                this.user = this.sessionServ.getUser();                
                this.getProfile();
                this.loaderServ.stop();
            }
        });
        this.lang = this.sessionServ.getLang();
    }

    getProfile() {
        this.loaderServ.start();
        this.userServ.getUserProfile(this.id)
            .then(res => {
                this.memberProfile = new MemberProfile(res.response);
                
                if(this.memberProfile.date_of_birth) {
                    let date = new Date(this.memberProfile.date_of_birth);
                    this.monthOfBirth = date.getMonth() + 1;
                    this.dayOfBirth = date.getDate();
                    this.yearOfBirth = date.getFullYear();
                }

                this.isMaleClicked = false;
                // this.isFemaleClicked = true;
                this.isFemaleClicked = false;
                if(this.memberProfile.gender) {
                    if(this.constants.GENDERS.MALE.toLowerCase() == this.memberProfile.gender.toLowerCase()) {
                        this.isMaleClicked = true;
                        this.isFemaleClicked = false;
                    }  else if(this.constants.GENDERS.FEMALE.toLowerCase() == this.memberProfile.gender.toLowerCase()) {
                        this.isMaleClicked = false;
                        this.isFemaleClicked = true;
                    }
                    else {
                        this.isMaleClicked = false;
                        this.isFemaleClicked = true;
                    }
                }
                
                if(this.memberProfile.image) {
                    this.profileImage = 'url(' + this.constants.SERVER.BASE + '/' + this.memberProfile.image + ')';
                    this.borderRadius = '50%';
                    this.backgroundSize = 'cover';
                }
                this.setBackgroundStyle();
                this.loaderServ.stop();
            });
    }
    
    checkOrganDonor(isOrganDonnor: number) {
        this.memberProfile.organ_donor = isOrganDonnor;
    }

    genderSelected(gender: string) {
        this.memberProfile.gender = gender;
        if(this.constants.GENDERS.MALE == gender) {
            this.isMaleClicked = true;
            this.isFemaleClicked = false;
        } else if(this.constants.GENDERS.FEMALE == gender) {
            this.isMaleClicked = false;
            this.isFemaleClicked = true;
        } else {
            this.isMaleClicked = false;
            this.isFemaleClicked = false;
        }
    }

    checkSmoker(isSmoker: number) {
        this.memberProfile.smoker = isSmoker;
    }

    handleFileInput(files: FileList) {        
        this.file = files.item(0);        
        this.loaderServ.start();

        this.userServ.uploadImage({file: this.file, id: this.memberProfile.id})
            .then(res => {
                if(this.constants.RESPONSE_CODES.HTTP_STATUS_BAD_REQUEST == res.status) {
                    this.loaderServ.stop();
                    this.snackBar.open(res.message, '', {
                        duration: 3000,
                        panelClass: ['red-snackbar']
                    });
                } else {
                    this.message = res.message;         
                    this.memberProfile.image = res.response.image;

                    this.profileDataServ.changeMember(this.memberProfile);

                    if(this.memberProfile.image) {
                        this.profileImage = 'url(' + this.constants.SERVER.BASE + '/' + this.memberProfile.image + ')';
                        this.borderRadius = '50%';
                        this.backgroundSize = 'cover';
                        
                        this.setBackgroundStyle();
                        this.showMessage(res);
                        this.loaderServ.stop();
                    }
                }
                this.userServ.saveFamilyMemberBS.next(Math.random());
            });
    }

    saveProfile(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            this.memberProfile.date_of_birth = null;

            // if(this.memberProfile.date_of_birth)
            if(this.yearOfBirth && this.monthOfBirth && this.dayOfBirth)
                this.memberProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;
            this.memberProfile.user_id = null;
            this.memberProfile.parent_id = this.user.user_id;
            this.memberProfile.image = null;

            // blood group amd blodd type validation start
            if(this.memberProfile.blood_group != null && this.memberProfile.rh_factor == null) {
                this.loaderServ.stop();
              
                this.snackBar.open(this.constants.BLOOD_TYPE_ERROR[this.lang].BLOODTYPE, '', {
                  duration: 3000,
                  panelClass: ['red-snackbar']
                }); 
                return false;
            }
            // blood group and blood type validation end
                        
            this.userServ.updateProfile(this.memberProfile, this.memberProfile.id)
                .then(res => {                    
                    this.loaderServ.stop();
                    this.profileDataServ.changeMember(this.memberProfile);
                    this.router.navigateByUrl('user/member-profile');
                });
        }
    }

    setBackgroundStyle() {
        this.backgroundStyle = {'overflow': 'hidden', 'position': 'absolute', 'right': '0px', 'top': '5px', 'background-repeat': 'no-repeat', 'background-size': this.backgroundSize, 'background-position': 'center', 'background-image': this.profileImage, 'border-radius': this.borderRadius}
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';

declare var $: any;

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css', '../../../assets/styles/responsive.css']
})
export class AdminComponent implements OnInit {

    current_segment:string;
    isActive: any = [];

    constructor(
        private router: Router,
        public constant: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService
    ) { }

    ngOnInit() {
        this.menuScript(); 

        if(this.router.parseUrl(this.router.url).root.children['primary'].segments[1]) {
            this.current_segment = this.router.parseUrl(this.router.url).root.children['primary'].segments[1].path;
        }
        
        if(this.current_segment == ''){
            this.isActive['admin-dashboard'] = true;
        } else {
            this.isActive['admin-dashboard'] = false;
        } 
        if(this.current_segment == 'users'){
            this.isActive['users'] = true;
        } else {
            this.isActive['users'] = false;
        }
        if(this.current_segment == 'meddra'){
            this.isActive['meddra'] = true;
        } else {
            this.isActive['meddra'] = false;
        }
        if(this.current_segment == 'cms'){
            this.isActive['cms'] = true;
        } else {
            this.isActive['cms'] = false;
        }
        if(this.current_segment == 'userreports' || this.current_segment == 'medicalreports' || this.current_segment == 'dentalreports' || this.current_segment == 'treatmentreports'){
            this.isActive['reports'] = true;
        } else {
            this.isActive['reports'] = false;
        }
        if(this.current_segment == 'userreports'){
            this.isActive['userreports'] = true;
        } else {
            this.isActive['userreports'] = false;
        }
        if(this.current_segment == 'medicalreports'){
            this.isActive['medicalreports'] = true;
        } else {
            this.isActive['medicalreports'] = false;
        }
        if(this.current_segment == 'dentalreports'){
            this.isActive['dentalreports'] = true;
        } else {
            this.isActive['dentalreports'] = false;
        }
        if(this.current_segment == 'treatmentreports'){
            this.isActive['treatmentreports'] = true;
        } else {
            this.isActive['treatmentreports'] = false;
        }
             
    }

    logout() {
        this.loginServ.logout(this.sessionServ.getUser())
            .then(res => {
                this.sessionServ.removeUser();
                this.router.navigateByUrl('/');
            });
    }

    menuScript() {
        $(document).ready(() => {
            /* -------left-nav-bar-mobile-view------ */
            $('#dismiss, .overlay').on('click', function () {
                $('#sidebar').removeClass('active');
                $('.overlay').removeClass('active');
            });

            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').addClass('active');
                $('.overlay').addClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });

            /* -------left-nav-bar-mobile-view------ */
            /* -------my-account-menu------ */
            $('#show-dropdown').click(function () {
                $('.dropdown-setting').slideToggle();
                $('.dropdown-overlay').show();
                $('.add-asset-dashboard').removeClass('add-asset-dashboard-show');

            });

            /* -------my-account-menu------ */
            /* -------dashboard-popup------ */
            $('#show-dashboard-popup').click(function () {
                $('.add-asset-dashboard').toggleClass("add-asset-dashboard-show");
                $('.rotate-cross').toggleClass("rotate-cross-rotate");
                $('.dropdown-overlay').show();
                $('.dropdown-setting').slideUp();
            });
            $('.dropdown-overlay').click(function () {
                $(this).hide();
                $('.dropdown-setting').slideUp();
                $('.add-asset-dashboard').removeClass('add-asset-dashboard-show');
                $('.rotate-cross').removeClass("rotate-cross-rotate");
            });

            /* -------dashboard-popup------ */
            // Dropdown toggle
            $('.dropdown-toggle').click(function () {
                $(this).next('.dropdown-menu').toggle();
            });

            $(document).click(function (e) {
                var target = e.target;
                if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle'))
                    $('.dropdown-menu').hide();
            });
            // Dropdown toggle

            /** Help and support hide */
            
            $('.helpandsupporthide').click(function () {
                $('.dropdown-overlay').hide();
                $('.dropdown-setting').slideUp();
            });
        });
    }

    changeActive(menuItem: string) {
        for (var key in this.isActive) {
            this.isActive[key] = false;
            console.log('menuitem=>'+menuItem+'key=>'+key);
            if(menuItem == key) {
                this.isActive[key] = true;
            }
        }
    }

}

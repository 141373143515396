export class DentalRecord {
    id: number;    
    person_id: number;
    title: string;
    start_date: string;
    end_date: string;
    other_interventions: string = null;
    notes: string;
    cleaning: JSON;
    fluoride: JSON;
    filling: JSON;
    gum_treatment: JSON;
    rct: JSON;
    extraction: JSON;
    surgical_process: JSON;
    other: string;
    teeth_status: any;
    created_by: number;
    updated_by: number;
    // notelist: any = [];
    treatment_ids: number[] = [];
    
    constructor(dentalRecord: DentalRecord = null) {
        if(dentalRecord) {
            this.id = dentalRecord.id;
            this.person_id = dentalRecord.person_id;
            this.title = dentalRecord.title;
            this.start_date = dentalRecord.start_date;
            this.end_date = dentalRecord.end_date;
            this.other_interventions = dentalRecord.other_interventions;
            this.treatment_ids = dentalRecord.treatment_ids;
            this.teeth_status = dentalRecord.teeth_status;
            this.notes = dentalRecord.notes;
            this.cleaning = dentalRecord.cleaning;           
            this.fluoride = dentalRecord.fluoride;           
            this.filling = dentalRecord.filling;           
            this.gum_treatment = dentalRecord.gum_treatment;           
            this.rct = dentalRecord.rct;           
            this.extraction = dentalRecord.extraction;           
            this.surgical_process = dentalRecord.surgical_process;           
            this.other = dentalRecord.other;
            this.created_by = dentalRecord.created_by;
            this.updated_by = dentalRecord.updated_by;           
            // this.notelist = dentalRecord.notelist;           
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../../services/session.service';
import { UserProfile } from '../../../models/user-profiles';
import { UserService } from '../../../services/user.service';
import { ProfileDataService } from '../../../services/profile-data.service';

@Component({
    selector: 'app-edit-my-profile',
    templateUrl: './edit-my-profile.component.html',
    styleUrls: ['./edit-my-profile.component.css']
})
export class EditMyProfileComponent implements OnInit {

    userProfile: UserProfile;
    monthOfBirth: number;
    dayOfBirth: number;
    yearOfBirth: number;
    // isMaleClicked: boolean = true;
    isMaleClicked: boolean;
    isFemaleClicked: boolean = false;
    profileImage: string = 'url(/assets/images/user-img.png)';
    file: File;
    backgroundStyle: any;
    borderRadius: string = '0';
    backgroundSize: string = 'contain';
    years: number[] = [];
    lang: string = 'en';

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public loaderServ: NgxUiLoaderService,
        public profileDataServ: ProfileDataService,
        private snackBar: MatSnackBar,
        private router: Router
    ) { }

    ngOnInit() {        
        // for(let i=new Date().getFullYear(); i>=1901; i--)
        for(let i=new Date().getFullYear(); i>=1600; i--)
            this.years.push(i);
            
        this.userProfile = new UserProfile(this.sessionServ.getLoggedInUser());
      
        let date = null;
        if(this.userProfile.date_of_birth) {
            date = new Date(this.userProfile.date_of_birth);
            this.monthOfBirth = date.getMonth() + 1;
            this.dayOfBirth = date.getDate();
            this.yearOfBirth = date.getFullYear();
        }
        if(this.userProfile.gender) {
            if(this.userProfile.gender != null && this.constants.GENDERS.MALE.toLowerCase() == this.userProfile.gender.toLowerCase()) {
                this.isMaleClicked = true;
                this.isFemaleClicked = false;
            }else if(this.userProfile.gender != null && this.constants.GENDERS.FEMALE.toLowerCase() == this.userProfile.gender.toLowerCase()) {
                this.isMaleClicked = false;
                this.isFemaleClicked = true;
            } else {
                this.isMaleClicked = false;
                this.isFemaleClicked = false;
            }
        }
        if(this.userProfile.image) {
            this.profileImage = 'url(' + this.constants.SERVER.BASE + '/' + this.userProfile.image + ')';
            this.borderRadius = '50%';
            this.backgroundSize = 'cover';
        }
        this.lang = this.sessionServ.getLang();
        this.setBackgroundStyle();        
    }

    // checkOrganDonor(isOrganDonnor: boolean) {
    //     this.userProfile.organ_donor = isOrganDonnor;
    // }

    checkOrganDonor(isOrganDonnor:number) {
        this.userProfile.organ_donor = isOrganDonnor;
    }

    genderSelected(gender: string) {
        this.userProfile.gender = gender;
        if(this.constants.GENDERS.MALE == gender) {
            this.isMaleClicked = true;
            this.isFemaleClicked = false;
        } else if(this.constants.GENDERS.FEMALE == gender) {
            this.isMaleClicked = false;
            this.isFemaleClicked = true;
        }
         else {
            this.isMaleClicked = false;
            // this.isFemaleClicked = true;
            this.isFemaleClicked = false;
        }
    }

    // checkSmoker(isSmoker:boolean) {
    //     this.userProfile.smoker = isSmoker;
    // }

    checkSmoker(isSmoker:number) {
        this.userProfile.smoker = isSmoker;
        console.log('smoker=>'+this.userProfile.smoker);
    }

    handleFileInput(files: FileList) {        
        this.file = files.item(0);        
        this.loaderServ.start();

        this.userServ.uploadImage({file: this.file, id: this.userProfile.id})
            .then(res => {
                if(this.constants.RESPONSE_CODES.HTTP_STATUS_BAD_REQUEST == res.status) {
                    let message = res.message;         
                    this.loaderServ.stop();
                    this.snackBar.open(message, '', {
                        duration: 3000,
                        panelClass: ['red-snackbar']
                    });
                } else {
                    this.userProfile.image = res.response.image;
                    if(this.userProfile.image) {
                    
                        let user = this.sessionServ.getLoggedInUser();

                        user.image = this.userProfile.image;                     
                        this.sessionServ.setLoggedInUser(user);          
                        this.profileDataServ.changeLoggedInUserImage(this.userProfile.image);         

                        this.profileImage = 'url(' + this.constants.SERVER.BASE + '/' + this.userProfile.image + ')';
                        this.borderRadius = '50%';
                        this.backgroundSize = 'cover';
                        
                        this.setBackgroundStyle();
                        this.loaderServ.stop();
                    }
                    this.loaderServ.stop();
                    this.showMessage(res);
                }
                this.userServ.saveFamilyMemberBS.next(Math.random());
            });
    }

    saveProfile(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            
            this.userProfile.date_of_birth = null;

            // date of birth validation
            // if(this.yearOfBirth == null || this.monthOfBirth == null || this.dayOfBirth == null){
            //     this.loaderServ.stop();
              
            //     this.snackBar.open(this.constants.DATE_OF_BIRTH_ERROR[this.lang].DATEOFBIRTH, '', {
            //       duration: 3000,
            //       panelClass: ['red-snackbar']
            //     }); 
            //     return false;
            // }
            if(this.yearOfBirth && this.monthOfBirth && this.dayOfBirth)
                this.userProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;

            // blood group amd blodd type validation start
            if(this.userProfile.blood_group != null && this.userProfile.rh_factor == null) {
                this.loaderServ.stop();
              
                this.snackBar.open(this.constants.BLOOD_TYPE_ERROR[this.lang].BLOODTYPE, '', {
                  duration: 3000,
                  panelClass: ['red-snackbar']
                }); 
                return false;
            }
            // blood group and blood type validation end

            if(this.yearOfBirth)
                this.userProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;

            this.userProfile.image = null;
            this.userServ.updateProfile(this.userProfile, this.userProfile.id)
                .then(res => {
                    let user = this.sessionServ.getLoggedInUser();

                    this.profileDataServ.changeLoggedInUserName(this.userProfile.name);

                    this.userServ.getLoggedInUserProfile(user.token)
                        .then(res => {
                            let loggedUser = res.response;                                
                            loggedUser.token = user.token;
                            loggedUser.email = user.email;
                            loggedUser.type = user.type;
                            loggedUser.image = user.image;
                            
                            this.sessionServ.setLoggedInUser(loggedUser);
                            this.sessionServ.setUser(loggedUser);

                            this.loaderServ.stop();
                            this.router.navigateByUrl('/user/my-profile');
                        });                    
                });
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    setBackgroundStyle() {
        this.backgroundStyle = {'overflow': 'hidden', 'position': 'absolute', 'right': '0px', 'top': '5px', 'background-repeat': 'no-repeat', 'background-size': this.backgroundSize, 'background-position': 'center', 'background-image': this.profileImage, 'border-radius': this.borderRadius}
    }
}

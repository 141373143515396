import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service'

@Injectable({
  providedIn: 'root'
})
export class MedicineService {

  constructor(
		private constants: CONSTANTS,
		private httpServ: HttpService,
		private sessionServ: SessionService
	) {
		this.constants = constants,
		this.httpServ = httpServ,
		this.sessionServ = sessionServ
  };

  /**
     * Prepares request object for add medical history and send it to http service
     * @param data 
     */
    add(data: any, file: any) {
      let user = this.sessionServ.getUser();
  
      data.created_by = user.user_id;
      data.updated_by = user.user_id;
      
      const formData: FormData = new FormData();
  
      for (let key in data) {
        if(null == data[key])
          data[key] = '';
        
              formData.append(key, data[key]);
          }
                  
          formData.append('file', file);		
          formData.append(this.constants.OTHERS.TOKEN, user.token);
      
      let requestObject = {
        REQUEST_DATA: formData,
        API_URL: this.constants.API_URLS.MEDICINES,
        REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
      };
  
      return this.httpServ.sendRequest(requestObject);
    }
  
}

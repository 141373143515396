import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CONSTANTS } from '../../../util/constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../../services/session.service';
import { DataService } from '../../../services/data.service';
import { MemberProfile } from '../../../models/member-profile';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.css']
})
export class MemberProfileComponent  implements OnInit {

    id: number;
    memberProfile: MemberProfile;
    message: string;
    age: number;
    user: any;
    monthOfBirth: number;
    dayOfBirth: number;
    yearOfBirth: number;
    isMaleClicked: boolean = true;
    isFemaleClicked: boolean = false;
    profileImage: string = '/assets/images/user-img.png';
    file: File;
    backgroundStyle: any;
    borderRadius: string = '0';
    backgroundSize: string = 'contain';
    latestCategories: any;
    lang: string;

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public dataServ: DataService,
        public userServ: UserService,
        public router: Router,
        public loaderServ: NgxUiLoaderService,
    ) { }

    ngOnInit() {    
        this.lang = this.sessionServ.getLang();  
        this.memberProfile = new MemberProfile();
        this.loaderServ.start();
        this.dataServ.id.subscribe(id => { 
            this.id = id; 
            if(!this.id)
                this.router.navigateByUrl('/user/my-family');
            else {
                this.user = this.sessionServ.getUser();                
                this.getProfile();
                this.loaderServ.stop();
            }
        });
    } 

    getProfile() {
        this.userServ.getUserProfile(this.id)
            .then(res => {
                this.memberProfile = new MemberProfile(res.response);
                let date = new Date(this.memberProfile.date_of_birth);
                this.monthOfBirth = date.getMonth() + 1;
                this.dayOfBirth = date.getDate();
                this.yearOfBirth = date.getFullYear();

                this.isMaleClicked = false;
                this.isFemaleClicked = true;
                if(this.memberProfile.gender) {
                    if(this.constants.GENDERS.MALE.toLowerCase() == this.memberProfile.gender.toLowerCase()) {
                        this.isMaleClicked = true;
                        this.isFemaleClicked = false;
                    } else {
                        this.isMaleClicked = false;
                        this.isFemaleClicked = true;
                    }
                }
                this.calculateAge();
                
                if(this.memberProfile.image) {
                    this.profileImage = this.constants.SERVER.BASE + '/' + this.memberProfile.image;
                    this.borderRadius = '50%';
                    this.backgroundSize = 'cover';
                }

                this.userServ.getDashboardValues(this.constants.STATUS.ACTIVE, this.memberProfile.id)
                    .then(res => {
                        this.latestCategories = res.response.latest_record;
                    });
            });
    }

    calculateAge() {
         let date_of_birth = Date.parse(this.memberProfile.date_of_birth);
         if(this.memberProfile.date_of_birth) {
            var timeDiff = Math.abs(Date.now() - date_of_birth);
            this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        }
    }
}

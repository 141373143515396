import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 

import { CONSTANTS } from 'src/app/util/constants';
import { MeddraService } from 'src/app/services/meddra.service';

@Component({
    selector: 'app-meddra',
    templateUrl: './meddra.component.html',
    styleUrls: ['./meddra.component.css']
})
export class MeddraComponent implements OnInit {

    languages: any;
    flag: boolean = false;
    file: any;
    languageId: number;
    type: string;

    constructor(
        private constants: CONSTANTS,
        private meddraServ: MeddraService,
        private snackBar: MatSnackBar,
        public loaderServ: NgxUiLoaderService,
    ) { }

    ngOnInit() {
        this.meddraServ.getLanguages()
            .then(res => {
                this.languages = res.response;
            });
    }

    handleReportFileInput(file: File) {  
        this.file = file[0];  
        let ext = ''; 
                
        ext = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);

        if('asc' != ext) {         
            this.showMessage({'status': 400, 'message': 'Invalid file format'});
            this.flag = false;
        } else
            this.flag = true;
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    upload(form: NgForm) {
        if(this.flag) {
            if(form.valid) {                        
                this.loaderServ.start();
                                
                this.meddraServ.add({type: this.type, language_id: this.languageId}, this.file)
                    .then(response => {                        
                        this.loaderServ.stop();               
                        
                        this.showMessage(response);

                        form.reset();
                    });                      
            }
        } else
            this.showMessage({'status': 400, 'message': 'Invalid file format'});
    }
}

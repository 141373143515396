import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { TreatmentService } from '../../../services/treatment.service';
import { Treatment } from '../../../models/treatment';
import { DataService } from '../../../services/data.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: 'app-show-treatment',
    templateUrl: './show-treatment.component.html',
    styleUrls: ['./show-treatment.component.css']
})
export class ShowTreatmentComponent implements OnInit {

    treatment: Treatment;
    user: any;
    id: number;
    treatmentAssets: any = [];
    assetLength: number;
    lang: string;
    
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public treatmentServ: TreatmentService,
        public dataServ: DataService,
        public router: Router,
        public sessionServ: SessionService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        this.treatment = new Treatment();
        this.loaderServ.start();
        this.dataServ.id.subscribe(id => {
            this.id = id; 
            if(!this.id)
                this.router.navigateByUrl('/user/treatment');
            else {
                this.treatmentServ.find(this.id)
                    .then(res => {
                        this.loaderServ.stop();
                        this.treatment = new Treatment(res.response[0]);
                        if(res.response[0] && res.response[0].medicine)
                            this.treatment.name = res.response[0].medicine.name;

                        this.treatmentAssets = res.response[0].assetDetails;
                    });
            }
        });        
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }
}

import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class DentalRecordService {
    constructor(
		private constants: CONSTANTS,
		private httpServ: HttpService,
		private sessionServ: SessionService
	) {
		this.constants = constants,
		this.httpServ = httpServ,
		this.sessionServ = sessionServ
	};

    /**
     * Prepares request object for add dental record and send it to http service
     * @param data 
     */
    add(data, treatmentIds, files) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
		data.updated_by = user.user_id;
		
		const formData: FormData = new FormData();

		for (let key in data) {
			if(null == data[key])
				data[key] = '';
			
            formData.append('data[' + key + ']', data[key]);
		}
        
        for(let i=0; i<treatmentIds.length; i++) {
            formData.append('data[treatment_ids][' + i + ']', treatmentIds[i]);
        }

		for (let i=0; i<files.length; i++) {
			formData.append('files[' + i + ']', files[i]);
		}

		formData.append('data[person_id]', user.id);
		formData.append('data[language_id]', user.language_id);
		formData.append(this.constants.OTHERS.TOKEN, user.token);
		
		let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.DENTAL_RECORDS,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
	}
	
	/**
     * Get dental record
     */
    find(id: number) {
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token  + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};
		
		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Get list of dental record details
     */
    records() {
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.PERSON_ID + user.id + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};
		
		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Get dental record details 
     */
    getUserDentalRecord(id: number) {
		let user = this.sessionServ.getUser();

		let requestObject = {
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token  + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Update dental record
     */
    update(data, treatmentIds, files) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
		data.updated_by = user.user_id;
		
		const formData: FormData = new FormData();

		for (let key in data) {
			if(null == data[key])
				data[key] = '';
			
			formData.append('data[' + key + ']', data[key]);
		}

		for (let i=0; i<files.length; i++) {
			formData.append('files[' + i + ']', files[i]);
        }
        
        for(let i=0; i<treatmentIds.length; i++) {
            formData.append('data[treatment_ids][' + i + ']', treatmentIds[i]);
        }

		// formData.append('data[person_id]', user.id);	
		formData.append('data[person_id]', data.person_id);
		formData.append('data[language_id]', user.language_id);	
		formData.append(this.constants.OTHERS.TOKEN, user.token);
		
		let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.UPDATE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
	}

	/**
	 * Delete dental record
	 */
	remove(id: number) {
		let user = this.sessionServ.getUser();

		let requestObject = {
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.USER_ID + user.user_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
		};

		return this.httpServ.sendRequest(requestObject);
	}

	/**
     * Delete asset
     */
    deleteAsset(id: number) {
		let user = this.sessionServ.getUser();
		let data = {
			'userId': user.user_id,
			'token': user.token,
			'report_id': id
		}

		let requestObject = {
			REQUEST_DATA: data,
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.DELETE_DENTAL_REPORT,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};
		
		return this.httpServ.sendRequest(requestObject);
	}
	
	/**
     * Get list of deleted dental records details
     */
    deletedRecords() {
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.REMOVED + this.constants.API_URLS.ROOT + user.user_id + this.constants.URL_VARIABLES.TOKEN + user.token  + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};
		
		return this.httpServ.sendRequest(requestObject);
	}
	
	/**
     * Restore deleted dental record
     */
    restore(id: number) {
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			API_URL: this.constants.API_URLS.DENTAL_RECORDS + this.constants.API_URLS.RESTORE + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.USER_ID + user.user_id + this.constants.URL_VARIABLES.ID + id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};
		
		return this.httpServ.sendRequest(requestObject);
    }

}

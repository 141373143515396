import { Component, OnInit,LOCALE_ID, Inject } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css', '../../../assets/styles/responsive.css']
})
export class ForgotPasswordComponent implements OnInit {

    email: string;
    message: string;
    hideError: boolean = true;
    hideSuccess: boolean = true;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
        public sessionServ: SessionService,
    ) { }

    ngOnInit() {
        let lang = '';
        if(this.locale == 'en-US'){
            lang = 'en';
        } else {
            lang = this.locale ;
        }
        console.log(lang);
        this.sessionServ.setLang(lang);
    }

    sendResetMail(form: NgForm) {
       if(form.valid) {
            this.loaderServ.start();
            this.loginServ.sendResetEmail(this.email)
                .then(response => {                   
                    this.loaderServ.stop();
                    this.showMessage(response);
                });
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

}

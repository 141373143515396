import { Component, OnInit } from '@angular/core';

import { CONSTANTS } from 'src/app/util/constants';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-legends',
    templateUrl: './legends.component.html',
    styleUrls: ['./legends.component.css']
})
export class LegendsComponent implements OnInit {

    legends: any = [
        {
            en: 'Present',
            zh: '前排',
            fr: 'présent',
            de: 'Gegenwart',
            es: 'presente',
            code: 'PS',
            color: '#c6ee9e'
        },
        {
            en: 'Missing',
            zh: '缺失',
            fr: 'manquant ',
            de: 'fehlt',
            es: 'Faltante',
            code: 'MS',
            color: '#ee9e9e'
        },
        {
            en: 'Pontic',
            zh: '桥体',
            fr: 'pontique',
            de: 'Pontic',
            es: 'Póntico',
            code: 'PT',
            color: '#80defe'
        },
        {
            en: 'Abutment',
            zh: '支台体',
            fr: 'pilier',
            de: 'Abutment',
            es: 'Pilar',
            code: 'AB',
            color: '#d5d78c'
        },
        {
            en: 'Implant',
            zh: '植体',
            fr: 'implant',
            de: 'Implantat',
            es: 'Implante',
            code: 'IM',
            color: '#e7aee8'
        },
        {
            en: 'Caries/Decay',
            zh: '龋齿/蛀牙',
            fr: 'carie/carie',
            de: 'Karies/Verfall',
            es: 'Caries/Decaimiento',
            code: 'CD',
            color: '#d7ad8c'
        },
        {
            en: 'Periodontal Gum',
            zh: '牙周牙龈',
            fr: 'gencive parodontale',
            de: 'Parodontalgummi',
            es: 'Foma periodontal',
            code: 'PG',
            color: '#909de0'
        },
        {
            en: 'Restoration/Filling',
            zh: '修复/补牙',
            fr: 'restauration/remplissage',
            de: 'Restaurierung / Füllung',
            es: 'Restauración/Empaste',
            code: 'RF',
            color: '#ffdb73'
        },
        {
            en: 'Non-Vital',
            zh: '坏死',
            fr: 'non vital',
            de: 'nicht lebenswichtig',
            es: 'no vital',
            code: 'NV',
            color: '#ffbb7f'
        },
        {
            en: 'Abscess',
            zh: '脓肿',
            fr: 'abcès',
            de: 'Abszess',
            es: 'Absceso',
            code: 'AS',
            color: '#72edf0'
        },
        {
            en: 'Infection',
            zh: '前排',
            fr: 'infection',
            de: 'Infektion',
            es: 'Infección',
            code: 'IN',
            color: '#71a384'
        },
        {
            en: 'Dental Trauma',
            zh: '牙外伤',
            fr: 'traumatisme dentaire',
            de: 'Zahntrauma',
            es: 'Trauma dental',
            code: 'DT',
            color: '#f06060'
        },
        {
            en: 'Other',
            zh: '其他',
            fr: 'autre',
            de: 'Andere',
            es: 'Otro',
            code: 'OT',
            color: '#c7784c'
        }
    ];

    lang: string;

    constructor(
        public constants: CONSTANTS,
        private sessionServ: SessionService
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
    }

}

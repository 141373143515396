import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { MedicalHistoryService } from '../../../services/medical-history.service';
import { DataService } from '../../../services/data.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: 'app-show-medical-history',
    templateUrl: './show-medical-history.component.html',
    styleUrls: ['./show-medical-history.component.css']
})
export class ShowMedicalHistoryComponent implements OnInit {

	medicalHistory: any;
    user: any;
	id: number;
	
    constructor(
		public constants: CONSTANTS,
        public common: COMMON,
        public medicalHistoryServ: MedicalHistoryService,
        public dataServ: DataService,
        public router: Router,
        public sessionServ: SessionService,
        public loaderServ: NgxUiLoaderService,
	) { }

    ngOnInit() {
        this.loaderServ.start();
        this.dataServ.id.subscribe(id => {
            this.id = id; 
            if(!this.id)
                this.router.navigateByUrl('/user/medical-history');
            else {
                this.medicalHistoryServ.find(this.id)
                    .then(res => {
                        this.loaderServ.stop();
                        this.medicalHistory = res.response[0];
					});
			}
		});        
	}
	
	edit(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/edit-medical-history');
    }
}

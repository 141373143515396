import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';

@Injectable({
  	providedIn: 'root'
})
export class MedicalHistoryService {

	constructor(
		private constants: CONSTANTS,
		private httpServ: HttpService,
		private sessionServ: SessionService
	) {
		this.constants = constants,
		this.httpServ = httpServ,
		this.sessionServ = sessionServ
	};

    /**
     * Prepares request object for add medical history and send it to http service
     * @param data 
     */
    add(data, treatmentIds, files) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
		data.updated_by = user.user_id;
		
		const formData: FormData = new FormData();

		for (let key in data) {
			if(null == data[key])
				data[key] = '';
			
            formData.append('data[' + key + ']', data[key]);
        }
        
        for(let i=0; i<treatmentIds.length; i++) {
            formData.append('data[treatment_ids][' + i + ']', treatmentIds[i]);
        }

		for (let i=0; i<files.length; i++) {
			formData.append('files[' + i + ']', files[i]);
		}

		formData.append('data[person_id]', user.id);
		if(!formData.has('data[language_id]')){
			formData.append('data[language_id]', user.language_id);
		}
		
		formData.append(this.constants.OTHERS.TOKEN, user.token);
		
		let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Get list of medical history details
     */
    records() {
		let user = this.sessionServ.getUser();
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.PERSON_ID + user.id + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};
		
		return this.httpServ.sendRequest(requestObject);
	}
	
	/**
     * Get list of deleted medical history details
     */
    deletedRecords() {
		let user = this.sessionServ.getUser();
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.REMOVED + this.constants.API_URLS.ROOT + user.user_id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id  + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};
		
		return this.httpServ.sendRequest(requestObject);
	}
	
	/**
     * Restore deleted medical history
     */
    restore(id: number) {
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.RESTORE + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.USER_ID + user.user_id + this.constants.URL_VARIABLES.ID + id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};
		
		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Get medical history details 
     */
    getUserMedicalHistory(id: number) {
		let user = this.sessionServ.getUser();
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		

		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id  + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Update medical History
     */
    update(data, treatmentIds, files) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
		data.updated_by = user.user_id;
		
		const formData: FormData = new FormData();
		
		for (let key in data) {
			if(null == data[key])
				data[key] = '';
			
			formData.append('data[' + key + ']', data[key]);
		}

		for (let i=0; i<files.length; i++) {
			formData.append('files[' + i + ']', files[i]);
        }
        
        for(let i=0; i<treatmentIds.length; i++) {
            formData.append('data[treatment_ids][' + i + ']', treatmentIds[i]);
        }

		formData.append('data[person_id]', data.person_id);	
		formData.append('data[language_id]', user.language_id);	
		formData.append(this.constants.OTHERS.TOKEN, user.token);
		
		let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.UPDATE_MEDICAL_HISTORY,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
	}

	/**
	 * Delete Medical History
	 */
	remove(id: number) {
		let user = this.sessionServ.getUser();

		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.USER_ID + user.user_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
		};

		return this.httpServ.sendRequest(requestObject);
	}

	/**
	 * Get Meddra Data list
	 */

	getMeddraData() {
        let user = this.sessionServ.getUser();
        let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];

		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY_MEDDRA_LIST + this.constants.URL_VARIABLES.TYPE + this.constants.MEDDRA_TYPES.LLT + this.constants.URL_VARIABLES.LLT_ID + '' + this.constants.URL_VARIABLES.NAME + '' + this.constants.URL_VARIABLES.CREATED_BY + user.user_id + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
    }
    
	/**
     * Delete asset
     */
    deleteAsset(id: number) {
		let user = this.sessionServ.getUser();
		let data = {
			'userId': user.user_id,
			'token': user.token,
			'report_id': id
		}

		let requestObject = {
			REQUEST_DATA: data,
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.DELETE_MEDICAL_REPORT,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};
		
		return this.httpServ.sendRequest(requestObject);
	}
	
	/**
	 * Generate Pdf
	 */
	// medical-history/generate-pdf/{{user.id}}?token={{user.token}}&language_id={{user.language_id}}">Download</a> -->
	generatePdf() {
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
		// {{url}}/api/user-profiles/generate-pdf/35?language_id=1&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODAwMFwvYXBpXC9sb2dpbiIsImlhdCI6MTU4Nzk2OTUzNCwibmJmIjoxNTg3OTY5NTM0LCJqdGkiOiJGajk2eHBEWFNCeEZjSkxlIiwic3ViIjoyNywicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9._VWLB0Q2DcfBt-5wfJb4dYcULcAtEm94B4Li2euYpUU
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			// API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.GENERATE_PDF+'/'+user.id+this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.PERSON_ID + user.id + this.constants.URL_VARIABLES.LANGUAGE_ID + user.language_id,
			API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.GENERATE_PDF+'/'+user.id+this.constants.URL_VARIABLES.TOKEN + user.token+ this.constants.URL_VARIABLES.LANGUAGE_ID + language_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};
		
		return this.httpServ.sendRequest(requestObject);
	}

	/**
	 * Find Medical History
	 */
	find(id: number) {
		let user = this.sessionServ.getUser();
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id + this.constants.URL_VARIABLES.DEVICE_TYPE,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
	}
}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { UserService } from '../../../services/user.service';
import { SessionService } from '../../../services/session.service';
import { User } from '../../../models/user';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { MeddraService } from 'src/app/services/meddra.service';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { NgForm, FormControl } from '@angular/forms';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-medical-report',
  templateUrl: './medical-report.component.html',
  styleUrls: ['./medical-report.component.css']
})
export class MedicalReportComponent implements OnInit {

  displayedColumns: string[] = ['sn', 'email', 'blood_group', 'organ_donor', 'date_of_birth', 'gender', 'smoker', 'allergy',  'created_at',  'presenting_condition', 'diagonosis', 'start_date' , 'end_date', 'notes', 'other_intervention'];

  
  lltCtrl= new FormControl();

  dataSource: any = [];
  users: any = [];
  user_status: string;
  filteredLLTs: any;
  llts: any = [];
  public excelData: any = [];
  public genderselection: string;
  public startDate: string;
  public endDate: string;
  public age: string;
  lltId: number;
  lang: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) sort: MatSort;
  myGroup: any;

  constructor(
      public constant: CONSTANTS,
      public userServ: UserService,
      public sessionServ: SessionService,
      private snackBar: MatSnackBar,
      public loaderServ: NgxUiLoaderService,
      private meddraServ: MeddraService,
  ) { }

  ngOnInit() {
      this.lang = this.sessionServ.getLang(); 
      this.lltCtrl = new FormControl();
      this.records();
      this.setLLTCtrl();
     
  }

  ngAfterViewInit() {
      this.dataSource.sort = this.sort;
  }

  setLLTCtrl() {
    this.lltCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
      )
      .subscribe(llt => {
        this.filterLLTs(llt);
      });
  }

  records() {
    this.loaderServ.start();
      this.userServ.recordsReportsMedical()
          .then(res => {  
            // this.loaderServ.stop();              
              this.users = res.response;
              console.log(this.users);
              // this.users.filter(element => {
              //     element.name = '';
                  
              //     if(element.user_profile)
              //         element.name = element.user_profile.name;
                      
              // });

              this.applyTableBehaviour(this.users);
          });
  }

  
  applyTableBehaviour(data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.loaderServ.stop();        
  }

  
  displayLLT(data: any): string | undefined {
      return data ? data.name : undefined;
  }

  

  filterLLTs(data: any) {
  
    let value = data;
   
    if (data.name) {
      value = data.name;
      this.lltId = data.id;
    } else {
      this.lltId = null;
    }
    this.meddraServ.getMeddraData(this.constant.MEDDRA_TYPES.LLT, data)
      .then(res => {
        if (res.response.data !== undefined) {
          this.llts = res.response.data;
        } else {
          this.llts = res.response;
        }
        if (this.llts.length > 0) {
          // if (value === undefined) return this.llts;
          return this.filteredLLTs = this.llts.filter(llt => {
            if (llt.english !== null) {
              try {
                return llt.english.toString().toLowerCase().includes(value.toLowerCase()) === true
              }
              catch (err) {
                // debugger;
              }

            }
          }
          );
        }
      })


  }
  
  showMessage(res: any) {    
  if(this.constant.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
    this.snackBar.open(res.message, '', {
      duration: 3000,
      panelClass: ['green-snackbar']
    });
  else
    this.snackBar.open(res.message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
  } 

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  applyFilter() {
    let data = [];
    let startDateInput = this.formatDate(this.startDate);
    let startDate = Date.parse(startDateInput);
    console.log(startDate);
    let age = this.age;
    
    let endDateInput = this.formatDate(this.endDate);
    let endDate = Date.parse(endDateInput);
    let gender = this.genderselection;
    let lltCtrlValue = '';
    
    if(this.lltCtrl.value) {
      lltCtrlValue = this.lltCtrl.value.english;
    }

    if(age || gender || startDate || endDate || lltCtrlValue) {
      if(this.users) {
        // data = this.users;
        let conditionList = [];
        if(typeof(age)!="undefined" && age!="")
        {
          conditionList.push("age")
        }

        if(typeof(gender)!="undefined" && gender!="")
        {
          conditionList.push("gender")
        }
        if(typeof(lltCtrlValue)!="undefined" && lltCtrlValue!="")
        {
          conditionList.push("lltCtrlValue")
        }

        if(startDate && !endDate)
        {
          conditionList.push("startDate")
        }

        if(endDate && !startDate)
        {
          conditionList.push("endDate");
        }

        if(endDate && startDate)
        {
          conditionList.push("startEndDate");     
        }
        
        data=this.users.filter(user => {
          let userdetails = [];
            let condition = true;
            let user_joining = Date.parse(user.created_at);
            let start_data = Date.parse(user.start_date);
            let end_data = Date.parse(user.end_date);
            let gender_value = user.gender;
            let age_value = user.age;
            let presenting_condition = user.presenting_condition;
          
            conditionList.forEach(function(item){
              if(condition!=false && item=="age"){
                  condition = age== user.age
              }
              if(condition!=false && item=="gender"){
                  condition = gender== user.gender
              }
              if(condition!=false && item=="lltCtrlValue"){
                  condition = lltCtrlValue== user.presenting_condition
              }
              // startDate = 1579737600000;
              if(condition!=false && item=="startDate" ){
                if(user.start_date != "" && Date.parse(user.start_date) != null) {
                  let convert_date = Date.parse(user.start_date);
                  condition = startDate == convert_date
                }
              }

              if(condition!=false && item=="endDate" ){
                if(user.end_date != "" && Date.parse(user.end_date) != null) {
                  let convert_date = Date.parse(user.end_date);
                  condition = endDate == convert_date
                }
              }
              
              if(condition!=false && item=="startEndDate"){
                let convert_date_end = Date.parse(user.end_date);
                let convert_date_start = Date.parse(user.start_date);
                  //  if (startDate <= convert_date_start && convert_date_end <= endDate) {
                    condition = (startDate <= convert_date_start && convert_date_end <= endDate)
                  // }
              }

            })
           
            return condition;

            // if(presenting_condition == lltCtrlValue && user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
            //   console.log("111111111");
            //   data.push(user);
            // } else if(gender && age) {
            //       if(gender == gender_value && age_value == age) {
            //         console.log("-------");
            //         data.push(user);
            //     }
            // } else if(age && age_value == age) {
            //   console.log("+++++");
            //     data.push(user);
            // } else if(gender && gender == gender_value) {
            //   console.log(">>>>>>>>>");
            //   data.push(user);
            // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
            //     data.push(user);
            // } else if(lltCtrlValue && presenting_condition == lltCtrlValue){
            //   data.push(user);
            // }

          
        });
        this.applyTableBehaviour(data);
    }
  }
  }

  downloadCSV() {
    this.excelData = [];
    this.excelData.push(this.displayedColumns);
    
    let that = this;
    
    this.dataSource.filteredData.filter(function(element, index, array) {
      let email: string;  
      let fname: string;  
      let medical_id: string;  
      let date_of_birth: string;  
      let smoker: string;  
      let organ_donor: string;
      let primary_emergency_contact_number: string;
      let secondary_emergency_contact_number: string;
      let doctor_contact_info: string;
      if(element.user_consent !='0') 
        {
           email = element.email;
           fname = element.name;
           medical_id = element.medical_id;
           primary_emergency_contact_number = element.primary_emergency_contact_number;
           secondary_emergency_contact_number = element.secondary_emergency_contact_number;
           doctor_contact_info = element.doctor_contact_info;
        } else {
           email = '*****';
           fname = '*****';
           medical_id = '*****';
           primary_emergency_contact_number = '*****';
           secondary_emergency_contact_number = '*****';
           doctor_contact_info = '*****';
        }
  
        if(element.smoker == 1){
          smoker = 'Yes';
        } else if(element.smoker == 0) {
          smoker = 'No';
        } else {
          smoker = 'No';
        }
  
        if(element.organ_donor == 1){
          organ_donor = 'Yes';
        } else if(element.organ_donor == 0) {
          organ_donor = 'No';
        } else {
          organ_donor = 'No';
        }
  
        if(element.date_of_birth) {
          date_of_birth = element.date_of_birth
        }
        
        that.excelData.push([index + 1 , email, element.blood_group, organ_donor, date_of_birth, element.gender, smoker,  element.allergy, element.created_at, element.presenting_condition, element.diagonosis, element.start_date , element.end_date, element.notes, element.other_intervention]);  
    });
  
    new AngularCsv(this.excelData, 'medical_history');
  }

  resetFilter() {
    this.dataSource.filter = '';
    this.startDate = '';
    this.age = '';
    this.endDate = '';
    this.genderselection = '';
    this.lltCtrl = new FormControl('');
    this.records();
 }
 
  

}

import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user.service';
import { DataService } from '../../services/data.service';
import { ProfileDataService } from '../../services/profile-data.service';

@Component({
    selector: 'app-my-family',
    templateUrl: './my-family.component.html',
    styleUrls: ['./my-family.component.css']
})
export class MyFamilyComponent implements OnInit {

    user: any;
    loggedInUser: any;
    noData: boolean = true;
    members: any = [];
    hide: boolean[] = [];
    lang: string;

    private profileId = new Subject<number>();

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public dataServ: DataService,
        public router: Router,
        public loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
        private profileDataServ: ProfileDataService
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        this.loggedInUser = this.sessionServ.getLoggedInUser();

        this.user = this.sessionServ.getUser();
        this.loaderServ.start();
        this.getMembers();        
    }

    getMembers() {
        this.userServ.getFamilyMembers()
            .then(res => {
                this.members.length = 0;
                let members = res.response;

                if(members.length <= 1)
                    this.noData = false;
                
                members.filter(element => {
                    if(element.id != this.user.id) {
                        this.hide[element.id] = true;
                        
                        if(element.image)
                            element.image =  this.constants.SERVER.BASE + '/' +element.image;
                        else
                            element.image = '/assets/images/dummy-user.png';

                        this.members.push(element);
                    }
                });

                this.loaderServ.stop();
            });
    }

    memberProfile(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/member-profile');
    }

    remove(id: number) {   
        this.snackBar.open(this.constants.REMOVE_ALERT[this.lang], this.constants.YES[this.lang], {
            duration: 4000,
            verticalPosition: 'top',
            panelClass: ['white-snackbar']
        }).onAction().subscribe(() => {
            this.loaderServ.start();
            this.userServ.removeMember(id)
                .then(res => {
                    let removedMember = null;
                    for(let i=0; i<this.members.length; i++) {
                        if(this.members[i].id == id)
                            removedMember = this.members[i];
                    }
                    
                    this.getMembers();
                    this.profileDataServ.changeMember(removedMember);
            });
        });
    }

    toggle(flag: boolean, id: number) {        
            this.hide[id] = !flag;``
    }

    addFamilyMember() {
        // alert("yeda");
    }
}

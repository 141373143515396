import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { UserService } from '../../../services/user.service';
import { SessionService } from '../../../services/session.service';
import { User } from '../../../models/user';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
  selector: 'app-dental-report',
  templateUrl: './dental-report.component.html',
  styleUrls: ['./dental-report.component.css']
})
export class DentalReportComponent implements OnInit {

  displayedColumns: string[] = ['sn', 'email', 'blood_group', 'organ_donor', 'date_of_birth', 'gender', 'smoker', 'allergy', 'created_at', 'procedures', 'start_date', 'end_date', 'notes', 'other_intervention'];
  
  dataSource: any = [];
  users: any = [];
  user_status: string;
  public excelData: any = [];
  public genderselection: string;
  public startDate: string;
  public endDate: string;
  public age: string;
  public procedures: string;
  

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) sort: MatSort;

  constructor(
      public constant: CONSTANTS,
      public userServ: UserService,
      public sessionServ: SessionService,
      private snackBar: MatSnackBar,
      public loaderServ: NgxUiLoaderService,
  ) { }

  ngOnInit() {
      this.records();
  }

  ngAfterViewInit() {
      this.dataSource.sort = this.sort;
  }

  records() {
    this.loaderServ.start();
      this.userServ.recordsReportsDental()
          .then(res => {       
            
              this.users = res.response;
              console.log(this.users);
              this.applyTableBehaviour(this.users);
          });
  }

  
  applyTableBehaviour(data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.loaderServ.stop();        
  }

  showMessage(res: any) {    
    if(this.constant.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
      this.snackBar.open(res.message, '', {
        duration: 3000,
        panelClass: ['green-snackbar']
      });
    else
      this.snackBar.open(res.message, '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
}

formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
} 

applyFilter() {
    let data = [];
    
    let startDateInput = this.formatDate(this.startDate);
    let startDate = Date.parse(startDateInput);
    let age = this.age;
    
    let endDateInput = this.formatDate(this.endDate);
    let endDate = Date.parse(endDateInput);
    let gender = this.genderselection;
    let proceduresInput = this.procedures;
    
    if(age || gender || startDate || endDate || proceduresInput) {
      if(this.users) {
        let conditionList = [];

        if(typeof(age)!="undefined" && age!="")
        {
          conditionList.push("age")
        }

        if(typeof(gender)!="undefined" && gender!="")
        {
          conditionList.push("gender")
        }
        if(proceduresInput && proceduresInput!="")
        {
          conditionList.push("proceduresInput")
        }

        if(startDate && !endDate)
        {
          conditionList.push("startDate")
        }

        if(endDate && !startDate)
        {
          conditionList.push("endDate");
        }

        if(endDate && startDate)
        {
          conditionList.push("startEndDate");     
        }
        
        data = this.users.filter(user => {
          let userdetails = [];
          let condition = true;
          // if (endDate && startDate) {
            let user_joining = Date.parse(user.created_at);
            let gender_value = user.gender;
            let age_value = user.age;
           
            conditionList.forEach(function(item){
              if(condition!=false && item=="age"){
                  condition = age== user.age
              }
              if(condition!=false && item=="gender"){
                  condition = gender== user.gender
              }
              if(condition!=false && item=="proceduresInput"){
                var str = String(user.procedures);
                let checked = str.indexOf(String(proceduresInput)) !== -1 // true
                condition =checked
              }
              // startDate = 1579737600000;
              if(condition!=false && item=="startDate" ){
                if(user.start_date != "" && Date.parse(user.start_date) != null) {
                  let convert_date = Date.parse(user.start_date);
                  condition = startDate == convert_date
                }
              }

              if(condition!=false && item=="endDate" ){
                if(user.end_date != "" && Date.parse(user.end_date) != null) {
                  let convert_date = Date.parse(user.end_date);
                  condition = endDate == convert_date
                }
              }
              
              if(condition!=false && item=="startEndDate"){
                let convert_date_end = Date.parse(user.end_date);
                let convert_date_start = Date.parse(user.start_date);
                  //  if (startDate <= convert_date_start && convert_date_end <= endDate) {
                    condition = (startDate <= convert_date_start && convert_date_end <= endDate)
                  // }
              }

            })
           
            return condition;
        
          
          //   if(checked == true && user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
          //   console.log("111111111");
          //   data.push(user);
          //   } else if(gender && age) {
          //       if(gender == gender_value && age_value == age) {
          //         console.log("-------");
          //         data.push(user);
          //     }
          //   } else if(age && age_value == age) {
          //   console.log("+++++");
          //     data.push(user);
          //   } else if(gender && gender == gender_value) {
          //   console.log(">>>>>>>>>");
          //   data.push(user);
          // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
          //     data.push(user);
          // } else if(str && checked == true){
          //     data.push(user);
          // }

          // if(proceduresInput && checked == true && user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
          //   console.log("LLT, Start and End date, Age, Gender");
          //   data.push(user);
          // } else if(user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
          //   console.log("Start and End date, Age, Gender");
          //   data.push(user);
          // } else if(proceduresInput && checked == true && endDate && startDate && user_joining <= endDate && user_joining >= startDate && gender && gender == gender_value) {
          //   console.log("LLT,  Start and End date, Gender");
          //   data.push(user);
          // } else if(proceduresInput && checked == true  && endDate && startDate && user_joining <= endDate && user_joining >= startDate && age && age_value == age) {
          //   console.log("LLT , Start and End date, Age");
          //   data.push(user);
          // } else if(proceduresInput && checked == true  && gender && gender == gender_value && age && age_value == age) {
          //   console.log("LLT , Gender, Age");
          //   data.push(user);
          // } else if(gender && gender == gender_value && endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
          //   console.log("Start and End date, Gender");
          //   data.push(user);
          // } else if(age && age_value == age && endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
          //   console.log("Start and End date, Age");
          //   data.push(user);
          // } else if(gender && age && gender == gender_value && age_value == age) {
          //   console.log("Gender, Age");
          //   data.push(user);
          // } else if(proceduresInput && checked == true  && endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
          //   console.log("LLT, Start and End date");
          //   data.push(user);
          // } else if(proceduresInput && checked == true  && gender && gender == gender_value) {
          //   console.log("LLT,Gender");
          //   data.push(user);
          // } else if(proceduresInput && checked == true  && age && age_value == age) {
          //   console.log("LLT,Age");
          //   data.push(user);
          // } else if(age && age_value == age) {
          //   console.log("Age");
          //    data.push(user);
          // } else if(gender && gender == gender_value) {
          //   console.log("Gender");
          //   data.push(user);
          // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
          //   console.log("Start and End date");
          //   data.push(user);
          // } if(proceduresInput && checked == true ){
          //   console.log("Presenting");
          //   data.push(user);
          // }
          
        });
        this.applyTableBehaviour(data);
    }
  }
  }

  resetFilter() {
    this.dataSource.filter = '';
    this.startDate = '';
    this.age = '';
    this.endDate = '';
    this.genderselection = '';
    this.procedures = '';
    this.records();
 }

 downloadCSV() {
  this.excelData = [];
  this.excelData.push(this.displayedColumns);
  
  let that = this;
  
  this.dataSource.filteredData.filter(function(element, index, array) {
    let email: string;  
    let fname: string;  
    let medical_id: string;  
    let date_of_birth: string;  
    let smoker: string;  
    let organ_donor: string;
    let primary_emergency_contact_number: string;
    let secondary_emergency_contact_number: string;
    let doctor_contact_info: string;
    if(element.user_consent !='0') 
      {
         email = element.email;
         fname = element.name;
         medical_id = element.medical_id;
         primary_emergency_contact_number = element.primary_emergency_contact_number;
         secondary_emergency_contact_number = element.secondary_emergency_contact_number;
         doctor_contact_info = element.doctor_contact_info;
      } else {
         email = '*****';
         fname = '*****';
         medical_id = '*****';
         primary_emergency_contact_number = '*****';
         secondary_emergency_contact_number = '*****';
         doctor_contact_info = '*****';
      }

      if(element.smoker == 1){
        smoker = 'Yes';
      } else if(element.smoker == 0) {
        smoker = 'No';
      } else {
        smoker = 'No';
      }

      if(element.organ_donor == 1){
        organ_donor = 'Yes';
      } else if(element.organ_donor == 0) {
        organ_donor = 'No';
      } else {
        organ_donor = 'No';
      }

      if(element.date_of_birth) {
        date_of_birth = element.date_of_birth
      }
      
      that.excelData.push([ index + 1 , email, element.blood_group, organ_donor, date_of_birth, element.gender, smoker,  element.allergy, element.created_at, element.procedures, element.start_date, element.end_date, element.notes, element.other_intervention]);  
  });

  new AngularCsv(this.excelData, 'dental_records');
}
 


}

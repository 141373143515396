import { Component } from '@angular/core';
import { ActivatedRoute, Router, PRIMARY_OUTLET } from '@angular/router';
import { SessionService } from './services/session.service';
import { CONSTANTS } from './util/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'LyfeTrek';

    constructor(private router: Router,
        public sessionServ: SessionService,
        public constants: CONSTANTS
        )
    {
        try {
            let language = 'en';
            var pathArray = window.location.pathname.split('/');
            if(pathArray[1]!="" && this.constants.LANGUAGE_LIST.includes(pathArray[1])!==false)
            {
                language = pathArray[1];
            }
            if(this.constants.LANGUAGE_LIST.includes(language)!==false)
            {
                this.sessionServ.setLang(language);
            }
        } catch (error) {
            
        }
      
    }
    
    ngOnInit() {
        // alert("lang");
    }
}

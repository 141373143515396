import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { DentalRecordService } from '../../../services/dental-record.service';
import { DentalRecord } from '../../../models/dental-record';
import { DataService } from '../../../services/data.service';
import { SessionService } from '../../../services/session.service';
import { TreatmentService } from 'src/app/services/treatment.service';

@Component({
	selector: 'app-deleted-dental-records',
	templateUrl: './deleted-dental-records.component.html',
	styleUrls: ['./deleted-dental-records.component.css']
})
export class DeletedDentalRecordsComponent implements OnInit {

    user: any;
    id: number;
	assetLength: number;
	dentalRecords: any = [];
    teethStatuses: any = [];
	treatmentDetails: any = [];
	dentalRecordAssets: any = [];
	noData: boolean = true;
	lang: string = 'en';
	
	procedures = [
        {
            'title': 'cleaning',
            'label': 'Cleaning'
        },
        {
            'title': 'fluoride',
            'label': 'Fluoride'
        },
        {
            'title': 'filling',
            'label': 'Restoration/Filling'
        },
        {
            'title': 'gum_treatment',
            'label': 'Periodontal/Gum treatment'
        },
        {
            'title': 'rct',
            'label': 'Root canal treatment'
        },
        {
            'title': 'extraction',
            'label': 'Extraction'
        },
        {
            'title': 'surgical_procedure',
            'label': 'Surgical procedure'
        },
    ];
    
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public dentalRecordServ: DentalRecordService,
        public dataServ: DataService,
        public router: Router,
        public sessionServ: SessionService,
        public treatmentServ: TreatmentService,
        private snackBar: MatSnackBar,
    ) { }

	ngOnInit() {
		this.lang = this.sessionServ.getLang();

		this.records();
	}

	records() {
		this.loaderServ.start();
		this.dentalRecordServ.deletedRecords()
			.then(res => {
				this.dentalRecords = res.response;
				this.loaderServ.stop();
				if(this.dentalRecords.length <= 0)
					this.noData = false;
					

				console.log(this.dentalRecords);
				
				for(let n=0; n<this.dentalRecords.length; n++) {
					this.treatmentDetails[this.dentalRecords[n].id] = this.dentalRecords[n].treatmentDetails;                       

					for(let i=0; i<this.constants.PROCEDURES.length; i++) {
					
						if(this.dentalRecords[n][this.constants.PROCEDURES[i][this.lang].title])
							// console.log(this.dentalRecords[n][this.constants.PROCEDURES[i][this.lang].title]);
							this.dentalRecords[n][this.constants.PROCEDURES[i][this.lang].title] = JSON.parse(this.dentalRecords[n][this.constants.PROCEDURES[i][this.lang].title])
					}

					for(let i=11; i<=85; i++) {                            
						if(!this.teethStatuses[this.dentalRecords[n].id])
							this.teethStatuses[this.dentalRecords[n].id] = [];
						if(!this.teethStatuses[this.dentalRecords[n].id][i])
							this.teethStatuses[this.dentalRecords[n].id][i] = [];
						this.teethStatuses[this.dentalRecords[n].id][i]['color'] = '#ffffff';
						this.teethStatuses[this.dentalRecords[n].id][i]['code'] = '';                        
					}

					if(this.dentalRecords[n].teeth_status) {
						this.dentalRecords[n].teeth_status = JSON.parse(this.dentalRecords[n].teeth_status);
						if(this.dentalRecords[n].teeth_status['fixed'])
							for(let i=0; i<this.dentalRecords[n].teeth_status['fixed'].length; i++)
								this.teethStatuses[this.dentalRecords[n].id][this.dentalRecords[n].teeth_status['fixed'][i].tooth] = this.dentalRecords[n].teeth_status['fixed'][i];
					}
					
					this.dentalRecordAssets[this.dentalRecords[n].id] = this.dentalRecords[n].assetDetails;
				}
			});			
    }

  	restore(id: number) {
		this.dentalRecordServ.restore(id)
			.then(res => {
				this.showMessage(res);
				this.records();
			});
    }
    
    showMessage(res: any) {    
		if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['green-snackbar']
			});
		else
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['red-snackbar']
			});
	}

}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../../services/session.service';
import { UserProfile } from '../../../models/user-profiles';
import { UserService } from '../../../services/user.service';
import { ProfileDataService } from '../../../services/profile-data.service';

@Component({
    selector: 'app-add-basic-info',
    templateUrl: './add-basic-info.component.html',
    styleUrls: ['./add-basic-info.component.css']
})
export class AddBasicInfoComponent implements OnInit {
    
    userProfile: UserProfile;
    message: string;
    monthOfBirth: number;
    dayOfBirth: number;
    yearOfBirth: number;
    isMaleClicked: boolean = true;
    isFemaleClicked: boolean = false;
    profileImage: string = '/assets/images/dummy-user.png';
    file: File;
    backgroundStyle: any;
    borderRadius: string = '0';
    backgroundSize: string = 'contain';
    lang: string;

    years: number[] = [];

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public loaderServ: NgxUiLoaderService,
        private router: Router,
        private snackBar: MatSnackBar,
        private profileDataServ: ProfileDataService
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        for(let i=new Date().getFullYear(); i>=1970; i--)
            this.years.push(i);        
        this.userProfile = new UserProfile(this.sessionServ.getUser());

        let date = null;
        if(this.userProfile.date_of_birth) {
            date = new Date(this.userProfile.date_of_birth);

            this.monthOfBirth = date.getMonth() + 1;
            this.dayOfBirth = date.getDate();
            this.yearOfBirth = date.getFullYear();
        }
        
        if(this.userProfile.gender) {
            if(this.constants.GENDERS.MALE.toLowerCase() == this.userProfile.gender.toLowerCase()) {
                this.isMaleClicked = true;
                this.isFemaleClicked = false;
            } else {
                this.isMaleClicked = false;
                this.isFemaleClicked = true;
            }
        }
        
        if(this.userProfile.image) {
            this.profileImage = this.constants.SERVER.BASE + '/' + this.userProfile.image;
            this.borderRadius = '50%';
            this.backgroundSize = 'cover';
        }

        this.setBackgroundStyle();
    }

    checkOrganDonor(isOrganDonnor: number) {
        this.userProfile.organ_donor = isOrganDonnor;
    }

    genderSelected(gender: string) {
        this.userProfile.gender = gender;
        if(this.constants.GENDERS.MALE == gender) {
            this.isMaleClicked = true;
            this.isFemaleClicked = false;
        } else {
            this.isMaleClicked = false;
            this.isFemaleClicked = true;
        }
    }

    checkSmoker(isSmoker: number) {
        this.userProfile.smoker = isSmoker;
    }

    handleFileInput(files: FileList) {        
        this.file = files.item(0);        
        this.loaderServ.start();
        this.userServ.uploadImage({file: this.file, id: this.userProfile.id})
            .then(res => {
                if(this.constants.RESPONSE_CODES.HTTP_STATUS_BAD_REQUEST == res.status) {
                    this.loaderServ.stop();
                    this.snackBar.open(res.message, '', {
                        duration: 3000,
                        panelClass: ['red-snackbar']
                    });
                } else {
                    this.message = res.message;         
                    this.userProfile.image = res.response.image;

                    if(this.userProfile.image) {
                        let user = this.sessionServ.getUser();                    
                        this.profileImage = this.constants.SERVER.BASE + '/' + this.userProfile.image;
                        user.image = this.userProfile.image;                    
                        this.sessionServ.setLoggedInUser(user);
                        this.sessionServ.setUser(user);

                        this.borderRadius = '50%';
                        this.backgroundSize = 'cover';
                        
                        this.setBackgroundStyle();
                    }
                    
                    this.loaderServ.stop();
                    this.showMessage(res);
                }
            });
    }

    saveProfile(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            if(this.yearOfBirth && this.monthOfBirth && this.dayOfBirth)
                this.userProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;
            else
                this.userProfile.date_of_birth = null;
            this.userServ.updateProfile(this.userProfile, this.userProfile.id)
                .then(res => {
                    let user = this.sessionServ.getUser();
                    this.userServ.getLoggedInUserProfile(user.token)
                        .then(userResponse => {
                            let user = this.sessionServ.getUser();        
                            user.token = user.token;
                            user.email = user.user.email;
                            user.type = user.user.type;
                            user.name = res.response.name;
                            user.medical_id = res.response.medical_id;
                            user.blood_group = res.response.blood_group;
                            user.rh_factor = res.response.rh_factor;
                            user.primary_emergency_contact_name = res.response.primary_emergency_contact_name;
                            user.primary_emergency_contact_number = res.response.primary_emergency_contact_number;
                            user.secondary_emergency_contact_name = res.response.secondary_emergency_contact_name;
                            user.secondary_emergency_contact_number = res.response.secondary_emergency_contact_number;
                            user.doctor_contact_info = res.response.doctor_contact_info;
                            user.smoker = res.response.smoker;
                            user.organ_donor = res.response.organ_donor;
                            user.gender = res.response.gender;
                            user.date_of_birth = res.response.date_of_birth;
                           
                            this.sessionServ.setLoggedInUser(user);
                            this.sessionServ.setUser(user);
                            
                            this.loaderServ.stop();
                            this.router.navigateByUrl('user');
                        });
                        
                    this.showMessage(res);
                });
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    setBackgroundStyle() {
        this.backgroundStyle = {'overflow': 'hidden', 'position': 'absolute', 'right': '0px', 'top': '5px', 'background-repeat': 'no-repeat', 'background-size': this.backgroundSize, 'background-position': 'center', 'background-image': this.profileImage, 'border-radius': this.borderRadius}
    }
}

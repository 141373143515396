import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../../services/session.service';
import { UserProfile } from '../../../models/user-profiles';
import { UserService } from '../../../services/user.service';
import { ProfileDataService } from '../../../services/profile-data.service';
import { DataService } from '../../../services/data.service';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

    userProfile: UserProfile;
    monthOfBirth: number;
    dayOfBirth: number;
    yearOfBirth: number;
    isMaleClicked: boolean = false;
    isFemaleClicked: boolean = false;
    profileImage: string = 'url(/assets/images/user-img.png)';
    file: File;
    backgroundStyle: any;
    borderRadius: string = '0';
    backgroundSize: string = 'contain';
    years: number[] = [];
    user_emergency_contact_number: string;
    lang: string = 'en';
    userId:number;

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public loaderServ: NgxUiLoaderService,
        public profileDataServ: ProfileDataService,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        public dataServ: DataService,
    ) { }

    ngOnInit() {        
        // for(let i=new Date().getFullYear(); i>=1901; i--)
        for(let i=new Date().getFullYear(); i>=1600; i--)
            this.years.push(i);

            let userid = this.route.snapshot.params.id;
            // alert(userid);
            this.userProfile = new UserProfile();
            
            // this.getProfile(userid);  

        // this.userProfile = new UserProfile(this.sessionServ.getUser());

        this.userServ.getUserProfile(userid)
                .then(res => {
            this.userProfile = new UserProfile(res.response);

            let date = null;
            if(this.userProfile.date_of_birth) {
                date = new Date(this.userProfile.date_of_birth);
                this.monthOfBirth = date.getMonth() + 1;
                this.dayOfBirth = date.getDate();
                this.yearOfBirth = date.getFullYear();
            }
            if(this.userProfile.gender) {
                if(this.userProfile.gender != null && this.constants.GENDERS.MALE.toLowerCase() == this.userProfile.gender.toLowerCase()) {
                    this.isMaleClicked = true;
                    this.isFemaleClicked = false;
                }
                else if(this.userProfile.gender != null && this.constants.GENDERS.FEMALE.toLowerCase() == this.userProfile.gender.toLowerCase()) {
                    this.isMaleClicked = false;
                    this.isFemaleClicked = true;
                } else {
                    this.isMaleClicked = false;
                    this.isFemaleClicked = false;
                }
            }
            
            if(this.userProfile.image) {
                this.profileImage = 'url(' + this.constants.SERVER.BASE + '/' +  this.userProfile.image + ')';
                this.borderRadius = '50%';
                this.backgroundSize = 'cover';
            }
    
            this.lang = this.sessionServ.getLang();
            // this.lang = 'de';
    
            this.setBackgroundStyle();
        });

        //    console.log(this.userProfile);    
    }

   

    checkOrganDonor(isOrganDonnor: number) {
        this.userProfile.organ_donor = isOrganDonnor;
    }

    genderSelected(gender: string) {
        this.userProfile.gender = gender;
        if(this.constants.GENDERS.MALE == gender) {
            this.isMaleClicked = true;
            this.isFemaleClicked = false;
        } else if(this.constants.GENDERS.FEMALE == gender) {
            this.isMaleClicked = false;
            this.isFemaleClicked = true;
        } else {
            this.isMaleClicked = false;
            // this.isFemaleClicked = true;
            this.isFemaleClicked = false;
        }
    }

    checkSmoker(isSmoker: number) {
        this.userProfile.smoker = isSmoker;
    }

    handleFileInput(files: FileList) {        
        this.file = files.item(0);        
        this.loaderServ.start();

        this.userServ.uploadImage({file: this.file, id: this.userProfile.id})
            .then(res => {
                if(this.constants.RESPONSE_CODES.HTTP_STATUS_BAD_REQUEST == res.status) {
                    let message = res.message;         
                    this.loaderServ.stop();
                    this.snackBar.open(message, '', {
                        duration: 3000,
                        panelClass: ['red-snackbar']
                    });
                } else {
                    this.userProfile.image = res.response.image;
                    if(this.userProfile.image) {
                    
                        let user = this.sessionServ.getUser();

                        user.image = this.userProfile.image;                                        
                        this.sessionServ.setUser(user);          
                        this.profileDataServ.changeImage(this.userProfile.image);         

                        this.profileImage = 'url(' + this.constants.SERVER.BASE + '/' + this.userProfile.image + ')';
                        this.borderRadius = '50%';
                        this.backgroundSize = 'cover';
                        
                        this.setBackgroundStyle();
                        this.loaderServ.stop();
                    }
                    this.loaderServ.stop();
                    this.showMessage(res);
                }
            });
    }

    saveProfile(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();

            this.userProfile.date_of_birth = null;

            if(this.yearOfBirth && this.monthOfBirth && this.dayOfBirth)
                this.userProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;

            this.userProfile.image = null;

            // blood group amd blodd type validation start
            if(this.userProfile.blood_group != null && this.userProfile.rh_factor == null) {
                this.loaderServ.stop();
              
                this.snackBar.open(this.constants.BLOOD_TYPE_ERROR[this.lang].BLOODTYPE, '', {
                  duration: 3000,
                  panelClass: ['red-snackbar']
                }); 
                return false;
            }
            // blood group and blood type validation end

            this.userServ.updateProfile(this.userProfile, this.userProfile.id)
                .then(res => {
                    let user = this.sessionServ.getUser();
                    // this.profileDataServ.changeName(this.userProfile.name);

                    // console.log(user);

                    this.userServ.getUserProfile(this.userProfile.id).then(res_profile => {
                        // console.log('user id=>'+res_profile.response.user_id);
                        if(res_profile.response.user_id != null) {
                            if(res.response.primary_emergency_contact_number) {
                                this.user_emergency_contact_number = res.response.primary_emergency_contact_number;
                            } else if(res.response.secondary_emergency_contact_number) {
                                this.user_emergency_contact_number = res.response.secondary_emergency_contact_number;
                            } else {
                                this.user_emergency_contact_number = "";
                            }
                            this.profileDataServ.changePhone(this.user_emergency_contact_number);
        
                            this.profileDataServ.changeLoggedInUserName(this.userProfile.name);
                            this.userServ.getLoggedInUserProfile(user.token)
                            .then(res => {
                                    // alert('main_user');
                                    // console.log(res);
                                    let loggedUser = res.response;                                
                                    loggedUser.token = user.token;
                                    loggedUser.email = user.email;
                                    loggedUser.type = user.type;
                                    loggedUser.image = user.image;
                                    this.userServ.saveFamilyMemberBS.next(Math.random());
                                    // if(user.id == res.response.id) {
                                        this.sessionServ.setLoggedInUser(loggedUser);
                                    // } else {
                                        this.sessionServ.setUser(loggedUser);
                                    // }
                               
                                this.loaderServ.stop();
                                // this.showMessage(res);
                                if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status) {
                                    this.snackBar.open(this.constants.UPDATE_PROFILE[this.lang], '', {
                                        duration: 3000,
                                        panelClass: ['green-snackbar']
                                    });
                                } else {
                                    this.showMessage(res);
                                }
                            
                                // this.router.navigateByUrl('/user/profile');
                                
                            });
                        } else {
                            // alert('profile user');
                            this.profileDataServ.changeName(this.userProfile.name);
                            this.loaderServ.stop();
                            if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status) {
                                this.snackBar.open(this.constants.UPDATE_PROFILE[this.lang], '', {
                                    duration: 3000,
                                    panelClass: ['green-snackbar']
                                });
                                this.userServ.saveFamilyMemberBS.next(Math.random());
                            } else {
                                this.showMessage(res);
                            }
                        }
                       
                        
                    })
                    
                    
                                       
                });
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    setBackgroundStyle() {
        this.backgroundStyle = {'overflow': 'hidden', 'position': 'absolute', 'right': '0px', 'top': '5px', 'background-repeat': 'no-repeat', 'background-size': this.backgroundSize, 'background-position': 'center', 'background-image': this.profileImage, 'border-radius': this.borderRadius}
    }
}

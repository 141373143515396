import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { SessionService } from 'src/app/services/session.service';

import { CONSTANTS } from 'src/app/util/constants';

@Component({
    selector: 'app-tooth-map',
    templateUrl: './tooth-map.component.html',
    styleUrls: ['./tooth-map.component.css']
})
export class ToothMapComponent implements OnInit {

    quantity: number;
    position: string;
    isDisabled: boolean = false;
    isChecked: boolean[] = [];

    fixedTeeth: any[] = [];
    superNumeraryTeeth: any = [];
    selectedTeeth: any = [];
    procedureString: string;
    lang: string = 'en';
    cleaningString: string;
    fluorideSting: string;

    constructor(
        private dataServ: DataService,
        public dialog: MatDialog,
        public constants: CONSTANTS,
        public dialogRef: MatDialogRef<ToothMapComponent>,
        private sessionServ: SessionService,
        
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
        if(data) {
            if(data['fixed'])
                this.fixedTeeth = data['fixed'];
            if(data['supernum']) {
                this.quantity = data['supernum']['quantity'];
                this.position = data['supernum']['position'];
            }
        }
    }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        // this.cleaningString = this.constants.PROCEDURES_CLEANING[0][this.lang].title;
        // this.fluorideSting = this.constants.PROCEDURES_FLUORIDE[0][this.lang].title;
        this.cleaningString = this.constants.PROCEDURES_CLEANING[0]['en'].title;
        this.fluorideSting = this.constants.PROCEDURES_FLUORIDE[0]['en'].title;
        this.dataServ.procedureString.subscribe(procedureString => {
            this.procedureString = procedureString;
        });

        // console.log('procedure string=>'+this.procedureString);


        this.isChecked['All'] = false;

        if('All' == this.fixedTeeth[0])
            this.isChecked['All'] = true;
        else
            for(let i=11; i<=85; i++) 
                this.isChecked[i] = false;

        if(this.fixedTeeth) {
            for(let i = 0; i<this.fixedTeeth.length; i++) 
                this.isChecked[this.fixedTeeth[i]] = true;
        
            this.selectedTeeth['fixed'] = this.fixedTeeth;
        } else 
            this.fixedTeeth = [];

        this.superNumeraryTeeth['quantity'] = this.quantity;
        this.superNumeraryTeeth['position'] = this.position;

        if(!this.superNumeraryTeeth['quantity'])
            this.superNumeraryTeeth['quantity'] = '';
        if(!this.superNumeraryTeeth['position'])
            this.superNumeraryTeeth['position'] = '';

        this.selectedTeeth['supernum'] = this.superNumeraryTeeth;
    }

    selectTeeth(n: any) {
       
        if('All' == n) {
            this.fixedTeeth = [];            
            //this.isDisabled = !this.isDisabled;
          
            this.constants.ALL_TEETH.forEach(element => {
                this.fixedTeeth.push(element);
            });
            // for(let i=11; i<=85; i++) {
            //     this.isChecked[i] = false;
            //     this.fixedTeeth.push(arr[i]);
            // }
           
        } else {
            if(this.fixedTeeth.indexOf('All') > 0) 
                this.fixedTeeth = [];
            this.isChecked['All'] = false;
        }
        
        if(this.fixedTeeth && this.fixedTeeth.includes(n)) {
            this.fixedTeeth.splice( this.fixedTeeth.indexOf(n), 1 );
            this.isChecked[n] = !this.isChecked[n];
        } else {
            this.fixedTeeth.push(n);
            this.isChecked[n] = !this.isChecked[n];
        }

        this.fixedTeeth.sort();
            
        this.selectedTeeth['fixed'] = this.fixedTeeth; 
    }

    selectSuperNum() {
        this.superNumeraryTeeth['quantity'] = this.quantity;
        this.superNumeraryTeeth['position'] = this.position;

        this.selectedTeeth['supernum'] = this.superNumeraryTeeth;
    }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../util/constants';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user.service';
import { Contact } from 'src/app/models/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public contact: Contact;
  lang: string = 'en';

  constructor(
    public constants: CONSTANTS,
    public userServ: UserService,
    public sessionServ: SessionService,
    public loaderServ: NgxUiLoaderService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.lang = this.sessionServ.getLang();

    this.contact = new Contact();
  }

  processContact(form: NgForm) {
    if(form.valid) {
        this.loaderServ.start();
        
        if(typeof this.contact.message == 'undefined'){
          this.loaderServ.stop();
        
          this.snackBar.open(this.constants.FILE_FORMAT_ERRORS[this.lang].ENTER_MESSAGE, '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          }); 
          return false;
        }
        
        this.userServ.saveContact(this.contact)
            .then(res => {
              this.loaderServ.stop();
              if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status) {
                this.snackBar.open(res.message, '', {
                    duration: 3000,
                    panelClass: ['green-snackbar']
                });
                form.resetForm();
               } else {
                this.snackBar.open(res.message, '', {
                    duration: 3000,
                    panelClass: ['red-snackbar']
                });               
                // this.showMessage(res);
              }
            });
    }
  }
}

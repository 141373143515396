import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { TreatmentService } from '../../../services/treatment.service';
import { SessionService } from '../../../services/session.service';
import { DataService } from '../../../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-deleted-treatments',
    templateUrl: './deleted-treatments.component.html',
    styleUrls: ['./deleted-treatments.component.css']
})
export class DeletedTreatmentsComponent implements OnInit {

    noData: boolean = false;
	treatments: any = [];
	lang: string;
   
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public treatmentServ: TreatmentService,
        public sessionServ: SessionService,
        public router: Router,
        public dataServ: DataService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
		this.lang = this.sessionServ.getLang();
        this.records();
    }

    records() {
		this.loaderServ.start();
		this.treatmentServ.deletedRecords()
			.then(res => {
                this.treatments = res.response;
				this.loaderServ.stop();
				if(this.treatments.length <= 0)
					this.noData = false;
			});
	}

	restore(id: number) {
		this.treatmentServ.restore(id)
			.then(res => {
				this.showMessage(res);
				this.records();
			});
    }
    
    showMessage(res: any) {    
		if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['green-snackbar']
			});
		else
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['red-snackbar']
			});
	}
}
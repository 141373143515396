import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class AssetService {

    constructor(
		private constants: CONSTANTS,
		private httpServ: HttpService,
		private sessionServ: SessionService
	) {
		this.constants = constants,
		this.httpServ = httpServ,
		this.sessionServ = sessionServ
	};

    /**
     * Prepares request object for add medical history and send it to http service
     * @param data 
     */
    upload(data, files) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
		data.updated_by = user.user_id;
		
		const formData: FormData = new FormData();

		for (let key in data) {
			if(null == data[key])
				data[key] = '';
			
            formData.append(key, data[key]);
        }
        
		for (let i=0; i<files.length; i++) {
			formData.append('files[' + i + ']', files[i]);
		}

		formData.append(this.constants.OTHERS.TOKEN, user.token);
		
		let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.ASSETS,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object for add medical history and send it to http service
     * @param data 
     */
    addNotes(data) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
        data.updated_by = user.user_id;
        data.token = user.token;
		
		let requestObject = {
			REQUEST_DATA: data,
			API_URL: this.constants.API_URLS.ASSETS,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
    }
}

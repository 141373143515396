import { Component, OnInit, Input } from '@angular/core';
import { CONSTANTS } from 'src/app/util/constants';

@Component({
    selector: 'app-medical-history-detail',
    templateUrl: './medical-history-detail.component.html',
    styleUrls: ['./medical-history-detail.component.css']
})
export class MedicalHistoryDetailComponent implements OnInit {

    @Input() selectedRecord: any;

    constructor(
        public constants: CONSTANTS
    ) { }

    ngOnInit() {
    }

}

import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CmsService } from 'src/app/services/cms.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

    content: any;

    constructor(
        private cmsServ: CmsService,
        public loaderServ: NgxUiLoaderService,
    ) { }

    ngOnInit() {
        this.loaderServ.start();
        this.cmsServ.getByName('Terms')
            .then(res => {
                this.loaderServ.stop();
                this.content = res.response[0].content;
            });
    }
}


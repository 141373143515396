import { Component, OnInit } from '@angular/core';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';
import { UserProfile } from '../../models/user-profiles';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

    userProfile: UserProfile;
    message: string;
    profileImage: string = '/assets/images/dummy-user.png';
    age: number;
    latestCategories: any;
    lang: string;

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        // this.lang = 'de';
        let loggedinUserDtls = this.sessionServ.getLoggedInUser();
        let loggedinuserId = loggedinUserDtls.id;
        this.userProfile = this.sessionServ.getLoggedInUser();
        this.userProfile = new UserProfile();

        this.userServ.getUserProfile(loggedinuserId)
        .then(res => {
            this.userProfile = new UserProfile(res.response);

            if(this.userProfile.image)
            this.profileImage = this.constants.SERVER.BASE + '/' + this.userProfile.image;

            console.log(this.userProfile);
        });

        this.userServ.getDashboardValues(this.constants.STATUS.ACTIVE)
            .then(res => {
                this.latestCategories = res.response.latest_record;
            });

        this.calculateAge();
    } 

    calculateAge() {
         let date_of_birth = Date.parse(this.userProfile.date_of_birth);
         
         if(this.userProfile.date_of_birth) {
            var timeDiff = Math.abs(Date.now() - date_of_birth);
            this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        }
    }

}

import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {
    public saveFamilyMemberBS = new BehaviorSubject(null);
    constructor(
      private constants: CONSTANTS,
      private httpServ: HttpService,
      private sessionServ: SessionService
    ) {
        this.constants = constants,
        this.httpServ = httpServ
    } 

    records() {
        let user = this.sessionServ.getUser();

        let requestObject = {
            API_URL: this.constants.API_URLS.USERS + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    recordsReports() {
        let user = this.sessionServ.getUser();
        // AGE: '&age=',
        // GENDER: '&gender=',
        // START_DATE: '&start_date=',
        // END_DATE: '&end_date=',
        // users/demographic-reports?page=1&age=&gender=&start_date=&end_date=&token=

        let requestObject = {
            API_URL: this.constants.API_URLS.USERS +  this.constants.API_URLS.REPORTS + this.constants.URL_VARIABLES.TOKEN + user.token
            + this.constants.URL_VARIABLES.PAGE + this.constants.URL_VARIABLES.AGE + this.constants.URL_VARIABLES.GENDER + 
            this.constants.URL_VARIABLES.START_DATE + this.constants.URL_VARIABLES.END_DATE ,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    recordsReportsMedical() {
        let user = this.sessionServ.getUser();
       
        let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
        let requestObject = {
            API_URL: this.constants.API_URLS.MEDICAL_HISTORY +  this.constants.API_URLS.REPORTS + this.constants.URL_VARIABLES.TOKEN + user.token
            + this.constants.URL_VARIABLES.PAGE + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id + this.constants.URL_VARIABLES.AGE + this.constants.URL_VARIABLES.GENDER + 
            this.constants.URL_VARIABLES.START_DATE + this.constants.URL_VARIABLES.END_DATE ,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    recordsReportsDental() {
        let user = this.sessionServ.getUser();
       
        let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
        let requestObject = {
            API_URL: this.constants.API_URLS.DENTAL_RECORDS +  this.constants.API_URLS.REPORTS + this.constants.URL_VARIABLES.TOKEN + user.token
            + this.constants.URL_VARIABLES.PAGE + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id + this.constants.URL_VARIABLES.AGE + this.constants.URL_VARIABLES.GENDER + 
            this.constants.URL_VARIABLES.START_DATE + this.constants.URL_VARIABLES.END_DATE + this.constants.URL_VARIABLES.PROCEDURE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    recordsReportsTreatment() {
        let user = this.sessionServ.getUser();
       
        let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
        let requestObject = {
            API_URL: this.constants.API_URLS.TREATMENTS +  this.constants.API_URLS.REPORTS + this.constants.URL_VARIABLES.TOKEN + user.token
            + this.constants.URL_VARIABLES.PAGE + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id + this.constants.URL_VARIABLES.AGE + this.constants.URL_VARIABLES.GENDER + 
            this.constants.URL_VARIABLES.START_DATE + this.constants.URL_VARIABLES.END_DATE + this.constants.URL_VARIABLES.MEDICATION_NAME,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    delete(id) {
        let user = this.sessionServ.getUser();

        let requestObject = {
            API_URL: this.constants.API_URLS.USERS + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    activedeactive(id, status) {
        let user = this.sessionServ.getUser();

        const formData: FormData = new FormData();
        let data : any;
        
        formData.append('data[status]', status);
        formData.append('data[userId]', id);
        formData.append('data[updated_by]', user.user_id);
        formData.append(this.constants.OTHERS.TOKEN, user.token);

        let requestObject = {
            REQUEST_DATA: formData,
            API_URL: this.constants.API_URLS.USERS + this.constants.API_URLS.CHANGE_STATUS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getLoggedInUserProfile(token) {
        let requestObject = {
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.GET_LOGGED_IN_USER_PROFILE + this.constants.URL_VARIABLES.TOKEN + token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    uploadImage(data) {
        let user = this.sessionServ.getUser();

        const formData: FormData = new FormData();

        formData.append(this.constants.OTHERS.ID, data['id']);
        formData.append(this.constants.OTHERS.FILE, data['file']);
        formData.append(this.constants.OTHERS.TOKEN, user.token);

        let requestObject = {
            REQUEST_DATA: formData,
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.UPDATE_PROFILE_IMAGE + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject, true);
    }

    saveProfile(data, file) {
        let user = this.sessionServ.getUser();

        const formData: FormData = new FormData();

        for (let key in data) {
            if(null == data[key])
                data[key] = '';
            
            formData.append('data[' + key + ']', data[key]);
        }
        formData.append(this.constants.OTHERS.FILE, file);
        formData.append(this.constants.OTHERS.TOKEN, user.token);

        let requestObject = {
            REQUEST_DATA: formData,
            API_URL: this.constants.API_URLS.USER_PROFILES,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    updateProfile(profile, id) {
        let user = this.sessionServ.getUser();
        delete profile['user_id'];
        let requestObject = {
            REQUEST_DATA: {data: profile},
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.PUT
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getFamilyMembers() {
        let user = this.sessionServ.getUser();

        let requestObject = {
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.GET_BY_PARENT_ID + this.constants.API_URLS.ROOT + user.user_id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.DEVICE_TYPE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getUserProfile(id: number) {
        let user = this.sessionServ.getUser();

        let requestObject = {
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeMember(id: number) {
        let user = this.sessionServ.getUser();
        
        let requestObject = {
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.USER_ID + user.user_id,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getDashboardValues(status: string, id: number = null) {
        let user = this.sessionServ.getUser();
        if(!id)
            id = user.id;
        
        let requestObject = {
            API_URL: this.constants.API_URLS.USER_PROFILES + this.constants.API_URLS.GET_DASHBOARD_VALUES + this.constants.API_URLS.ROOT + id + this.constants.API_URLS.ROOT + status + this.constants.URL_VARIABLES.TOKEN + user.token,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        console.log(requestObject);
        return this.httpServ.sendRequest(requestObject);
    }

    search(searchString: string) {
        let user = this.sessionServ.getUser();
        
        let requestObject = {
            API_URL: this.constants.API_URLS.SEARCH + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.SEARCH_PARAMETER + searchString + this.constants.URL_VARIABLES.CREATED_BY + user.user_id,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveContact(data: any) {
		let user = this.sessionServ.getUser();

        const formData: FormData = new FormData();
        
        for (let key in data) {
            if(null == data[key])
                data[key] = '';
            
            formData.append('data[' + key + ']', data[key]);
        }
        formData.append('data[userId]', user.user_id);
        formData.append(this.constants.OTHERS.TOKEN, user.token);

        let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.CONTACT_US,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileDataService {

    private nameChangedSource = new Subject<string>();
    private phoneChangedSource = new Subject<string>();
    private imageChangedSource = new Subject<string>();
    private loggedInUserNameChangedSource = new Subject<string>();
    private loggedInUserImageChangedSource = new Subject<string>();
    private memberchangedSource = new Subject<any>();

    public nameChanged$ = this.nameChangedSource.asObservable();
    public phoneChanged$ = this.phoneChangedSource.asObservable();
    public imageChanged$ = this.imageChangedSource.asObservable();
    public loggedInUserNameChanged$ = this.loggedInUserNameChangedSource.asObservable();
    public loggedInUserImageChanged$ = this.loggedInUserImageChangedSource.asObservable();
    public memberChanged$ = this.memberchangedSource.asObservable();

    constructor() { }

    changeName(name: string) {
        this.nameChangedSource.next(name);
    }

    changeImage(image: string) {
        this.imageChangedSource.next(image);
    }

    changeLoggedInUserName(name: string) {
        this.loggedInUserNameChangedSource.next(name);
    }

    changeLoggedInUserImage(image: string) {
        this.loggedInUserImageChangedSource.next(image);
    }

    changeMember(member: any) {
        this.memberchangedSource.next(member);
    }

    changePhone(phone: string) {
        this.phoneChangedSource.next(phone);
    }
}

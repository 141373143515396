import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { DentalRecordService } from '../../../services/dental-record.service';
import { UserProfile } from '../../../models/user-profiles';
import { TreatmentService } from 'src/app/services/treatment.service';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';
import { AssetService } from 'src/app/services/asset.service';
import { DentalRecord } from 'src/app/models/dental-record';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {

    userProfile: UserProfile;
    message: string;
    category: string;
    records: any = null;
    reportTitle: string;
    selectedRecord: any;
    selectedTreatment: any;
    selectedMedicalHistory: any;
    lang: string;
    dentalRecord: DentalRecord;
    isVisible: boolean = false;
    files: File [] = [];    
    treatmentDetails: any = [];
    teethStatuses: any = [];
    categoryType: string;

    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
    @ViewChild('pdfInput', {static: false}) pdfInput: ElementRef;

    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public dentalRecordServ: DentalRecordService,
        public treatmentServ: TreatmentService,
        public medicalHistoryServ: MedicalHistoryService,
        public assetServ: AssetService,
        private sessionServ: SessionService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() { 
        this.lang = this.sessionServ.getLang();
    }

    getCategoryValues() {
        this.isVisible = false;
        this.reportTitle = '';
        this.files=[];
        if(this.fileInput) {
            this.fileInput.nativeElement.value = null;
            this.reportTitle = '';
        }
        console.log('pdf input category=>'+this.pdfInput);

        if(this.pdfInput) {
            this.pdfInput.nativeElement.value = null;
            // this.reportTitle = '';
        }
        
       
        this.dentalRecord = null;
        this.selectedMedicalHistory = null;
        this.selectedTreatment = null;
        this.loaderServ.start();
        
        if(this.constants.CATEGORY_TITLES_LABEL.MEDICAL_HISTORY[this.lang]['MEDICAL HISTORY'] == this.category)
            this.medicalHistoryServ.records()
                .then(res => {
                    this.loaderServ.stop();
                    this.records = res.response;
                    this.categoryType = "Medical History";
                });
        else if(this.constants.CATEGORY_TITLES_LABEL.DENTAL_RECORD[this.lang]['DENTAL RECORD'] == this.category)
            this.dentalRecordServ.records()
                .then(res => {
                    this.loaderServ.stop();
                    this.records = res.response;
                    this.categoryType = "Dental Record";
                });
        else if(this.constants.CATEGORY_TITLES_LABEL.TREATMENT_IMMUNIZATION[this.lang]['TREATMENT AND IMMUNIZATION'] == this.category)
            this.treatmentServ.records()
                .then(res => {
                    this.loaderServ.stop();
                    this.records = res.response;
                    this.categoryType = "Treatment and Immunization";
                });
    }

    prepareData() {
        this.isVisible = true;
        this.reportTitle = '';
        this.files=[];
        
        if(this.pdfInput) {
            this.pdfInput.nativeElement.value = null;
            // this.reportTitle = '';
        }
        if(this.constants.CATEGORY_TITLES_LABEL.MEDICAL_HISTORY[this.lang]['MEDICAL HISTORY'] == this.category)
            this.selectedMedicalHistory = this.selectedRecord;
        else if(this.constants.CATEGORY_TITLES_LABEL.TREATMENT_IMMUNIZATION[this.lang]['TREATMENT AND IMMUNIZATION'] == this.category)
            this.selectedTreatment = this.selectedRecord;
        else if(this.constants.CATEGORY_TITLES_LABEL.DENTAL_RECORD[this.lang]['DENTAL RECORD'] == this.category) {
            this.dentalRecord = new DentalRecord(this.selectedRecord);
                
            this.treatmentDetails = this.selectedRecord.treatmentDetails;                       

            // for(let i=0; i<this.constants.TEETH_PROCEDURES[this.lang].length; i++) {
            //     if(this.dentalRecord[this.constants.TEETH_PROCEDURES[this.lang][i].title])
            //         this.dentalRecord[this.constants.TEETH_PROCEDURES[this.lang][i].title] = JSON.parse(this.dentalRecord[this.constants.TEETH_PROCEDURES[this.lang][i].title])
            // }
            for(let i=0; i<this.constants.PROCEDURES.length; i++) {
                if(this.dentalRecord[this.constants.PROCEDURES[i][this.lang].title])
                    this.dentalRecord[this.constants.PROCEDURES[i][this.lang].title] = JSON.parse(this.dentalRecord[this.constants.PROCEDURES[i][this.lang].title])
            }
            
            for(let i=11; i<=85; i++) {                            
                if(!this.teethStatuses[i])
                        this.teethStatuses[i] = [];
                this.teethStatuses[i]['color'] = '#ffffff';
                this.teethStatuses[i]['code'] = '';                        
            }

            if(this.dentalRecord.teeth_status) {
                this.dentalRecord.teeth_status = JSON.parse(this.dentalRecord.teeth_status);
                if(this.dentalRecord.teeth_status['fixed'])
                    for(let i=0; i<this.dentalRecord.teeth_status['fixed'].length; i++)
                        this.teethStatuses[this.dentalRecord.teeth_status['fixed'][i].tooth] = this.dentalRecord.teeth_status['fixed'][i];
            }
            
            this.dentalRecord['teethStatuses'] = this.teethStatuses;
            this.dentalRecord['dentalRecordAssets'] = this.selectedRecord.assetDetails;
            this.dentalRecord['treatmentDetails'] = this.selectedRecord.treatmentDetails;
        }
    }

    handleReportFileInput(files: FileList) {        
        this.reportTitle = '';
        for(let i=0; i<files.length; i++) {
            this.files[i] = files.item(i);
            this.reportTitle += files[i].name + ', ';
        }

        this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
    }

    showMessage(message: string, status: number) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == status)
            this.snackBar.open(message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    upload(form: NgForm) {        
        if(form.valid) {
            if(this.files.length <= 0)
                this.showMessage(this.constants.FILE_FORMAT_ERRORS[this.lang].SELECT_IMAGE, 500);
            else {
                let flag = true;
                for(let i=0; i<this.files.length; i++) {
                    if(this.constants.FILE_FORMATS.DOC.indexOf(this.files[i].type.toLowerCase()) < 0) {
                        flag = false;
                        break;
                    }
                }
                
                if(!flag) {
                    this.showMessage(this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT, 500);
                    this.reportTitle = '';
                    this.pdfInput.nativeElement.value = null;
                    this.files=[];
                }  
                else {
                    let data = {
                        'category': this.categoryType,
                        'category_id': this.selectedRecord.id,
                        'type': 'pdf'
                    };

                    this.loaderServ.start();
                    
                    this.assetServ.upload(data, this.files)
                        .then(response => {  
                            console.log(form); 
                            this.loaderServ.stop();               
                            this.showMessage(response.message, response.status);
                            // form.reset();
                            if(this.pdfInput) {
                                this.pdfInput.nativeElement.value = null;
                                this.reportTitle = '';
                                this.files=[];
                            }
                        });
                }
            }
        }
    }
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CONSTANTS } from '../../util/constants';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { SessionService } from '../../services/session.service';
import { ProfileDataService } from '../../services/profile-data.service';
import { DataService } from 'src/app/services/data.service';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';
// import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
    selector: 'app-user-panel',
    templateUrl: './user-panel.component.html',
    styleUrls: ['./user-panel.component.css', '../../../assets/styles/responsive.css']
})
export class UserPanelComponent implements OnInit, OnDestroy{

    nameSubscription: Subscription;
    imageSubscription: Subscription;
    loggedInUserNameSubscription: Subscription;
    loggedInUserImageSubscription: Subscription;
    phoneSubscription: Subscription;
    removedMemberSubscription: Subscription;
    user: any;
    loggedInUser: any;
    profileImage: string = '/assets/images/dummy-user.png';
    loggedInUserProfileImage: string = '/assets/images/dummy-user.png';
    hideContact: boolean = true;
    currentMember: any;
    user_emergency_contact_number: any;
    searchString: string;

    isActive: any = [];
    members: any = [];
    tooltipContent : string;
    categoryCurrentDates: any;
    currentLang: string = '';
    isHidden: boolean =  true;
    current_segment:string = 'dashboard';

    constructor(
        private router: Router,
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public profileDataServ: ProfileDataService,
        public userServ: UserService,
        public dataServ: DataService,
        public dialog: MatDialog,
        public medicalHistoryServ: MedicalHistoryService,
        // public loaderServ: NgxUiLoaderService,
    ) { }

    ngOnInit() {
        this.userServ.saveFamilyMemberBS.subscribe(()=>{
            this.getMembers();
        })

        this.currentLang = this.sessionServ.getLang();

        this.menuScript();

        this.user = this.sessionServ.getUser();
        this.loggedInUser = this.sessionServ.getLoggedInUser();

        this.nameSubscription = this.profileDataServ.nameChanged$.subscribe(
            name => {
                this.user.name = name;

                if(this.user.id == this.loggedInUser.id)
                    this.loggedInUser.name = name;
        });

        this.imageSubscription = this.profileDataServ.imageChanged$.subscribe(
            image => {
                this.profileImage = this.constants.SERVER.BASE + '/' + image;
                // this.user.image = this.constants.SERVER.BASE + '/' + image; //29.06.2020
                this.user.image =  image;

                if(this.user.id == this.loggedInUser.id) {
                    this.loggedInUser.image = this.constants.SERVER.BASE + '/' + image;
                    this.loggedInUserProfileImage = this.constants.SERVER.BASE + '/' + image;
                }

                
        });

        this.loggedInUserNameSubscription = this.profileDataServ.loggedInUserNameChanged$.subscribe(
            name => {
                this.loggedInUser.name = name;

                if(this.user.id == this.loggedInUser.id)
                    this.user.name = name;
        });

        this.phoneSubscription = this.profileDataServ.phoneChanged$.subscribe(
            phone => {
                this.user_emergency_contact_number = phone;

                if(this.user.id == this.loggedInUser.id){
                    this.user_emergency_contact_number = phone;
                }
       
        });

        this.loggedInUserImageSubscription = this.profileDataServ.loggedInUserImageChanged$.subscribe(
            image => {
                this.loggedInUserProfileImage = this.constants.SERVER.BASE + '/' + image;
                this.loggedInUser.image = this.constants.SERVER.BASE + '/' + image;
                if(this.user.id == this.loggedInUser.id) {
                    // this.user.image = image; //29.06.2020
                    this.user.image = this.constants.SERVER.BASE + '/' + image; 
                    this.profileImage = this.constants.SERVER.BASE + '/' + image; 
                }
        });
        this.removedMemberSubscription = this.profileDataServ.memberChanged$.subscribe(
            member => {
                this.getMembers();
        });
        
        if(this.user.image)
            this.profileImage = this.constants.SERVER.BASE + '/' + this.user.image;
        if(this.loggedInUser.image)
            this.loggedInUserProfileImage = this.constants.SERVER.BASE + '/' + this.loggedInUser.image;

        if(this.user.primary_emergency_contact_number) {
            this.user_emergency_contact_number = this.user.primary_emergency_contact_number;
        } else if(this.user.secondary_emergency_contact_number) {
            this.user_emergency_contact_number = this.user.secondary_emergency_contact_number;
        } else {
            this.user_emergency_contact_number = "";
        }

        if(this.router.parseUrl(this.router.url).root.children['primary'].segments[1]) {
            this.current_segment = this.router.parseUrl(this.router.url).root.children['primary'].segments[1].path;
        }
        

        if(this.current_segment == 'dashboard' || this.current_segment == ''){
            this.isActive['dashboard'] = true;
        } else {
            this.isActive['dashboard'] = false;
        }
        if(this.current_segment == 'family'){
            this.isActive['family'] = true;
        } else {
            this.isActive['family'] = false;
        }
        if(this.current_segment == 'deleted'){
            this.isActive['deleted'] = true;
        } else {
            this.isActive['deleted'] = false;
        }
        if(this.current_segment == 'contact'){
            this.isActive['contact'] = true;
        } else {
            this.isActive['contact'] = false;
        }
        if(this.current_segment == 'about'){
            this.isActive['about'] = true;
        } else {
            this.isActive['about'] = false;
        }
        if(this.current_segment == 'terms'){
            this.isActive['terms'] = true;
        } else {
            this.isActive['terms'] = false;
        }
        if(this.current_segment == 'policy'){
            this.isActive['policy'] = true;
        } else {
            this.isActive['policy'] = false;
        }
        if(this.current_segment == 'support'){
            this.isActive['support'] = true;
        } else {
            this.isActive['support'] = false;
        }
        
        // this.isActive['dashboard'] = true;
        // this.isActive['family'] = false;
        // this.isActive['deleted'] = false;
        // this.isActive['contact'] = false;
        // this.isActive['about'] = false;
        // this.isActive['terms'] = false;
        // this.isActive['policy'] = false;
        // this.isActive['support'] = false;

        
    }

    getMembers() {
        this.userServ.getFamilyMembers()
            .then(res => {
                this.members = res.response;
            });
    }


    keyUpFunction(event:any) {
        if(event.keyCode == 13){
         this.search();
        }
     }


    nextMember() {      
        // this.loaderServ.start();  
        this.currentMember = this.members[0];
        for(let i=0; i<this.members.length; i++) { 
            if(this.user.id == this.members[i].id) {
                let x = i == this.members.length - 1 ? 0 : i+1;
                this.currentMember = this.members[x];
                break;
            }
        }

        let user = this.user;
        if(this.currentMember) {
            this.user = this.currentMember;
            this.user.token = user.token;
            this.user.email = user.email;
            this.user.type = user.type;
            this.user.user_id = user.user_id;
            this.user.language_id = user.language_id;

            // console.log('user image=>'+this.user.image);
            if(this.user.image) {
                // var userImage = this.user.image.split(this.constants.SERVER.BASE+'/');
                // if(userImage[1] === undefined) {
                //     this.profileImage = this.constants.SERVER.BASE + '/' + userImage[0];
                // } else {
                //     this.profileImage = this.constants.SERVER.BASE + '/' + userImage[1];
                // }
                this.profileImage = this.constants.SERVER.BASE + '/' + this.user.image;
            }  
            else {
                this.profileImage = '/assets/images/dummy-user.png';
            }
                
            
            if(this.user.primary_emergency_contact_number) {
                this.user_emergency_contact_number = this.currentMember.primary_emergency_contact_number;
            } else if(this.user.secondary_emergency_contact_number) {
                this.user_emergency_contact_number = this.currentMember.secondary_emergency_contact_number;
            } else {
                this.user_emergency_contact_number = "";
            }
            // console.log('profile image befor get profile url =>'+this.profileImage);
            
            // this.loaderServ.stop();

            this.userServ.getUserProfile(this.currentMember.id)
            .then(res => {
                if(res.response.image) {
                    this.profileImage = this.constants.SERVER.BASE + '/' + res.response.image;
                }  
                else {
                    this.profileImage = '/assets/images/dummy-user.png';
                }
            });
            
            this.sessionServ.setUser(this.user);
            // console.log('profile image after get profile url =>'+this.profileImage);
            
        }

       

        this.router.navigateByUrl('/user');
    }

    previousMember() {        
        // this.loaderServ.start();
        this.currentMember = this.members[0];
        for(let i=0; i<this.members.length; i++) {            
            if(this.user.id == this.members[i].id) {
                let x = i == 0 ? this.members.length - 1 : i-1;
                this.currentMember = this.members[x];
                break;
            }
        }
        
        let user = this.user;
        if(this.currentMember) {
            this.user = this.currentMember;
            this.user.token = user.token;
            this.user.email = user.email;
            this.user.type = user.type;
            this.user.user_id = user.user_id;
            this.user.language_id = user.language_id;
            console.log(this.user);
            if(this.user.image) {
                // var userImage = this.user.image.split(this.constants.SERVER.BASE+'/');
                // if(userImage[1] === undefined) {
                //     this.profileImage = this.constants.SERVER.BASE + '/' + userImage[0];
                // } else {
                //     this.profileImage = this.constants.SERVER.BASE + '/' + userImage[1];
                // }
                this.profileImage = this.constants.SERVER.BASE + '/' + this.user.image;
                
            }
            else {
                this.profileImage = '/assets/images/dummy-user.png';
            }
                

            if(this.user.primary_emergency_contact_number) {
                this.user_emergency_contact_number = this.currentMember.primary_emergency_contact_number;
            } else if(this.user.secondary_emergency_contact_number) {
                this.user_emergency_contact_number = this.currentMember.secondary_emergency_contact_number;
            } else {
                this.user_emergency_contact_number = "";
            }

            this.userServ.getUserProfile(this.currentMember.id)
            .then(res => {
                if(res.response.image) {
                    this.profileImage = this.constants.SERVER.BASE + '/' + res.response.image;
                }  
                else {
                    this.profileImage = '/assets/images/dummy-user.png';
                }
            });
            
            this.sessionServ.setUser(this.user);
            // this.loaderServ.stop();
        }
        
        this.router.navigateByUrl('/user');
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.nameSubscription.unsubscribe();
        this.imageSubscription.unsubscribe();
        this.loggedInUserNameSubscription.unsubscribe();
        this.loggedInUserImageSubscription.unsubscribe();
        this.removedMemberSubscription.unsubscribe();
    }

    logout() {
        this.loginServ.logout(this.sessionServ.getUser())
            .then(res => {
                this.sessionServ.clearSession();
                this.router.navigateByUrl('/');
            });
    }

    onLanguageChange(lang: string) {
        console.log(lang);
        const url = this.router.url;
        console.log('langurl:'+url);
        if(lang == 'en') {
            this.sessionServ.setLang('en');
            window.location.href = this.constants.SERVER.BASE +  url;
        } else {
            this.sessionServ.setLang(lang);
            window.location.href = this.constants.SERVER.BASE + '/' + lang + url;
        }
        
        //  console.log(window.location.href);
    }
    
    changeActive(menuItem: string) {
        for (var key in this.isActive) {
            this.isActive[key] = false;
            // console.log('menuitem=>'+menuItem+'key=>'+key);
            if(menuItem == key) {
                this.isActive[key] = true;
            }
        }
    }

    showContact() {
        this.hideContact = !this.hideContact;
    }

    search() {
        if(this.searchString != '') {
            this.dataServ.updateSearchString(this.searchString);
            this.router.navigateByUrl('/user/search');
        }
        
    }

    downloadReport() {
        this.medicalHistoryServ.generatePdf()
            .then(res => {
                window.location.href=this.constants.SERVER.BASE+'/'+res.response;
            });
    }
    openSearchbar(){
        if(this.isHidden == true) {
            this.isHidden = false;
        }
        else{
            this.isHidden  = true;
        }
    }

    menuScript() {
        var that = this;

        $(document).ready(() => {
            /* -------left-nav-bar-mobile-view------ */
            $('#dismiss, .overlay').on('click', function () {
                $('#sidebar').removeClass('active');
                $('.overlay').removeClass('active');
            });

            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').addClass('active');
                $('.overlay').addClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });

            /* -------left-nav-bar-mobile-view------ */
            /* -------my-account-menu------ */
            $('#show-dropdown').click(function () {
                $('.dropdown-setting').slideToggle();
                $('.dropdown-overlay').toggle();
                $('.add-asset-dashboard').removeClass('add-asset-dashboard-show');

            });

            $('.dropdown-submenu').click(function () {
                $('.dropdown-setting').slideToggle();
                $('.dropdown-overlay').hide();
            });

            /* -------my-account-menu------ */
            /* -------dashboard-popup------ */
            $('#show-dashboard-popup').click(function () {
                $('.add-asset-dashboard').toggleClass("add-asset-dashboard-show");
                $('.rotate-cross').toggleClass("rotate-cross-rotate");
                $('.dropdown-overlay').toggle();
                $('.dropdown-setting').slideUp();
            });
            $('.dropdown-overlay').click(function () {
                $(this).hide();
                $('.dropdown-setting').slideUp();
                $('.add-asset-dashboard').removeClass('add-asset-dashboard-show');
                $('.rotate-cross').removeClass("rotate-cross-rotate");
               
            });
            // -------language-----
            $(".select-language").click(function() {
                $(".language").slideToggle();
                $(".mobile-language").slideToggle();
            });
            /* -------dashboard-popup------ */
            // Dropdown toggle
            $('.dropdown-toggle').click(function () {
                $(this).next('.dropdown-menu').toggle();
            });

            $(document).click(function (e) {
                var target = e.target;
                if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle'))
                    $('.dropdown-menu').hide();
            });
            // Dropdown toggle

            $(".dropdown-submenu-lower").click(function () {
                $('.dropdown-overlay').hide();
                $('.add-asset-dashboard').removeClass('add-asset-dashboard-show');
                $('.rotate-cross').removeClass('rotate-cross-rotate');

            });

            $(".hide_overlay").click(function () {
                $('.overlay').removeClass("active");
                $('#sidebar').removeClass('active');
            });
        });
    }

}

import { Validator, NG_VALIDATORS, AbstractControl } from "@angular/forms";
import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appConfrimEqualValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ConfirmEqualVlaidatorDirective,
        multi: true
    }]
})
export class ConfirmEqualVlaidatorDirective implements Validator {
    @Input() appConfrimEqualValidator: string;

    validate(control: AbstractControl): {[key: string]: any} | null {
        const controlToCompare = control.parent.get(this.appConfrimEqualValidator);

        if(controlToCompare && controlToCompare.value != control.value) {
            return {'notEqual': true};
        }

        return null;
    }
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthUserService } from './services/auth-user.service';
import { AuthAdminService } from './services/auth-admin.service';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { UserComponent } from './components/user/user.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserPanelComponent } from './components/user-panel/user-panel.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { AddBasicInfoComponent } from './components/user-profile/add-basic-info/add-basic-info.component';
import { EditProfileComponent } from './components/user-profile/edit-profile/edit-profile.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { AddMedicalHistoryComponent } from './components/medical-history/add-medical-history/add-medical-history.component';
import { EditMedicalHistoryComponent } from './components/medical-history/edit-medical-history/edit-medical-history.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MyFamilyComponent } from './components/my-family/my-family.component';
import { DentalRecordComponent } from './components/dental-record/dental-record.component';
import { AddDentalRecordComponent } from './components/dental-record/add-dental-record/add-dental-record.component';
import { EditDentalRecordComponent } from './components/dental-record/edit-dental-record/edit-dental-record.component';
import { MemberProfileComponent } from './components/my-family/member-profile/member-profile.component';
import { AddFamilyMemberComponent } from './components/my-family/add-family-member/add-family-member.component';
import { EditMemberProfileComponent } from './components/my-family/edit-member-profile/edit-member-profile.component';
import { TreatmentComponent } from './components/treatment/treatment.component';
import { AddTreatmentComponent } from './components/treatment/add-treatment/add-treatment.component';
import { EditTreatmentComponent } from './components/treatment/edit-treatment/edit-treatment.component';
import { ShowTreatmentComponent } from './components/treatment/show-treatment/show-treatment.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { EditMyProfileComponent } from './components/my-profile/edit-my-profile/edit-my-profile.component';
import { ImageComponent } from './components/assets/image/image.component';
import { PdfComponent } from './components/assets/pdf/pdf.component';
import { NotesComponent } from './components/assets/notes/notes.component';
import { ShowDentalRecordComponent } from './components/dental-record/show-dental-record/show-dental-record.component';
import { RecentlyDeletedComponent } from './components/recently-deleted/recently-deleted.component';
import { DeletedMedicalHistoriesComponent } from './components/medical-history/deleted-medical-histories/deleted-medical-histories.component';
import { DeletedTreatmentsComponent } from './components/treatment/deleted-treatments/deleted-treatments.component';
import { DeletedDentalRecordsComponent } from './components/dental-record/deleted-dental-records/deleted-dental-records.component';
import { MeddraComponent } from './components/meddra/meddra.component';
import { CmsComponent } from './components/cms/cms.component';
import { AddCmsComponent } from './components/cms/add-cms/add-cms.component';
import { AboutComponent } from './components/about/about.component';
import { TermsComponent } from './components/terms/terms.component';
import { PolicyComponent } from './components/policy/policy.component';
import { SupportComponent } from './components/support/support.component';
import { SearchComponent } from './components/search/search.component';
import { ShowMedicalHistoryComponent } from './components/medical-history/show-medical-history/show-medical-history.component';
import { ContactComponent } from './components/contact/contact.component';
import { UserReportComponent } from './components/report/user-report/user-report.component';
import { DentalReportComponent } from './components/report/dental-report/dental-report.component';
import { MedicalReportComponent } from './components/report/medical-report/medical-report.component';
import { TreatmentReportComponent } from './components/report/treatment-report/treatment-report.component';
import { MedicineComponent } from './components/medicine/medicine.component';

const routes: Routes = [
    {
        path: '',   
        component: LoginComponent     
    },
    {
        path: 'register',   
        component: RegisterComponent     
    },
    {
        path: 'forgot-password',   
        component: ForgotPasswordComponent     
    },
    {
        path: 'reset-password',   
        component: ResetPasswordComponent     
    },
    {
        path: 'add-basic-info',
        component: AddBasicInfoComponent
    },
    {
        path: 'user',   
        component: UserPanelComponent,
        canActivate: [AuthUserService],
        children: [
            {
                path: '',
                component: UserDashboardComponent
            },
            {
                path: 'my-profile',
                component: MyProfileComponent
            },
            {
                path: 'edit-my-profile',
                component: EditMyProfileComponent
            },
            {
                path: 'profile',
                component: UserProfileComponent
            },
            {
                path: 'dental-records',
                component: DentalRecordComponent
            },
            {
                path: 'add-dental-record',
                component: AddDentalRecordComponent
            },
            {
                path: 'show-dental-record',
                component: ShowDentalRecordComponent
            },
            {
                path: 'edit-dental-record',
                component: EditDentalRecordComponent
            },
            {
                path: 'edit-profile/:id',
                component: EditProfileComponent
            },
            {
                path: 'my-family',
                component: MyFamilyComponent
            },
            {
                path: 'member-profile',
                component: MemberProfileComponent
            },
            {
                path: 'add-family-member',
                component: AddFamilyMemberComponent
            },
            {
                path: 'medical-history',
                component: MedicalHistoryComponent,
            },
            {
                path: 'show-medical-history',
                component: ShowMedicalHistoryComponent,
            },
            {
                path: 'add-medical-history',
                component: AddMedicalHistoryComponent,
            },
            {
                path: 'edit-medical-history',
                component: EditMedicalHistoryComponent,
            },
            {
                path: 'edit-member-profile',
                component: EditMemberProfileComponent,
            },
            {
                path: 'treatment',
                component: TreatmentComponent,
            },
            {
                path: 'show-treatment',
                component: ShowTreatmentComponent,
            },
            {
                path: 'add-treatment',
                component: AddTreatmentComponent,
            },
            {
                path: 'edit-treatment',
                component: EditTreatmentComponent,
            },
            {
                path: 'dental-record',
                component: DentalRecordComponent,
            },
            {
                path: 'add-dental-record',
                component: AddDentalRecordComponent,
            },
            {
                path: 'edit-dental-record',
                component: EditDentalRecordComponent,
            },
            {
                path: 'images',
                component: ImageComponent,
            },
            {
                path: 'pdf',
                component: PdfComponent,
            },
            {
                path: 'notes',
                component: NotesComponent,
            },
            {
                path: 'recently-deleted',
                component: RecentlyDeletedComponent,
            },
            {
                path: 'deleted-medical-histories',
                component: DeletedMedicalHistoriesComponent,
            },
            {
                path: 'deleted-treatments',
                component: DeletedTreatmentsComponent,
            },
            {
                path: 'deleted-dental-records',
                component: DeletedDentalRecordsComponent,
            },
            {
                path: 'about-us',
                component: AboutComponent,
            },
            {
                path: 'terms',
                component: TermsComponent,
            },
            {
                path: 'policy',
                component: PolicyComponent,
            },
            {
                path: 'support',
                component: SupportComponent,
            },
            {
                path: 'search',
                component: SearchComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
        ]         
    },
    {
        path: 'admin',   
        component: AdminComponent,
        canActivate: [AuthAdminService],
        children: [
            {
                path: 'users',
                component: UserComponent
            },
            {
                path: 'meddra',
                component: MeddraComponent
            },
            {
                path: 'medicine',
                component: MedicineComponent
            },
            {
                path: 'cms',
                component: CmsComponent
            },
            {
                path: 'add-cms',
                component: AddCmsComponent
            },
            {
                path: 'support',
                component: SupportComponent,
            },
            {
                path: 'userreports',
                component: UserReportComponent
            },
            {
                path: 'medicalreports',
                component: MedicalReportComponent
            },
            {
                path: 'treatmentreports',
                component: TreatmentReportComponent
            },
            {
                path: 'dentalreports',
                component: DentalReportComponent
            },
        ]
    },

    {
		path: "**",
		redirectTo: "/"
	}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule{}

import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class MeddraService {
    constructor(
		private constants: CONSTANTS,
		private httpServ: HttpService,
		private sessionServ: SessionService
	) {
		this.constants = constants,
		this.httpServ = httpServ,
		this.sessionServ = sessionServ
	};

    /**
     * Prepares request object for add medical history and send it to http service
     * @param data 
     */
    add(data: any, file: any) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
		data.updated_by = user.user_id;
		
		const formData: FormData = new FormData();

		for (let key in data) {
			if(null == data[key])
				data[key] = '';
			
            formData.append(key, data[key]);
        }
                
        formData.append('file', file);		
        formData.append(this.constants.OTHERS.TOKEN, user.token);
		
		let requestObject = {
			REQUEST_DATA: formData,
			API_URL: this.constants.API_URLS.MEDDRA_PTS_LLTS,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
    }

	/**
	 * Get Meddra Data list
	 */
	getMeddraData(type: string, name: string) {
		let user = this.sessionServ.getUser();
		// let lang = user.language_id;
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
			// if(!lang)
		// 	lang = 1;

		let requestObject = {
			API_URL: this.constants.API_URLS.MEDICAL_HISTORY_MEDDRA_LIST + this.constants.URL_VARIABLES.TYPE + type + this.constants.URL_VARIABLES.NAME + name + this.constants.URL_VARIABLES.CREATED_BY + user.user_id + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
    }
    
    /**
	 * Get Language list
	 */
	getLanguages() {
        let user = this.sessionServ.getUser();
        
		let requestObject = {
			API_URL: this.constants.API_URLS.GET_LANGUAGES + this.constants.URL_VARIABLES.TOKEN + user.token,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
	}
}

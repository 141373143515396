export class Cms {
    id: number;    
    language_id: number;
    name: string;
    content: string;
    created_by: number;
    updated_by: number;
    
    constructor(cms: Cms = null) {
        if(cms) {
            this.id = cms.id;
            this.language_id = cms.language_id;
            this.name = cms.name;
            this.content = cms.content;
            this.created_by = cms.created_by;
            this.updated_by = cms.updated_by;           
        }
    }
}

import { Component, OnInit} from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { TreatmentService } from '../../../services/treatment.service';
import { DataService } from '../../../services/data.service';
import { Treatment } from '../../../models/treatment';
import { UserProfile } from '../../../models/user-profiles';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-edit-treatment',
    templateUrl: './edit-treatment.component.html',
    styleUrls: ['./edit-treatment.component.css']
})
export class EditTreatmentComponent implements OnInit {

    id: number;
    treatment: Treatment;
    userProfile: UserProfile;
    message: string;
    medicineCtrl: FormControl;    
    nameCtrl: FormControl;    
    frequencyCtrl: FormControl;    
    measureCtrl: FormControl;    
    filteredMedicines: any;
    filteredNames: any;
    filteredFrequencies: any;
    filteredMeasures: any;
    medicineId: number;
    reportTitle: string = '';
    medicineValue: string;
    lang: string;
    maxDate: Date;
    treatmentAssets: any;
    assetLength: number;
    files: File [] = [];
    medicines: any = [];
    names: string[] = [];
    hideReport: boolean[] = [];

    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public treatmentServ: TreatmentService,
        private snackBar: MatSnackBar,
        private dataServ: DataService,
        private sessionServ: SessionService,
        private router: Router
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        // this.lang = 'zh';
        if(this.lang == '') {
            this.lang = 'en';
        }

        this.treatment = new Treatment();

        this.medicineCtrl = new FormControl();
        this.nameCtrl = new FormControl();
        this.frequencyCtrl = new FormControl();
        this.measureCtrl = new FormControl();

        this.dataServ.id.subscribe(id => {
            this.id = id; 
            if(!this.id)
                this.router.navigateByUrl('/user/treatment');
            else {
                this.treatmentServ.find(this.id)
                    .then(res => {
                        let treatment = res.response[0];
                        this.treatment = new Treatment(treatment);
                        this.treatment.name = treatment.medicine.name;
                        this.medicineId = this.treatment.medicine_id;

                        this.treatmentAssets = res.response[0].assetDetails;

                        for(let i=0; i<this.treatmentAssets.length; i++)
                            this.hideReport[this.treatmentAssets[i].id] = false;

                        this.treatmentServ.getMedicines()
                            .then(res => {
                                this.medicines = res.response.data;                

                                var flags = [];
                                for(let i=0; i<this.medicines.length; i++) {
                                    if(flags[this.medicines[i].name]) 
                                        continue;
                                        
                                    flags[this.medicines[i].name] = true;
                                    this.names.push(this.medicines[i].name);                
                                }

                                this.treatment.composition = treatment.medicine.composition;
                                this.treatment.brand_name = treatment.medicine.name;
                                // console.log('treatment composition=>'+this.treatment.composition);
                                this.medicineCtrl = new FormControl(treatment.medicine);
                                this.nameCtrl = new FormControl(treatment.medicine.name); 
                                this.frequencyCtrl = new FormControl(treatment.frequency);
                                this.measureCtrl = new FormControl(treatment.dose_measure);
                                // this.frequencyCtrl = new FormControl(this.constants.FREQUENCY[0][this.lang][treatment.frequency]);
                                // this.measureCtrl = new FormControl(this.constants.MEASURES[0][this.lang][treatment.dose_measure]);
                                this.medicineValue = this.treatment.composition;
                                
                               
                                this.setMedicineCtrl();
                                this.setNameCtrl();
                                this.setMeasureCtrl();
                                this.setFrequencyCtrl();

                                // added on 8th-july-2020
                                this.medicineSelected();
                               
                            });                        
                    });
                
            }            
        });
    }

    getCompositionName(data) {
        //     console.log("Value",data);
            if(data) {
                this.treatmentServ.getMedicinesByName(data)
                    .then(res => {
                        this.medicines = res.response.data; 
                        // console.log(this.medicines);               
    
                        if(this.medicines) {
                            var flags = [];
                            for(let i=0; i<this.medicines.length; i++) {
                                if(flags[this.medicines[i].name]) 
                                    continue;
                                    
                                flags[this.medicines[i].name] = true;
                                this.names.push(this.medicines[i].name);                
                            }
                        }
                        
    
                        this.setMedicineCtrl();
                        this.setNameCtrl();
                    });
            }
            
          }

    dateChanged() {        
        if(this.treatment.start_date && this.treatment.end_date && new Date(this.treatment.start_date) > new Date(this.treatment.end_date)) {
            this.snackBar.open(this.constants.MESSAGES.INVALID_DATE, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });

            this.treatment.end_date = null;
        }
    }

    setMedicineCtrl() {
        this.filteredMedicines = this.medicineCtrl.valueChanges
            .pipe(
                startWith(''),
                map(medicine => medicine ? this.filterMedicines(medicine) : this.medicines.slice())
            );
    }

    setNameCtrl() {
        this.filteredNames = this.nameCtrl.valueChanges
            .pipe(
                startWith(''),
                map(name => name ? this.filterNames(name) : this.names.slice())
            );
    }

    setFrequencyCtrl() {
        this.filteredFrequencies = this.frequencyCtrl.valueChanges
            .pipe(
                startWith(''),
                //map(freequency => freequency ? this.filterFrequencies(freequency) : this.constants.DOSE_FREQUENCIES[this.lang].slice())
                map(freequency => freequency ? this.filterFrequencies(freequency) : this.constants.DOSE_FREQUENCIES_WITHOUT_LANG.slice())
            );
    }
    
    setMeasureCtrl() {
        this.filteredMeasures = this.measureCtrl.valueChanges
            .pipe(
                startWith(''),
                map(measure => measure ? this.filterMeasures(measure) : this.constants.DOSE_MEASURES.slice())
            );
    }

    displayMedicine(data: any): string | undefined {
        // console.log(data);
        return data ? data.composition : undefined;
    }

    displayName(data: any): string | undefined {
        // console.log(data);
        return data ? data : undefined;
    }

    // displayFrequencyOld(data: string): string | undefined {
    //     return data ? data : undefined;
    // }

    displayFrequency(data: string): string | undefined {
        return data ? this.constants.FREQUENCY[0][this.lang][data] : undefined;
    }

    // displayMeasureOld(data: string): string | undefined {
        
    //     return data ? data : undefined;
    //     // return data ? this.constants.MEASURES[0]['zh'][data] : undefined;
    // }

    displayMeasure(data: string): string | undefined{
        // return data ? data : undefined;
        return data ? this.constants.MEASURES[0][this.lang][data] : undefined;
    }

    // displayFn(id) {
    //     // I want to get the full object and display the name
    //     if (!id) return '';
    //     let index = this.measure.findIndex(state => state.id === id);
    //     return this.states[index].name;
    //   }

    filterMedicines(data: any) {                
        this.medicineValue = data;
        if(data.composition) {
            this.medicineValue = data.composition;
            this.medicineId = data.id;    
            this.treatment.brand_name = data.name;        
        } else {            
            this.medicineId = null;
        }

        this.treatment.composition = this.medicineValue;   

        return this.medicines.filter(medicine =>
            medicine.composition.toLowerCase().includes(this.medicineValue.toLowerCase()) === true || medicine.name.toLowerCase().includes(this.medicineValue.toLowerCase()) === true
        );
    }

    filterNames(data: any) {    
        this.treatment.brand_name = data;

        for(let i=0; i<this.medicines.length; i++) {
            if(data == this.medicines[i].name) {
                this.medicineId = this.medicines[i].id;
                break;
            }
        }

        return this.names.filter(name =>
            name.toLowerCase().includes(data.toLowerCase()) === true
        );
    }

    filterFrequencies(data: string) {
        this.treatment.frequency = data;
        // return this.constants.DOSE_FREQUENCIES[this.lang].filter(element =>
        //     element.toLowerCase().includes(data.toLowerCase()) === true
        // );

        return this.constants.DOSE_FREQUENCIES_WITHOUT_LANG.filter(element =>
            element.toLowerCase().includes(data.toLowerCase()) === true
        );
    }

    filterMeasures(data: string) {
        this.treatment.dose_measure = data;
        return this.constants.DOSE_MEASURES.filter(element =>
            element.toLowerCase().includes(data.toLowerCase()) === true
        );
    }

    medicineSelected() {
        var flags = [];
        this.names = [];

        for(let i=0; i<this.medicines.length; i++) {
            if(this.medicines[i].composition == this.medicineValue) {
                if(flags[this.medicines[i].name]) 
                    continue;
                    
                flags[this.medicines[i].name] = true;
                this.names.push(this.medicines[i].name);                
            }
        }
        // this.nameCtrl = new FormControl();  //commented on 8th-july-2020
        this.setNameCtrl();             
    }

    handleReportFileInput(files: FileList) {        
        let flag = true;
        for(let i=0; i<files.length; i++) {
            if(this.constants.FILE_FORMATS.DOC.indexOf(files[i].type.toLowerCase()) < 0 && this.constants.FILE_FORMATS.IMAGE.indexOf(files[i].type.toLowerCase()) < 0) {
                flag = false;  
                break;
            }
        }

        if(!flag)
            this.showMessage({'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT});
        else {
            this.reportTitle = '';
            for(let i=0; i<files.length; i++) {
                this.files[i] = files.item(i);
                this.reportTitle += files[i].name + ', ';
            }

            this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
        }
    }

    update(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            if(!this.names.includes(this.treatment.brand_name))
                this.medicineId = null;

            this.treatment.medicine_id = this.medicineId;
            if(this.medicineValue)
                this.treatment.title = this.medicineValue;
            
            if(this.treatment.start_date)
                this.treatment.start_date = this.common.formatDate(new Date(this.treatment.start_date));
            else 
                this.treatment.start_date = null;
            if(this.treatment.end_date)
                this.treatment.end_date = this.common.formatDate(new Date(this.treatment.end_date));
            else 
                this.treatment.end_date = null;
            
            delete this.treatment['name'];

            if(this.treatment.composition) {
                this.treatmentServ.update(this.treatment, this.files)
                    .then(response => {         
                        this.loaderServ.stop();               
                        this.showMessage(response);
                        this.router.navigateByUrl('/user/treatment');
                    });      
            }
        }
    }

    delete(id: number) {
        this.treatmentServ.remove(id)
            .then(res => {
                this.router.navigateByUrl('/user/treatment');
            });
    }

    deleteAsset(id: number) {
        this.snackBar.open(this.constants.REMOVE_ASSET_ALERT[this.lang], this.constants.YES[this.lang], {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['white-snackbar']
        }).onAction().subscribe(() => {
            this.loaderServ.start();
            this.treatmentServ.deleteAsset(id)
                .then(res => {
                    this.hideReport[id] = true;
                    this.loaderServ.stop();
                });
        });
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }


    
}

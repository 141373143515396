import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
    id: BehaviorSubject<number>;
    searchString: BehaviorSubject<string>;
    lang: BehaviorSubject<string>;
    procedureString: BehaviorSubject<string>;

    constructor() {
        this.id = new BehaviorSubject(null);
        this.searchString = new BehaviorSubject(null);
        this.lang = new BehaviorSubject(null);
        this.procedureString = new BehaviorSubject(null);
    }  

    updateId(id: number) {
        this.id.next(id);
    }

    updateSearchString(searchString: string) {
        this.searchString.next(searchString);
    }

    updateCurrentLanguage(lang: string) {
        this.lang.next(lang);
    }

    // =====set procedure name on subscribe======//
    currectProcedureString(procedureString: string) {
        this.procedureString.next(procedureString);
    }
}

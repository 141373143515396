import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { DentalRecordService } from '../../../services/dental-record.service';
import { DentalRecord } from '../../../models/dental-record';
import { DataService } from '../../../services/data.service';
import { SessionService } from '../../../services/session.service';
import { TreatmentService } from 'src/app/services/treatment.service';

@Component({
    selector: 'app-show-dental-record',
    templateUrl: './show-dental-record.component.html',
    styleUrls: ['./show-dental-record.component.css']
})
export class ShowDentalRecordComponent implements OnInit {

	dentalRecord: DentalRecord;
    user: any;
    id: number;
    lang: string = 'en';
    dentalRecordAssets: any;
    assetLength: number;
    teethStatuses: any = [];
    treatmentDetails: any = [];
	
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public dentalRecordServ: DentalRecordService,
        public dataServ: DataService,
        public router: Router,
        public sessionServ: SessionService,
        public treatmentServ: TreatmentService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();

        this.dentalRecord = new DentalRecord();

        this.dataServ.id.subscribe(id => {
            this.id = id; 
            if(!this.id)
                this.router.navigateByUrl('/user/dental-record');
            else {
                this.loaderServ.start();
                this.dentalRecordServ.find(this.id)
                    .then(res => {
                      
                        this.dentalRecord = new DentalRecord(res.response[0]);
                        
                       
                        this.treatmentDetails = res.response[0].treatmentDetails;                       
                        
						for(let i=0; i<this.constants.PROCEDURES.length; i++) {
							if(this.dentalRecord[this.constants.PROCEDURES[i][this.lang].title])
								this.dentalRecord[this.constants.PROCEDURES[i][this.lang].title] = JSON.parse(this.dentalRecord[this.constants.PROCEDURES[i][this.lang].title])
                        }
                        
                        for(let i=11; i<=85; i++) {                            
                            if(!this.teethStatuses[i])
                                 this.teethStatuses[i] = [];
                            this.teethStatuses[i]['color'] = '#ffffff';
                            this.teethStatuses[i]['code'] = '';                        
                        }

						if(this.dentalRecord.teeth_status) {
                            this.dentalRecord.teeth_status = JSON.parse(this.dentalRecord.teeth_status);
                            if(this.dentalRecord.teeth_status['fixed'])
                                for(let i=0; i<this.dentalRecord.teeth_status['fixed'].length; i++)
                                    this.teethStatuses[this.dentalRecord.teeth_status['fixed'][i].tooth] = this.dentalRecord.teeth_status['fixed'][i];
                        }
                        this.loaderServ.stop();
                        this.dentalRecordAssets = res.response[0].assetDetails;
                    });
            }
        });        
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    edit(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/edit-dental-record');
    }
}
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { MedicalHistoryService } from '../../../services/medical-history.service';
import { SessionService } from '../../../services/session.service';
import { DataService } from '../../../services/data.service';

@Component({
	selector: 'app-deleted-medical-histories',
	templateUrl: './deleted-medical-histories.component.html',
	styleUrls: ['./deleted-medical-histories.component.css']
})
export class DeletedMedicalHistoriesComponent implements OnInit {
	noData: boolean = true;
	medicalHistories: any = [];
	lang: string;
	
	constructor(
		public constants: CONSTANTS,
		public common: COMMON,
		public loaderServ: NgxUiLoaderService,
		public medicalHistoryServ: MedicalHistoryService,
		public sessionServ: SessionService,
		public router: Router,
		public dataServ: DataService,
		private snackBar: MatSnackBar,
	) { }

	ngOnInit() {
		this.lang = this.sessionServ.getLang(); 
		this.records();
	}

	records() {
		this.loaderServ.start();
		this.medicalHistoryServ.deletedRecords()
			.then(res => {
				this.medicalHistories = res.response;
				this.loaderServ.stop();
				if(this.medicalHistories.length <= 0)
					this.noData = false;
			});
	}

	restore(id: number) {
		this.medicalHistoryServ.restore(id)
			.then(res => {
				this.showMessage(res);
				this.records();
			});
	}

	showMessage(res: any) {    
		if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['green-snackbar']
			});
		else
			this.snackBar.open(res.message, '', {
				duration: 3000,
				panelClass: ['red-snackbar']
			});
	}
}

import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { MedicalHistoryService } from '../../../services/medical-history.service';
import { MedicalHistory } from '../../../models/medical-history';
import { UserProfile } from '../../../models/user-profiles';
import { DataService } from '../../../services/data.service';
import { SessionService } from '../../../services/session.service';
import { TreatmentService } from 'src/app/services/treatment.service';
import { MeddraService } from 'src/app/services/meddra.service';

@Component({
    selector: 'app-edit-medical-history',
    templateUrl: './edit-medical-history.component.html',
    styleUrls: ['./edit-medical-history.component.css']
})
export class EditMedicalHistoryComponent implements OnInit {

    medicalHistory: MedicalHistory;
    user: any;
    userProfile: UserProfile;
    message: string;
    id: number;
    lltCtrl: FormControl;
    ptCtrl: FormControl;
    filteredLLTs: any;
    filteredPTs: any;
    lltId: number;
    ptId: number;
    meddraPTValue: string;
    reportTitle: string;
    maxDate: Date;
    lang: string;
    history: any = [];
    files: File [] = [];
    llts: any = [];
    pts: any = [];
    ptValue: any = [];
    treatmentIds: number[] = [];
    treatmentDetails: any = [];
    assetDetails: any = [];
    hideReport: boolean[] = [];

    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public medicalHistoryServ: MedicalHistoryService,
        public dataServ: DataService,
        public router: Router,
        public sessionServ: SessionService,
        public treatmentServ: TreatmentService,
        public meddraServ: MeddraService,
        private snackBar: MatSnackBar
    ) {
     }

    ngOnInit() {       
        this.lang = this.sessionServ.getLang(); 
        this.maxDate = new Date();

        this.lltCtrl = new FormControl();
        this.ptCtrl = new FormControl();

        this.medicalHistory = new MedicalHistory();
        
        this.medicalHistoryServ.getMeddraData()
            .then(response => {                
                let that = this;    
                this.llts = response.response.data;

                that.pts = [];
                this.llts.forEach(element => {
                    that.pts.push(element.meddra_pt);
                });

                this.dataServ.id.subscribe(id => { 
                    this.id = id; 
                    if(!this.id)
                        this.router.navigateByUrl('/user/medical-history');
                    else {
                        this.user = this.sessionServ.getUser();                
                        this.getMedicalHistoryByUserId();
                        
                    }
                });
            });

           
    }

    dateChanged() {
        if(this.medicalHistory.start_date && this.medicalHistory.end_date && new Date(this.medicalHistory.start_date) > new Date(this.medicalHistory.end_date)) {
            this.snackBar.open(this.constants.MESSAGES.INVALID_DATE, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });

            this.medicalHistory.end_date = null;
        }
    }

    // setLLTCtrl() {
    //     this.filteredLLTs = this.lltCtrl.valueChanges
    //         .pipe(
    //             startWith(''),
    //             map(llt => llt ? this.filterLLTs(llt) : this.llts.slice())
    //         );
    // }

    setLLTCtrl() {
        this.lltCtrl.valueChanges
          .pipe(
            startWith(''),
            debounceTime(300),
          )
          .subscribe(llt => {
            this.filterLLTs(llt);
          });
      }

    setPTCtrl() {
        this.filteredPTs = this.ptCtrl.valueChanges
            .pipe(
                startWith(''),
                map(pt => pt ? this.filterPTs(pt) : this.pts.slice())
            );
    }

    displayLLT(data: any): string | undefined {
        return data ? data.name : undefined;
    }

    displayPT(data: any): string | undefined {
        return data ? data.name : undefined;
    }

    // filterLLTs(data: any) {                
    //     this.setPTCtrl();

    //     let value = data;

    //     if(data.name) {
    //         value = data.name;
    //         this.lltId = data.id;
    //         this.medicalHistory.other_llt = value;
    //         this.medicalHistory.other_pt = data.meddra_pt.name;
    //         this.ptCtrl = new FormControl(data.meddra_pt);
    //         this.filteredPTs = null;
    //     } else {
    //         this.medicalHistory.other_llt = data;
    //         this.lltId = null;
    //     }

    //     this.meddraServ.getMeddraData(this.constants.MEDDRA_TYPES.LLT, data)
    //         .then(res => {
    //             if(res)
    //                 this.llts = res.response;
    //         });

    //     if(this.llts.length>0)
    //         return this.llts.filter(llt =>
    //             llt.english.toLowerCase().includes(value.toLowerCase()) === true
    //         );
    // }

    filterLLTs(data: any) {
        this.setPTCtrl();
    
        let value = data;
    
        if (data.name) {
          value = data.name;
          this.lltId = data.id;
          this.medicalHistory.other_llt = value;
          this.medicalHistory.other_pt = data.meddra_pt.name;
          this.ptCtrl = new FormControl(data.meddra_pt);
          this.filteredPTs = null;
        } else {
          this.medicalHistory.other_llt = data;
          this.lltId = null;
        }
    
        this.meddraServ.getMeddraData(this.constants.MEDDRA_TYPES.LLT, data)
          .then(res => {
            if (res.response.data !== undefined) {
              this.llts = res.response.data;
            } else {
              this.llts = res.response;
            }
            if (this.llts.length > 0) {
              // if (value === undefined) return this.llts;
              return this.filteredLLTs = this.llts.filter(llt => {
                if (llt.english !== null) {
                  try {
                    return llt.english.toString().toLowerCase().includes(value.toLowerCase()) === true
                  }
                  catch (err) {
                    // debugger;
                  }
    
                }
              }
              );
            }
          })
    
    
      }

    filterPTs(data: any) {
        let value = data;
        if(data.name) {
            value = data.name;
            this.ptId = data.id;     
            this.medicalHistory.other_pt = value;       
        } else 
            this.medicalHistory.other_pt = data;

        this.meddraServ.getMeddraData(this.constants.MEDDRA_TYPES.PT, data)
            .then(res => {
                this.pts = res.response.data;
            });

        if(this.pts)
            return this.pts.filter(pt =>
                pt.name.toLowerCase().includes(value.toLowerCase()) === true
            );
    }

    getMedicalHistoryByUserId() {
        // alert(this.id);
        this.loaderServ.start();
        this.medicalHistoryServ.getUserMedicalHistory(this.id)
            .then(res => {
                this.history = res.response[0];
                if(this.history) {
                    this.assetDetails = this.history.assetDetails;
                    this.medicalHistory = new MedicalHistory(this.history);
                    this.ptValue.name = this.medicalHistory.title;
                    if(this.history.treatmentDetails.length != 0) {
                        this.medicalHistory.treatment_ids = res.response[0].treatmentDetails[0].id;                
                    }
                    this.lltId = this.medicalHistory.meddra_llt_id;
                    this.medicalHistory.other_pt = this.medicalHistory.title;

                    for(let i=0; i<this.assetDetails.length; i++)
                        this.hideReport[this.assetDetails[i].id] = false;

                    this.treatmentServ.records()
                        .then(res => {
                            this.treatmentDetails = res.response;

                            for(let i=0; i<this.history.treatmentDetails.length; i++) {
                                this.treatmentIds.push(this.history.treatmentDetails[i].id);
                            }        
                        });
                                        
                    this.lltCtrl = null;
                    this.ptCtrl = null;
                                        this.lltCtrl = new FormControl(this.history.medicalHistoryLlt);
                    this.ptCtrl = new FormControl(this.history.medicalHistoryLlt.meddra_pt);
                    if(this.ptCtrl != null) {
                        this.medicalHistory.title = this.ptCtrl.value.name;
                    }

                    
                    this.setLLTCtrl();
                    this.setPTCtrl();  
                }

                this.loaderServ.stop();
            });
    }

    handleReportFileInput(files: FileList) {        
        let flag = true;
        for(let i=0; i<files.length; i++) {
            if(this.constants.FILE_FORMATS.DOC.indexOf(files[i].type.toLowerCase()) < 0 && this.constants.FILE_FORMATS.IMAGE.indexOf(files[i].type.toLowerCase()) < 0) {
                flag = false;  
                break;
            }
        }

        if(!flag)
            this.showMessage({'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT});
        else {
            this.reportTitle = '';
            for(let i=0; i<files.length; i++) {
                this.files[i] = files.item(i);
                this.reportTitle += files[i].name + ', ';
            }

            this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
        }
    }

    update(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();

            if(!this.medicalHistory.other_llt) {
                this.medicalHistory.other_pt = this.medicalHistory.title;
                this.medicalHistory.other_llt = this.history.medicalHistoryLlt.name;
            }
            
            if(this.medicalHistory.start_date)
                this.medicalHistory.start_date = this.common.formatDate(new Date(this.medicalHistory.start_date));
            else 
                this.medicalHistory.start_date = null;
            if(this.medicalHistory.end_date)
                this.medicalHistory.end_date = this.common.formatDate(new Date(this.medicalHistory.end_date));
            else 
                this.medicalHistory.end_date = null;
            this.medicalHistory.meddra_llt_id = this.lltId;
            this.medicalHistory.title = this.medicalHistory.other_pt;
            this.medicalHistoryServ.update(this.medicalHistory, this.treatmentIds, this.files)
                .then(response => {                    
                    this.loaderServ.stop();
                    this.showMessage(response);
                    this.router.navigateByUrl('/user/medical-history');
                });            
        }
    }

    delete(id: number) {
        this.medicalHistoryServ.remove(id)
            .then(res => {
                this.router.navigateByUrl('/user/medical-history');
            });
    }

    getPTRecords() {
        let ptValue = this.pts.filter(item => item.id == this.medicalHistory.meddra_llt_id);
        let nameOfPt = ptValue[0];
        this.ptValue = nameOfPt.meddra_pt;
        this.medicalHistory.title = this.ptValue.name;
    }

    deleteAsset(id: number) {
        this.snackBar.open(this.constants.REMOVE_ASSET_ALERT[this.lang], this.constants.YES[this.lang], {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['white-snackbar']
        }).onAction().subscribe(() => {
            this.loaderServ.start();
            this.medicalHistoryServ.deleteAsset(id)
                .then(res => {
                    if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
                        this.hideReport[id] = true;
                    
                    this.loaderServ.stop();
                });
        });
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

    treatmentComparer(o1: number, o2: number): boolean {
        return o1 && o2 ? o1 === o2 : o2 === o2;
    }
}

import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';

import { CONSTANTS } from '../../util/constants';
import { COMMON } from '../../util/common';
import { MedicalHistoryService } from '../../services/medical-history.service';
import { SessionService } from '../../services/session.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-medical-history',
    templateUrl: './medical-history.component.html',
    styleUrls: ['./medical-history.component.css']
})
export class MedicalHistoryComponent implements OnInit {

    noData: boolean = true;
    user: any;
    medicalHistories: any = [];
   
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public medicalHistoryServ: MedicalHistoryService,
        public sessionServ: SessionService,
        public router: Router,
        public dataServ: DataService,
    ) { }

    ngOnInit() {
        this.user = this.sessionServ.getUser();
        this.records();
    }

    records() {
        this.loaderServ.start();
        this.medicalHistoryServ.records()
            .then(res => {
                this.medicalHistories = res.response;

                if(this.medicalHistories.length <= 0)
                    this.noData = false;
                this.loaderServ.stop();
            });
    }

    show(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/show-medical-history');
    }

    downloadReport() {
        this.medicalHistoryServ.generatePdf()
            .then(res => {
                window.location.href=this.constants.SERVER.BASE+'/'+res.response;
            });
    }
}

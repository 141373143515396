import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { MatSnackBar } from '@angular/material';

import { CONSTANTS } from 'src/app/util/constants';
import { Cms } from 'src/app/models/cms';
import { MeddraService } from 'src/app/services/meddra.service';
import { CmsService } from 'src/app/services/cms.service';

@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {

    public Editor = ClassicEditor;
    public cmsDetails: any;
    private cms: Cms;
    public languages: any = [];

    constructor(
        public constants: CONSTANTS,
        private meddraServ: MeddraService,
        private cmsServ: CmsService,
        private loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.records();

        this.meddraServ.getLanguages()
            .then(res => {
                if(res)
                    this.languages = res.response;
            })
    }

    records() {
        this.loaderServ.start();
        this.cmsServ.record()
            .then(res => {
                this.cmsDetails = res.response;
                this.loaderServ.stop();
            });
    }

    update(form: NgForm, cms: any) {
        this.cms = new Cms(cms);

        if(form.valid) {
            this.loaderServ.start();
            this.cmsServ.update(this.cms)
                .then(res => {                    
                    this.showMessage(res);
                    this.records();
                    this.loaderServ.stop();
                });
        }
    }

    delete(id:  number) {
        this.loaderServ.start();
        this.cmsServ.delete(id)
            .then(res => {                    
                this.showMessage(res);
                this.records();
                this.loaderServ.stop();
            });
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }
}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

import { CONSTANTS } from '../../../util/constants';
import {FormControl, FormGroup} from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { SessionService } from '../../../services/session.service';
import { User } from '../../../models/user';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.css']
})
export class UserReportComponent implements OnInit {
  

  // displayedColumns: string[] = ['name', 'email', 'blood_group', 'medical_id', 'organ_donor', 'date_of_birth', 'gender', 'smoker', 'allergy', 'actions'];
  displayedColumns: string[] = ['sn', 'email', 'blood_group', 'organ_donor', 'date_of_birth', 'gender', 'smoker', 'allergy', 'created_at'];
  
  dataSource: any = [];
  users: any = [];
  user_status: string;
  public excelData: any = [];
  public genderselection: string;
  public startDate: string;
  public endDate: string;
  public age: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) sort: MatSort;

  // filterForm = new FormGroup({
  //   fromDate: new FormControl(),
  //   toDate: new FormControl(),
  // });

  // get fromDate() { return this.filterForm.get('fromDate').value; }
  // get toDate() { return this.filterForm.get('toDate').value; }


  constructor(
      public constant: CONSTANTS,
      public userServ: UserService,
      public sessionServ: SessionService,
      private snackBar: MatSnackBar,
      public loaderServ: NgxUiLoaderService,
      private datePipe: DatePipe,
  ) { }

  ngOnInit() {
      this.records();
  }

  

  ngAfterViewInit() {
      this.dataSource.sort = this.sort;
  }

  records() {
    this.loaderServ.start();
      this.userServ.recordsReports()
          .then(res => {   
            // this.loaderServ.stop();       
              this.users = res.response;
              console.log(this.users);
             
              this.applyTableBehaviour(this.users);
          });
  }

  delete(id) {
      this.userServ.delete(id)
          .then(res => {                
              this.records();
          })
  }

  activedeactive(id, status){
      console.log(status);
      if(status == 'active')
      { 
          this.user_status = 'removed';
      } else {
          this.user_status = 'active';
      }
      
      this.userServ.activedeactive(id, this.user_status)
      .then(res => {     
          this.showMessage(res);           
          this.records();
      })
  }

  applyTableBehaviour(data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.loaderServ.stop();        
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  } 


  applyFilter() {
    let data = [];
    
    let startDateInput = this.formatDate(this.startDate);
    let startDate = Date.parse(startDateInput);
    console.log(startDate);
    let age = this.age;
    
    let endDateInput = this.formatDate(this.endDate);
    let endDate = Date.parse(endDateInput);
    let gender = this.genderselection;

    let conditionList = [];
    if(typeof(age)!="undefined" && age!="")
    {
      conditionList.push("age")
    }

    if(typeof(gender)!="undefined" && gender!="")
    {
      conditionList.push("gender")
    }
    
    if(startDate && !endDate)
    {
      conditionList.push("startDate")
    }

    if(endDate && !startDate)
    {
      conditionList.push("endDate");
    }

    if(endDate && startDate)
    {
      conditionList.push("startEndDate");     
    }


    if(age || gender || startDate || endDate) {
      if(this.users) {
        // data = this.users;
        data=this.users.filter(user => {
          let userdetails = [];
          let condition = true;
            let user_joining = Date.parse(user.created_at);
            let gender_value = user.gender;
            let age_value = user.age;
            user.created_at = this.datePipe.transform(user.created_at, 'yyyy-MM-dd');

            conditionList.forEach(function(item){
              if(condition!=false && item=="age"){
                  condition = age== user.age
              }
              if(condition!=false && item=="gender"){
                  condition = gender== user.gender
              }
              // startDate = 1579737600000;
              if(condition!=false && item=="startDate" ){
                if(user.created_at != "" && Date.parse(user.created_at) != null) {
                  let convert_date = Date.parse(user.created_at);
                  condition = startDate == convert_date
                }
              }

              if(condition!=false && item=="endDate" ){
                if(user.created_at != "" && Date.parse(user.created_at) != null) {
                  let convert_date = Date.parse(user.created_at);
                  condition = endDate == convert_date
                }
              }
              
              if(condition!=false && item=="startEndDate"){
                let convert_date_end = Date.parse(user.created_at);
                let convert_date_start = Date.parse(user.created_at);
                // console.log(user.created_at+'start=>'+startDateInput+'end=>'+endDateInput);
                  //  if (startDate <= convert_date_start && convert_date_end <= endDate) {
                    // condition = (startDate <= convert_date_start && convert_date_end <= endDate)
                    condition = (convert_date_end >= startDate && convert_date_end <= endDate)
                  // }
              }

            })
            return condition;
            // if(user_joining <= endDate && user_joining >= startDate && gender == gender_value && age_value == age){
            //   console.log("111111111");
            //   data.push(user);
            // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate && gender && gender == gender_value) {
            //   data.push(user);
            // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate && age && age_value == age) {
            //   data.push(user);
            // } else if(gender && gender == gender_value && age && age_value == age) {
            //   data.push(user);
            // } else if(gender && age && gender == gender_value && age_value == age) {
            //   console.log("-------");
            //   data.push(user);
            // } else if(age && age_value == age) {
            //   console.log("+++++");
            //     data.push(user);
            // } else if(gender && gender == gender_value) {
            //   console.log(">>>>>>>>>");
            //   data.push(user);
            // } else if(endDate && startDate && user_joining <= endDate && user_joining >= startDate) {
            //   data.push(user);
            // }
          
        });
        this.applyTableBehaviour(data);
    }
  }
  }

  showMessage(res: any) {    
  if(this.constant.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
    this.snackBar.open(res.message, '', {
      duration: 3000,
      panelClass: ['green-snackbar']
    });
  else
    this.snackBar.open(res.message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
}

downloadCSV() {
  this.excelData = [];
  this.excelData.push(this.displayedColumns);
  
  let that = this;
  
  this.dataSource.filteredData.filter(function(element, index, array) {
    let email: string;  
    let fname: string;  
    let medical_id: string;  
    let date_of_birth: string;  
    let smoker: string;  
    let organ_donor: string;
    let primary_emergency_contact_number: string;
    let secondary_emergency_contact_number: string;
    let doctor_contact_info: string;
    if(element.user_consent !='0') 
      {
         email = element.email;
         fname = element.name;
         medical_id = element.medical_id;
         primary_emergency_contact_number = element.primary_emergency_contact_number;
         secondary_emergency_contact_number = element.secondary_emergency_contact_number;
         doctor_contact_info = element.doctor_contact_info;
      } else {
         email = '*****';
         fname = '*****';
         medical_id = '*****';
         primary_emergency_contact_number = '*****';
         secondary_emergency_contact_number = '*****';
         doctor_contact_info = '*****';
      }

      if(element.smoker == 1){
        smoker = 'Yes';
      } else if(element.smoker == 0) {
        smoker = 'No';
      } else {
        smoker = 'No';
      }

      if(element.organ_donor == 1){
        organ_donor = 'Yes';
      } else if(element.organ_donor == 0) {
        organ_donor = 'No';
      } else {
        organ_donor = 'No';
      }

      if(element.date_of_birth) {
        date_of_birth = element.date_of_birth
      }
      that.excelData.push([ index + 1 , email, element.blood_group, organ_donor, date_of_birth, element.gender, smoker,  element.allergy, element.created_at]);  
  });

  new AngularCsv(this.excelData, 'demographic');
}

resetFilter() {
   this.dataSource.filter = '';
   this.startDate = '';
   this.age = '';
   this.endDate = '';
   this.genderselection = '';
   this.records();
}

}

import { Component, OnInit, Inject } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';

import { CONSTANTS } from '../../util/constants';
import { COMMON } from '../../util/common';
import { DentalRecordService } from '../../services/dental-record.service';
import { SessionService } from '../../services/session.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-dental-record',
    templateUrl: './dental-record.component.html',
    styleUrls: ['./dental-record.component.css']
})
export class DentalRecordComponent implements OnInit {

    noData: boolean = true;
    dentalRecords: any = [];
    teethData: number[] = [];
   
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public dentalRecordServ: DentalRecordService,
        public sessionServ: SessionService,
        public router: Router,
        public dataServ: DataService,
    ) { }

    ngOnInit() {
        this.loaderServ.start();
        this.dentalRecordServ.records()
            .then(res => {
                this.dentalRecords = res.response;
                this.loaderServ.stop();
                if(this.dentalRecords.length > 0)
                    this.noData = false;

               
            });
    }

    show(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/show-dental-record');
    }

    edit(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/edit-dental-record');
    }
}

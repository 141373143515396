import { Component, OnInit, Input } from '@angular/core';
import { CONSTANTS } from 'src/app/util/constants';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: 'app-treatment-detail',
    templateUrl: './treatment-detail.component.html',
    styleUrls: ['./treatment-detail.component.css']
})
export class TreatmentDetailComponent implements OnInit {

    lang: string;
    @Input() selectedRecord: any;

    constructor(
        public constants: CONSTANTS,
        public sessionServ: SessionService,
    ) { }

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
    }

}

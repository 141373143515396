export class MemberProfile {
    id: number;

    name: string;
    gender: string;
    
    // gender: string = "male";
    user_id: number;
    parent_id: number;
    date_of_birth: string;
    // organ_donor: number = 1;
    organ_donor: number;
    primary_emergency_contact_name: string;
    primary_emergency_contact_number: string;
    secondary_emergency_contact_name: string;
    secondary_emergency_contact_number: string;
    // smoker: number = 1;
    smoker: number;
    doctor_contact_info: string;
    user_consent: number = 0;
    blood_group: string;
    rh_factor: string;
    image: string;
    medical_id: string;
    allergy: string;
    
    constructor(memberProfile: MemberProfile = null) {
        if(memberProfile) {
            this.id = memberProfile.id;
            this.name = memberProfile.name;
            this.gender = memberProfile.gender;
            this.user_id = memberProfile.user_id;
            this.parent_id = memberProfile.parent_id;
            this.date_of_birth = memberProfile.date_of_birth;
            this.organ_donor = memberProfile.organ_donor;
            this.primary_emergency_contact_name = memberProfile.primary_emergency_contact_name;
            this.primary_emergency_contact_number = memberProfile.primary_emergency_contact_number;
            this.secondary_emergency_contact_name = memberProfile.secondary_emergency_contact_name;
            this.secondary_emergency_contact_number = memberProfile.secondary_emergency_contact_number;
            this.smoker = memberProfile.smoker;
            this.doctor_contact_info = memberProfile.doctor_contact_info;
            this.user_consent = memberProfile.user_consent;
            this.rh_factor = memberProfile.rh_factor;
            this.image = memberProfile.image;
            this.blood_group = memberProfile.blood_group;
            this.medical_id = memberProfile.medical_id;
            this.allergy = memberProfile.allergy;
        }
    }
}

export class Treatment {
    id: number;
    person_id: number;
    title: string;
    medicine_id: number;
    name: string;
    purpose: string;
    start_date: string;
    end_date: string;
    composition: string = null;
    brand_name: string = null;
    dose_quantity: string = null;
    dose_measure: string = null;
    frequency: string = null;
    administration: string = null;
    created_by: number;
    updated_by: number;
    // notelist: number[] = [];
    
    constructor(treatment: Treatment = null) {
        if(treatment) {
            this.id = treatment.id;
            this.person_id = treatment.person_id;
            this.title = treatment.title;
            this.name = treatment.name;
            this.purpose = treatment.purpose;
            this.medicine_id = treatment.medicine_id;
            this.start_date = treatment.start_date;
            this.end_date = treatment.end_date;
            this.dose_quantity = treatment.dose_quantity;
            this.dose_measure = treatment.dose_measure;
            this.frequency = treatment.frequency;
            this.administration = treatment.administration;
            this.composition = treatment.composition;
            this.brand_name = treatment.brand_name;
            this.created_by = treatment.created_by;
            this.updated_by = treatment.updated_by;  
            // this.notelist = treatment.notelist;           
        }
    }
}

export class MedicalHistory {
    id: number;
    other_llt: string;
    other_pt: string;
    person_id: number;
    title: string;
    meddra_llt_id: number;
    medical_details: string;
    start_date: string;
    end_date: string;
    other_interventions: string = null;
    notes: string = null;
    created_by: number;
    updated_by: number;
    language_id: number = 1;
    treatment_ids: number[] = [];
    files: File[] = [];
    
    constructor(medicalHistory: MedicalHistory = null) {
        if(medicalHistory) {
            this.id = medicalHistory.id;
            this.language_id = 1;
            this.other_llt = medicalHistory.other_llt;
            this.other_pt = medicalHistory.other_pt;
            this.person_id = medicalHistory.person_id;
            this.title = medicalHistory.title;
            this.meddra_llt_id = medicalHistory.meddra_llt_id;
            this.medical_details = medicalHistory.medical_details;
            this.start_date = medicalHistory.start_date;
            this.end_date = medicalHistory.end_date;
            this.other_interventions = medicalHistory.other_interventions;
            this.notes = medicalHistory.notes;
            this.treatment_ids = medicalHistory.treatment_ids;
            this.files = medicalHistory.files;
            this.created_by = medicalHistory.created_by;
            this.updated_by = medicalHistory.updated_by;
           
        }
    }
}

import { Injectable } from '@angular/core';

import { CONSTANTS } from '../util/constants';
import { HttpService } from './http.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    constructor(
		private constants: CONSTANTS,
		private httpServ: HttpService,
		private sessionServ: SessionService
	) {
		this.constants = constants,
		this.httpServ = httpServ,
		this.sessionServ = sessionServ
	};

    /**
     * Prepares request object for add cms and send it to http service
     * @param data 
     */
    save(data: any) {
		let user = this.sessionServ.getUser();

		data.created_by = user.user_id;
        data.updated_by = user.user_id;
		
		let requestObject = {
			REQUEST_DATA: data,
			API_URL: this.constants.API_URLS.CMS + this.constants.URL_VARIABLES.TOKEN + user.token,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object to update cms and send it to http service
     * @param data 
     */
    update(data: any) {
        let user = this.sessionServ.getUser();
        
        data.updated_by = user.user_id;
        data.token = user.token;
		
		let requestObject = {
			REQUEST_DATA: data,
			API_URL: this.constants.API_URLS.CMS + this.constants.API_URLS.ROOT + data.id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.PUT
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object for get record of cms and send it to http service
     * @param data 
     */
    record() {
		let user = this.sessionServ.getUser();
		
		let requestObject = {
			API_URL: this.constants.API_URLS.CMS + this.constants.URL_VARIABLES.TOKEN + user.token,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object for get record of cms and send it to http service
     * @param data 
     */
    getByName(name: string) {
		let user = this.sessionServ.getUser();
		let lang = this.sessionServ.getLang();
		let language_id = this.constants.LANGUAGE_COLUMNS_VALUE[lang];
		
		let requestObject = {
			API_URL: this.constants.API_URLS.PAGE + this.constants.URL_VARIABLES.NAME_VAR + name + this.constants.URL_VARIABLES.LANGUAGE_ID + language_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
		};

		return this.httpServ.sendRequest(requestObject);
    }

    /**
     * Prepares request object to delete cms and send it to http service
     * @param data 
     */
    delete(id: number) {
        let user = this.sessionServ.getUser();
        
		let requestObject = {
			API_URL: this.constants.API_URLS.CMS + this.constants.API_URLS.ROOT + id + this.constants.URL_VARIABLES.TOKEN + user.token + this.constants.URL_VARIABLES.USER_ID + user.user_id,
			REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
		};

		return this.httpServ.sendRequest(requestObject);
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { MatDatepickerModule, MatNativeDateModule, MatSnackBarModule, MatAutocompleteModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';    

import { CONSTANTS } from './util/constants';
import { COMMON } from './util/common';
import { SessionService } from './services/session.service';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { HttpService } from './services/http.service';
import { AuthAdminService } from './services/auth-admin.service';
import { AuthUserService } from './services/auth-user.service';
import { DataService } from './services/data.service';
import { MeddraService } from './services/meddra.service';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmEqualVlaidatorDirective } from './shared/confirm-equal-vlaidated.directive';
import { AdminComponent } from './components/admin/admin.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UserComponent } from './components/user/user.component';
import { UserPanelComponent } from './components/user-panel/user-panel.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { EditMedicalHistoryComponent } from './components/medical-history/edit-medical-history/edit-medical-history.component';
import { AddMedicalHistoryComponent } from './components/medical-history/add-medical-history/add-medical-history.component';
import { EditProfileComponent } from './components/user-profile/edit-profile/edit-profile.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MyFamilyComponent } from './components/my-family/my-family.component';
import { DentalRecordComponent } from './components/dental-record/dental-record.component';
import { AddDentalRecordComponent } from './components/dental-record/add-dental-record/add-dental-record.component';
import { EditDentalRecordComponent } from './components/dental-record/edit-dental-record/edit-dental-record.component';
import { AddBasicInfoComponent } from './components/user-profile/add-basic-info/add-basic-info.component';
import { AddFamilyMemberComponent } from './components/my-family/add-family-member/add-family-member.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EditMemberProfileComponent } from './components/my-family/edit-member-profile/edit-member-profile.component';
import { MemberProfileComponent } from './components/my-family/member-profile/member-profile.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { EditMyProfileComponent } from './components/my-profile/edit-my-profile/edit-my-profile.component';
import { TreatmentComponent } from './components/treatment/treatment.component';
import { AddTreatmentComponent } from './components/treatment/add-treatment/add-treatment.component';
import { ShowTreatmentComponent } from './components/treatment/show-treatment/show-treatment.component';
import { EditTreatmentComponent } from './components/treatment/edit-treatment/edit-treatment.component';
import { ImageComponent } from './components/assets/image/image.component';
import { PdfComponent } from './components/assets/pdf/pdf.component';
import { NotesComponent } from './components/assets/notes/notes.component';
import { ToothMapComponent } from './components/dental-record/tooth-map/tooth-map.component';
import { LegendsComponent } from './components/dental-record/legends/legends.component';
import { ShowDentalRecordComponent } from './components/dental-record/show-dental-record/show-dental-record.component';
import { RecentlyDeletedComponent } from './components/recently-deleted/recently-deleted.component';
import { DeletedMedicalHistoriesComponent } from './components/medical-history/deleted-medical-histories/deleted-medical-histories.component';
import { DeletedTreatmentsComponent } from './components/treatment/deleted-treatments/deleted-treatments.component';
import { DeletedDentalRecordsComponent } from './components/dental-record/deleted-dental-records/deleted-dental-records.component';
import { TreatmentDetailComponent } from './components/assets/treatment-detail/treatment-detail.component';
import { MedicalHistoryDetailComponent } from './components/assets/medical-history-detail/medical-history-detail.component';
import { DentalRecordDetailComponent } from './components/assets/dental-record-detail/dental-record-detail.component';
import { MeddraComponent } from './components/meddra/meddra.component';
import { CmsComponent } from './components/cms/cms.component';
import { AddCmsComponent } from './components/cms/add-cms/add-cms.component';
import { AboutComponent } from './components/about/about.component';
import { TermsComponent } from './components/terms/terms.component';
import { PolicyComponent } from './components/policy/policy.component';
import { SupportComponent } from './components/support/support.component';
import { SearchComponent } from './components/search/search.component';
import { ShowMedicalHistoryComponent } from './components/medical-history/show-medical-history/show-medical-history.component';
import { ContactComponent } from './components/contact/contact.component';
import { MedicalReportComponent } from './components/report/medical-report/medical-report.component';
import { DentalReportComponent } from './components/report/dental-report/dental-report.component';
import { UserReportComponent } from './components/report/user-report/user-report.component';
import { TreatmentReportComponent } from './components/report/treatment-report/treatment-report.component';
import { DatePipe } from '@angular/common';
import { MedicineComponent } from './components/medicine/medicine.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        ConfirmEqualVlaidatorDirective,
        AdminComponent,
        ForgotPasswordComponent,
        UserComponent,
        UserPanelComponent,
        UserDashboardComponent,
        MedicalHistoryComponent,
        EditMedicalHistoryComponent,
        AddMedicalHistoryComponent,
        EditProfileComponent,
        ResetPasswordComponent,
        MyFamilyComponent,
        DentalRecordComponent,
        AddDentalRecordComponent,
        AddBasicInfoComponent,
        AddFamilyMemberComponent,
        UserProfileComponent,
        EditMemberProfileComponent,
        MemberProfileComponent,
        MyProfileComponent,
        EditMyProfileComponent,
        TreatmentComponent,
        AddTreatmentComponent,
        ShowTreatmentComponent,
        EditTreatmentComponent,
        EditDentalRecordComponent,
        ImageComponent,
        PdfComponent,
        NotesComponent,
        ToothMapComponent,
        LegendsComponent,
        ShowDentalRecordComponent,
        RecentlyDeletedComponent,
        DeletedMedicalHistoriesComponent,
        DeletedTreatmentsComponent,
        DeletedDentalRecordsComponent,
        TreatmentDetailComponent,
        MedicalHistoryDetailComponent,
        DentalRecordDetailComponent,
        MeddraComponent,
        CmsComponent,
        AddCmsComponent,
        AboutComponent,
        TermsComponent,
        PolicyComponent,
        SupportComponent,
        SearchComponent,
        ShowMedicalHistoryComponent,
        ContactComponent,
        MedicalReportComponent,
        DentalReportComponent,
        UserReportComponent,
        TreatmentReportComponent,
        MedicineComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        AngularFontAwesomeModule,
        NgxUiLoaderModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        CKEditorModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
    ],
    providers: [
        CONSTANTS,
        COMMON,
        SessionService,
        LoginService,
        UserService,
        HttpService,
        AuthAdminService,
        AuthUserService,
        DataService,
        MeddraService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        DatePipe,
    ],
    entryComponents: [
        ToothMapComponent,
        LegendsComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

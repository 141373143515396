import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { CONSTANTS } from '../util/constants';
import { SessionService } from "./session.service";

@Injectable()

@Injectable()
export class HttpService {
    constructor(
        private http: HttpClient, 
        private constants: CONSTANTS,
        private sessionServ: SessionService,
        private router: Router
    ) {}

    sendRequest(requestObject, fileUpload=false): Promise<any> {  
        let lang = this.sessionServ.getLang();
        console.log(lang);
        // const headers = new HttpHeaders({'X-localization': lang,'X-RateLimit-Limit':'30'});
        const headers = new HttpHeaders({'X-localization': lang});

        if(requestObject.REQUEST_METHOD == this.constants.REQUEST_METHODS.GET) {            
            return this.http.get(this.constants.SERVER.ROOT + requestObject.API_URL, {headers: headers})
                .toPromise()
                .then((response: any) => {
                    this.checkResponse(response);
                    return response;
                }, error => {
                    this.handleError(error.status);
                });
        }
        else if(requestObject.REQUEST_METHOD == this.constants.REQUEST_METHODS.POST) {
            return this.http.post(this.constants.SERVER.ROOT + requestObject.API_URL, requestObject.REQUEST_DATA, {headers: headers})
                .toPromise()
                .then((response: any) => {
                    this.checkResponse(response);
                    return response;
                }, error => {
                    this.handleError(error.status);
                });
        }
        else if(requestObject.REQUEST_METHOD == this.constants.REQUEST_METHODS.PUT) {
            return this.http.put(this.constants.SERVER.ROOT + requestObject.API_URL, requestObject.REQUEST_DATA, {headers: headers})
                .toPromise()
                .then((response: any) => {
                    this.checkResponse(response);
                    return response;
                }, error => {
                    this.handleError(error.status);
                });
        }
        else if(requestObject.REQUEST_METHOD == this.constants.REQUEST_METHODS.DELETE){
            return this.http.delete(this.constants.SERVER.ROOT + requestObject.API_URL, {headers: headers})
                .toPromise()
                .then((response: any) => {
                    this.checkResponse(response);
                    return response;
                }, error => {
                    this.handleError(error.status);
                });
        }
    }

    handleError(status) {
        if(status == this.constants.RESPONSE_CODES.UNAUTHORIZED){
            delete sessionStorage.user;
            window.location.href = '/';
        }
    } 

    checkResponse(response: any) {
        if(this.constants.MESSAGES.INVALID_TOKEN == response.message) {
            this.sessionServ.clearSession();
            this.router.navigateByUrl('/');
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Router } from '@angular/router';

import { CONSTANTS } from '../../util/constants';
import { COMMON } from '../../util/common';
import { TreatmentService } from '../../services/treatment.service';
import { SessionService } from '../../services/session.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-treatment',
    templateUrl: './treatment.component.html',
    styleUrls: ['./treatment.component.css']
})
export class TreatmentComponent implements OnInit {

    treatments: any = [];
   
    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public treatmentServ: TreatmentService,
        public sessionServ: SessionService,
        public router: Router,
        public dataServ: DataService,
    ) { }

    ngOnInit() {
        this.loaderServ.start();
        this.treatmentServ.records()
            .then(res => {
                this.treatments = res.response;
                this.loaderServ.stop();
            });
    }

    show(id: number) {
        this.dataServ.updateId(id);
        this.router.navigateByUrl('/user/show-treatment');
    }

}

import { Component, OnInit} from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { COMMON } from '../../../util/common';
import { TreatmentService } from '../../../services/treatment.service';
import { Treatment } from '../../../models/treatment';
import { UserProfile } from '../../../models/user-profiles';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-add-treatment',
    templateUrl: './add-treatment.component.html',
    styleUrls: ['./add-treatment.component.css']
})
export class AddTreatmentComponent implements OnInit {

    treatment: Treatment;
    userProfile: UserProfile;
    message: string;
    medicineCtrl: FormControl;    
    nameCtrl: FormControl;    
    frequencyCtrl: FormControl;    
    measureCtrl: FormControl;    
    filteredMedicines: any;
    filteredNames: any;
    filteredFrequencies: any;
    filteredMeasures: any;
    medicineId: number;
    reportTitle: string = '';
    medicineValue: string;
    maxDate: Date;
    lang: string;
    files: File [] = [];
    medicines: any = [];
    names: string[] = [];    

    constructor(
        public constants: CONSTANTS,
        public common: COMMON,
        public loaderServ: NgxUiLoaderService,
        public treatmentServ: TreatmentService,
        private sessionServ: SessionService,
        private snackBar: MatSnackBar,
        private router: Router
    ) { }


    

    ngOnInit() {
        this.lang = this.sessionServ.getLang();
        this.maxDate = new Date();
        
        this.medicineCtrl = new FormControl();
        this.nameCtrl = new FormControl();
        this.frequencyCtrl = new FormControl();
        this.measureCtrl = new FormControl();
        this.treatment = new Treatment();
        // console.log(this.medicineCtrl);
        console.log(this.medicineCtrl.valueChanges);

        

        this.treatmentServ.getMedicines()
            .then(res => {
                this.medicines = res.response.data; 
                // console.log(this.medicines);               

                if(this.medicines) {
                    var flags = [];
                    for(let i=0; i<this.medicines.length; i++) {
                        if(flags[this.medicines[i].name]) 
                            continue;
                            
                        flags[this.medicines[i].name] = true;
                        this.names.push(this.medicines[i].name);                
                    }
                }
                

                this.setMedicineCtrl();
                this.setNameCtrl();
            });
            
        this.setMeasureCtrl();
        this.setFrequencyCtrl();
    }

    getCompositionName(data) {
    //     console.log("Value",data);
        if(data) {
            this.treatmentServ.getMedicinesByName(data)
                .then(res => {
                    this.medicines = res.response.data; 
                    // console.log(this.medicines);               

                    if(this.medicines) {
                        var flags = [];
                        for(let i=0; i<this.medicines.length; i++) {
                            if(flags[this.medicines[i].name]) 
                                continue;
                                
                            flags[this.medicines[i].name] = true;
                            this.names.push(this.medicines[i].name);                
                        }
                    }
                    

                    this.setMedicineCtrl();
                    this.setNameCtrl();
                });
        }
        
      }

    dateChanged() {
        if(this.treatment.start_date && this.treatment.end_date && new Date(this.treatment.start_date) > new Date(this.treatment.end_date)) {
            this.snackBar.open(this.constants.MESSAGES.INVALID_DATE, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });

            this.treatment.end_date = null;
        }
    }

    setNameCtrl() {
        this.filteredNames = this.nameCtrl.valueChanges
            .pipe(
                startWith(''),
                map(name => name ? this.filterNames(name) : this.names.slice())
            );
    }

    setMedicineCtrl() {
            this.filteredMedicines = this.medicineCtrl.valueChanges
            .pipe(
                startWith(''),
                map(medicine => medicine ? this.filterMedicines(medicine) : this.medicines.slice())
            );
    }

    setFrequencyCtrl() {
        this.filteredFrequencies = this.frequencyCtrl.valueChanges
            .pipe(
                startWith(''),
                // map(freequency => freequency ? this.filterFrequencies(freequency) : this.constants.DOSE_FREQUENCIES[this.lang].slice())
                map(freequency => freequency ? this.filterFrequencies(freequency) : this.constants.DOSE_FREQUENCIES_WITHOUT_LANG.slice())
            );
    }
    
    setMeasureCtrl() {
        this.filteredMeasures = this.measureCtrl.valueChanges
            .pipe(
                startWith(''),
                map(measure => measure ? this.filterMeasures(measure) : this.constants.DOSE_MEASURES.slice())
                // map(measure => measure ? this.filterMeasures(measure) : this.constants.DOSE_MEASURES_LANG[this.lang].slice())
            );
    }

    displayMedicine(data: any): string | undefined {
        return data ? data.composition : undefined;
    }

    displayName(data: any): string | undefined {
        return data ? data : undefined;
    }

    // displayFrequency(data: string): string | undefined {
    //     return data ? data : undefined;
    // }

    // displayMeasure(data: string): string | undefined {
    //     return data ? data : undefined;
    // }

    displayMeasure(data: string): string | undefined{
        return data ? this.constants.MEASURES[0][this.lang][data] : undefined;
    }

    displayFrequency(data: string): string | undefined {
        return data ? this.constants.FREQUENCY[0][this.lang][data] : undefined;
    }

    filterMedicines(data: any) {         
        this.medicineValue = data;
        if(data.composition) {
            this.medicineValue = data.composition;
            this.medicineId = data.id;   
            this.treatment.brand_name = data.name;
        } else 
            this.medicineId = null;
           
        this.treatment.composition = this.medicineValue; 

        return this.medicines.filter(medicine =>
            medicine.composition.toLowerCase().includes(this.medicineValue.toLowerCase()) === true || medicine.name.toLowerCase().includes(this.medicineValue.toLowerCase()) === true
        );
    } 

    filterNames(data: any) {           
        this.treatment.brand_name = data;
        // console.log();
        for(let i=0; i<this.medicines.length; i++) {
            if(data == this.medicines[i].name) {
                this.medicineId = this.medicines[i].id;
                break;
            }
        }

        return this.names.filter(name =>
            name.toLowerCase().includes(data.toLowerCase()) === true
        );
    }

    filterFrequencies(data: string) {
        this.treatment.frequency = data;
        // return this.constants.DOSE_FREQUENCIES[this.lang].filter(element =>
        //     element.toLowerCase().includes(data.toLowerCase()) === true
        // );

        return this.constants.DOSE_FREQUENCIES_WITHOUT_LANG.filter(element =>
            element.toLowerCase().includes(data.toLowerCase()) === true
        );
    }

    filterMeasures(data: string) {
        this.treatment.dose_measure = data;
        return this.constants.DOSE_MEASURES.filter(element =>
            element.toLowerCase().includes(data.toLowerCase()) === true
        );
    }

    medicineSelected() {
        var flags = [];
        this.names = [];
        if(this.medicines) {
            // console.log(this.medicines.length);
            for(let i=0; i<this.medicines.length; i++) {
                if(this.medicines[i].composition == this.medicineValue) {
                    if(flags[this.medicines[i].name]) 
                        continue;
                        
                    flags[this.medicines[i].name] = true;
                    this.names.push(this.medicines[i].name);   
                }
            }
            
            this.nameCtrl = new FormControl();
            this.setNameCtrl();         
            this.treatment.title = this.medicineValue; 
        }
           
    }

    handleReportFileInput(files: FileList) {        
        let flag = true;
        for(let i=0; i<files.length; i++) {
            if(this.constants.FILE_FORMATS.DOC.indexOf(files[i].type.toLowerCase()) < 0 && this.constants.FILE_FORMATS.IMAGE.indexOf(files[i].type.toLowerCase()) < 0) {
                flag = false;  
                break;
            }
        }

        if(!flag)
            this.showMessage({'status': 400, 'message': this.constants.FILE_FORMAT_ERRORS[this.lang].INVALID_FILE_FORMAT});
        else {
            this.reportTitle = '';
            for(let i=0; i<files.length; i++) {
                this.files[i] = files.item(i);
                this.reportTitle += files[i].name + ', ';
            }

            this.reportTitle = this.reportTitle.substring(0, this.reportTitle.length - 2);
        }
    }

    save(form: NgForm) {
        if(form.valid  && this.medicineValue ) {    
            this.loaderServ.start();     
            if(!this.names.includes(this.treatment.brand_name))
                this.medicineId = null;

            // this.loaderServ.start();

            // start date and end date validation start
            if(this.treatment.end_date != null && this.treatment.start_date == null) {
                this.loaderServ.stop();     
                this.snackBar.open(this.constants.START_DATE_END_DATE_ERROR[this.lang].STARTDATEREQUIREDBEFOREENDDATE, '', {
                    duration: 3000,
                    panelClass: ['red-snackbar']
                }); 
                return false; 
            }
            // start date and end date validation end
            
            this.treatment.medicine_id = this.medicineId;
            this.treatment.title = this.medicineValue;
            this.treatment.person_id = null;
            
            if(this.treatment.start_date)
                this.treatment.start_date = this.common.formatDate(new Date(this.treatment.start_date));
            else 
                this.treatment.start_date = null;
            if(this.treatment.end_date)
                this.treatment.end_date = this.common.formatDate(new Date(this.treatment.end_date));
            else 
                this.treatment.end_date = null;
            
            if(this.treatment.composition) {
                this.treatmentServ.add(this.treatment, this.files)
                    .then(response => {
                        this.loaderServ.stop();
                        this.medicineCtrl = new FormControl();
                        this.nameCtrl = new FormControl();
                        this.treatment.composition = null;
                        this.treatment.brand_name = null;

                        // this.loaderServ.stop();               
                        this.showMessage(response);
                        this.router.navigateByUrl('/user/treatment');
                    });      
            }
        }
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status)
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }

}

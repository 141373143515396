import { Component, OnInit,Output,  EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CONSTANTS } from '../../../util/constants';
import { LoginService } from '../../../services/login.service';
import { SessionService } from '../../../services/session.service';
import { MemberProfile } from '../../../models/member-profile';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-add-family-member',
    templateUrl: './add-family-member.component.html',
    styleUrls: ['./add-family-member.component.css']
})
export class AddFamilyMemberComponent implements OnInit {
    @Output() getFamiltyMemberHeader = new EventEmitter();

    memberProfile: MemberProfile;
    user: any;
    message: string;
    monthOfBirth: string = '';
    dayOfBirth: string = '';
    yearOfBirth: string = '';
    // isMaleClicked: boolean = true;
    isMaleClicked: boolean;
    isFemaleClicked: boolean = false;
    imgURL: any = 'url(/assets/images/user-img.png)';
    file: File;
    backgroundStyle: any;
    borderRadius: string = '0';
    backgroundSize: string = 'contain';
   
    years: number[] = [];
    lang: string;

    constructor(
        public constants: CONSTANTS,
        public loginServ: LoginService,
        public sessionServ: SessionService,
        public userServ: UserService,
        public loaderServ: NgxUiLoaderService,
        private snackBar: MatSnackBar,
        public router: Router
    ) { }

    ngOnInit() {     
        this.lang = this.sessionServ.getLang();
        // for(let i=new Date().getFullYear(); i>=1970; i--)
        //     this.years.push(i);

        // for(let i=new Date().getFullYear(); i>=1901; i--)
        for(let i=new Date().getFullYear(); i>=1600; i--)
            this.years.push(i);
        this.user = this.sessionServ.getUser();

        this.memberProfile = new MemberProfile();        
        
        this.setBackgroundStyle();
    }

    checkOrganDonor(isOrganDonnor: number) {
        this.memberProfile.organ_donor = isOrganDonnor;
    }

    genderSelected(gender: string) {
        this.memberProfile.gender = gender;
        if(this.constants.GENDERS.MALE == gender) {
            this.isMaleClicked = true;
            this.isFemaleClicked = false;
        } else if(this.constants.GENDERS.FEMALE == gender) {
            this.isMaleClicked = false;
            this.isFemaleClicked = true;
        }
         else {
            this.isMaleClicked = false;
            this.isFemaleClicked = false;
        }
    }

    checkSmoker(isSmoker: number) {
        this.memberProfile.smoker = isSmoker;
    }

    handleFileInput(target: any) {        
        this.file = target.files.item(0);        
    
        if(this.file) {
            var reader = new FileReader();
            
            this.borderRadius = '50%';
            this.backgroundSize = 'cover';

            reader.readAsDataURL(this.file); 
            reader.onload = (_event) => { 
                this.imgURL = 'url(' + reader.result + ')'; 
                this.setBackgroundStyle();
            }
        }
    }

    saveProfile(form: NgForm) {
        if(form.valid) {
            this.loaderServ.start();
            
            this.memberProfile.date_of_birth = null;
            if(this.yearOfBirth && this.monthOfBirth && this.dayOfBirth)
                this.memberProfile.date_of_birth = this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth;
            this.memberProfile.user_id = null;
            this.memberProfile.parent_id = this.user.user_id;

            // blood group amd blodd type validation start
            if(this.memberProfile.blood_group != null && this.memberProfile.rh_factor == null) {
                this.loaderServ.stop();
              
                this.snackBar.open(this.constants.BLOOD_TYPE_ERROR[this.lang].BLOODTYPE, '', {
                  duration: 3000,
                  panelClass: ['red-snackbar']
                }); 
                return false;
            }
            // blood group and blood type validation end
            
            this.userServ.saveProfile(this.memberProfile, this.file)
                .then(res => {
                    this.showMessage(res);
                    this.loaderServ.stop();
                    this.userServ.saveFamilyMemberBS.next(Math.random());
                });
        }
    }

    setBackgroundStyle() {
        this.backgroundStyle = {'overflow': 'hidden', 'position': 'absolute', 'right': '0px', 'top': '5px', 'background-repeat': 'no-repeat', 'background-size': this.backgroundSize, 'background-position': 'center', 'background-image': this.imgURL, 'border-radius': this.borderRadius}
    }

    showMessage(res: any) {    
        if(this.constants.RESPONSE_CODES.HTTP_STATUS_OK == res.status) {
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });

            this.router.navigateByUrl('/user/my-family');
        }
        else
            this.snackBar.open(res.message, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
    }
}
